<body>
    <!--<app-nav-menu></app-nav-menu>-->
<!--    <div class="container">
        <app-home-new></app-home-new>
    </div>-->
    <ngx-toastr></ngx-toastr>
    <div class="container-fluid display-height-100" >
        <router-outlet></router-outlet>
    </div>
    <!--<script src="~/js/jquery.min.js"></script>
    <script src="~/js/popper.js"></script>
    <script src="~/js/bootstrap.min.js"></script>
    <script src="~/js/main.js"></script>-->
</body>
