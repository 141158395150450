import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {

    transform(value: Date): string {
        if (!value) return '';

        if (typeof value === 'string' || typeof value === 'number') {
            value = new Date(value);
        }

        const now = new Date();
        const seconds = Math.floor((now.getTime() - new Date(value).getTime()) / 1000);

        if (seconds < 60) {
            return 'just now';
        }

        const minutes = Math.floor(seconds / 60);
        if (minutes < 60) {
            return `${minutes}m`;
        }

        const hours = Math.floor(minutes / 60);
        if (hours < 24) {
            return `${hours}h`;
        }

        const days = Math.floor(hours / 24);
        if (days <= 5) {
            return `${days}d`;
        }

        const months = now.getMonth() - value.getMonth()
            + (12 * (now.getFullYear() - value.getFullYear()));

        if (months < 12) {
            return value.toLocaleDateString(undefined, { month: 'long', day: 'numeric' });
        }

        return value.toLocaleDateString(undefined, { month: 'long', day: 'numeric', year: 'numeric' });
    }
}
