<!-- validation-modal.component.html -->
<!--<div class="modal fade" id="validationModal" tabindex="-1" aria-labelledby="validationModalLabel" aria-hidden="true">-->


<!-- sign_up_validation.modal.component.html -->
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header justify-content-between">
      <button type="button" class="btn btn-link" aria-label="Back" (click)="goBackToRegistration()">
        <i class="fa fa-arrow-left"></i>
      </button>
      <h5 class="modal-title text-center flex-grow-1" id="validationModalLabel">Validation</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="formValidation" novalidate>
        <div class="d-flex justify-content-center mb-3">
          <div class="btn-group" role="group" aria-label="Validation Method">
            <input type="radio" class="btn-check" formControlName="validationMethod" value="email" id="emailMethod">
            <label class="btn btn-outline-primary" for="emailMethod">Validate with Email</label>
            <input type="radio" class="btn-check" formControlName="validationMethod" value="phone" id="phoneMethod">
            <label class="btn btn-outline-primary" for="phoneMethod" [class.disabled]="!hasPhone">Validate with Phone</label>
          </div>
        </div>
        <div class="form-group mb-3 d-flex align-items-start">
          <div class="flex-grow-1">
            <label for="Code">Enter the 6-digit code:</label>
            <input type="text" formControlName="Code" name="Code" class="form-control" placeholder="Enter the 6-digit code" maxlength="6">
            <small *ngIf="formValidation.get('Code').touched && formValidation.get('Code').errors?.required" class="text-danger">
              Code is required.
            </small>
            <small *ngIf="formValidation.get('Code').touched && formValidation.get('Code').errors?.pattern" class="text-danger">
              Please enter a valid 6-digit code.
            </small>
            <small *ngIf="formValidation.get('Code').errors?.invalidCode" class="text-danger">
                Invalid or expired code.
            </small>
          </div>
          <button type="button" class="btn btn-secondary ms-2" style="margin-top:30px;" (click)="sendValidationCode()" [disabled]="isBlocked">Send Code</button>
        </div>
        <div *ngIf="codeSentMessage" class="text-info mb-3">{{ codeSentMessage }}</div>
        <button type="button" class="btn btn-success w-100" (click)="submitRegistration()" [disabled]="!formValidation.valid || !isCodeValid">Sign up</button>
      </form>
    </div>
  </div>
</div>



<!--</div>-->
