import { Injectable, OnDestroy, HostListener  } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Observable, Subject, timer, fromEvent, Subscription  } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { throttleTime } from 'rxjs/operators';

import { GlobalService } from '../services/global.service';
import { UserService } from '../services/user.service';
import { SessionService } from '../services/session.service';

@Injectable({
    providedIn: 'any'
})
export class InactivityTimeoutService implements OnDestroy {
    private readonly inactivityTimeoutMs = 300000; // 5 minutes inactivity timeout
    private readonly inactivityThrottleMs = 30000; // 30 Seconds for now. Make it 5 minutes after going live and extending the application timeout.
                                                    // TODO
    private inactivityTimer$: Observable<number>;
    private destroy$ = new Subject<void>();

    private inactivityTimerSubscription: Subscription;
    private userActivitySubscription: Subscription;
    private readonly defaultInactivityTimeoutMs = 300000; // 5 minutes inactivity timeout as a default

    constructor(
        private globalService: GlobalService,
        private sessionService: SessionService,
        private authService: UserService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.watchUserActivity();
    }

    /*
     
    initializeTimer(): void {
        //const expiration = localStorage.getItem('expiration');
        const expiration = this.sessionService.getExpiration;

        if (expiration) {
            //const expirationDate = new Date(expiration);
            this.sessionService.getExpiration().subscribe(expiration => {
                if (expiration) {
                    const expirationDate = new Date(expiration);
                    // Do whatever you need with the expirationDate here
                    //console.log('Expiration Date:', expirationDate);
                    const now = new Date();
                    if (expirationDate > now) {
                        // Calculate time left until token expires
                        const timeoutMs = expirationDate.getTime() - now.getTime();
                        this.startTimer(Math.min(timeoutMs, this.defaultInactivityTimeoutMs));
                    } else {
                        // Token has expired
                        this.handleSessionExpired();
                    }

                }
            });

        } else {
            // Expiration not set, redirect to login
            this.router.navigateByUrl('/dashboard_login');
        }
    }

*/

    initializeTimer(): void {
        //console.log('Timer initialized');
        this.sessionService.getExpiration().subscribe(expiration => {
            if (expiration) {
                const expirationDate = new Date(expiration);
                const now = new Date();
                if (expirationDate > now) {
                    // Calculate time left until token expires
                    const timeoutMs = expirationDate.getTime() - now.getTime();
                    this.startTimer(Math.min(timeoutMs, this.defaultInactivityTimeoutMs));
                } else {
                    // Token has expired
                    this.handleSessionExpired();
                }
            } else {
                // Expiration not set or token has expired, redirect to login
                this.router.navigateByUrl('/dashboard_login');
            }
        }, error => {
            console.error('Error fetching expiration', error);
            this.router.navigateByUrl('/dashboard_login');
        });
    }

    private startTimer(timeoutMs: number): void {
        this.inactivityTimerSubscription?.unsubscribe(); // Clear any previous timer
        this.inactivityTimerSubscription = timer(timeoutMs).subscribe(() => {
            this.handleSessionExpired();
        });
        // Reset timer on user activity
        this.resetTimerOnActivity();
    }

    private handleSessionExpired(): void {
        localStorage.removeItem('expiration'); // Clean up
        localStorage.removeItem('userID');
        localStorage.removeItem('username');
        localStorage.removeItem('userImg');
        localStorage.removeItem('userBannerImg');
        this.authService.logout();
        this.router.navigateByUrl('/dashboard_login');
    }

    private watchUserActivity(): void {
        const mouseMove$ = fromEvent(window, 'mousemove');
        const keyPress$ = fromEvent(window, 'keypress');

        this.userActivitySubscription = mouseMove$.pipe(
            throttleTime(this.inactivityThrottleMs)  // Example: throttle mouse events to prevent too frequent resets
        ).subscribe(() => this.resetTimerOnActivity());

        this.userActivitySubscription.add(
            keyPress$.subscribe(() => this.resetTimerOnActivity())
        );
    }

    //startTimer(): void {
    //    this.inactivityTimer$ = timer(this.inactivityTimeoutMs).pipe(
    //        takeUntil(this.destroy$)
    //    );

    //    this.inactivityTimer$.subscribe(() => {
    //        // Timeout action: Perform logout or redirect to login page
    //        this.authService.logout();
    //    });

    //    // Reset timer on user activity
    //    this.resetTimerOnActivity();
    //}

    resetTimerOnActivity(): void {
        // Listen to router navigation events to detect user activity
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            switchMap(() => timer(this.inactivityTimeoutMs))
        ).subscribe(() => {
            // Reset timer on user activity
            this.initializeTimer();
        });

        // You can add additional event listeners for other types of user activity
    }

    //resetTimer(): void {
    //    // Reset the timer by unsubscribing and resubscribing

    //    this.destroy$.next();
    //    this.destroy$.complete();
    //    this.destroy$ = new Subject<void>();
    //    this.startTimer();
    //}

    expireToken(): void {
        this.authService.logout();
    }

    @HostListener('window:mousemove', ['$event'])
    @HostListener('window:keypress', ['$event'])
    handleUserActivity(): void {
        this.initializeTimer();
    }


    ngOnDestroy(): void {
        if (this.userActivitySubscription) {
            this.userActivitySubscription.unsubscribe();
        }
    }


}
