import { Component, Input, OnInit, OnChanges, ElementRef, ViewChild, ViewChildren, SimpleChange, SimpleChanges, OnDestroy, QueryList, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { PdfmakeService } from '../../../../services/pdfmake.service';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@Component({
    selector: 'app-terms-of-service',
    templateUrl: './terms-of-service.component.html',
    styleUrls: ["./terms-of-service.component.css"]
})
export class TermsOfService implements OnInit, OnChanges {

    returnUrl: string;
    errorMessage: string; 

    ulContentHeight: number;

    email: string = '';
    password: string = '';

    htmlContent: string;

  constructor(
      private modalService: NgbModal,
      private route: ActivatedRoute,
      private pdfmakeService: PdfmakeService,
    private router: Router
    //private formBuilder: FormBuilder
  ) {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
    ngOnChanges(changes: SimpleChanges): void {
        throw new Error("Method not implemented.");
    }
    //ngOnDestroy(): void {
    //    throw new Error("Method not implemented.");
    //}

    ngOnInit() {

    }

    ngAfterViewInit() {
        // Extract HTML content after view init
        const contentElement = document.getElementById('termsContent');
        if (contentElement) {
            this.htmlContent = contentElement.innerHTML;
        }
    }

    //downloadPDF() {
    //    if (this.htmlContent) {
    //        this.pdfmakeService.generatePdfFromHtml(this.htmlContent, 'Terms of Service');
    //    }
    //}

    downloadPDF() {
        setTimeout(() => {
            this.pdfmakeService.generatePdfFromHtml('termsContent', 'Terms of Service');
        }, 500); // Adjust the delay if needed
    }

}
