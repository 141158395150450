// score-modal.component.ts
import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScoreService } from '../../../services/scores.service';

@Component({
    selector: 'app-custom-slider',
    templateUrl: './custom-slider.component.html',
    styleUrls: ['./custom-slider.component.css']
})
export class CustomSliderComponent  implements OnInit, AfterViewInit {
    @Input() entityName: string;
    @Input() value: number | null = null;
    @Input() min: number = 0;
    @Input() max: number = 20;
    @Input() noScoreValue: number = this.min - 1; // default to min - 1 for no score

    //@Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() valueChange = new EventEmitter<number | null>();


    private offsets: number[] = [
        -1.6, 2.7, 2.5, 2.2, 2.0, 1.8, 1.4, 1.0, 0.9, 0.6, 0.4,
        0,
        -0.4, -0.6, -0.9, -1.0, -1.4, -1.8, -2.0, -2.2, -2.5, -2.7
    ];

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private scoreService: ScoreService
    ) { }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {

    }

    onSliderChange(event: any): void {
        //this.value = event.target.value;
        //this.valueChange.emit(this.value);

        this.value = +event.target.value === this.noScoreValue ? null : +event.target.value;
        this.valueChange.emit(this.value);
    }

    onChange(event: any) {
        this.valueChange.emit(this.value);
    }

    getThumbPosition(): number {
        var offset: number;
        if (this.value === null) {
            offset = this.calculateOffset(0); // Position for no score
        }
        else {
            offset = this.calculateOffset(this.value + 1);
        }
        const position = ((this.value - this.noScoreValue) / (this.max - this.noScoreValue)) * 100;
        //const offset = 0;
        const correctedPosition = position + offset;
        return correctedPosition;
    }

    calculateOffset(value: number): number {
        // Use a polynomial function or any other formula to dynamically calculate the offset
        // Adjust the coefficients as needed
        //return -0.0005 * Math.pow(value, 2) + 0.1 * value;
        //const a = -0.027;  // Approximated coefficient for the quadratic term
        //const b = 0.01;    // Approximated coefficient for the linear term
        //const offset = a * Math.pow(value - 10, 2) + b * (value - 10);
        //return offset;


        return this.offsets[value] || 0;
    }

}
