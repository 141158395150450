import { Injectable } from '@angular/core';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, ReplaySubject, forkJoin, throwError } from 'rxjs';

import { RegistrationUrls } from '../global.urls';
import * as Classes from '../classes/models.classes';
import { ShortsPostSave } from '../interfaces/models.interfaces';
import { tap } from 'rxjs/operators';

//import { Http } from '@angular/http';

@Injectable({
    providedIn: 'any'
})
export class EmailService  {
    lcl_params: Classes.UserID_Params;
    private postsSubject = new BehaviorSubject<Classes.ShortsPosts[]>([]); // Observable stream for posts
    private reactionsSubject = new BehaviorSubject<any>(null);  // For reactions

  constructor(
      private http: HttpClient
  ) {

  }

    sendVerificationCode(toEmail: string, toName: string, emailBody: string): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        // Create request body with toEmail, toName, emailBody
        const body = {
            ToEmail: toEmail,
            ToName: toName,
            EmailBody: emailBody
        };

        return this.http.post<any>(RegistrationUrls.RequestVerificationCode, body, { headers });

    }

}
