import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

import { ContentAnalysis } from '../global.urls';

@Injectable({
    providedIn: 'root'
})
export class ContentAnalysisService {

    //private apiUrl = 'https://your-backend-url/api/content/analyze'; // Change this to your actual backend URL

    constructor(private http: HttpClient) { }

    analyzeUrl(url: string): Observable<any> {
        return this.http.post<any>(ContentAnalysis.AnalyzeContent, { url: url }, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    fetchMetaTags(url: string): Observable<any> {
        return this.http.post<any>(ContentAnalysis.FetchMetaTags, { url: url }, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(
            timeout(3000), // Set timeout to 3 seconds
            catchError(error => {
                console.error('Error fetching meta tags:', error);
                return throwError(error);
            })
        );
    }

}
