
    <div class="container-fluid container-md">
        <div class="row ">
            <!-- LEFT SIDE BAR -->
            <app-left-sidebar class="col-sm-2 col-md-3" [Username]="Username"></app-left-sidebar>

            <!-- MAIN SECTION -->
            <div class="col-12 col-sm-10 col-md-9 col-lg-6  main_bar ">
                <div class="row d-flex justify-content-between align-items-center ps-1">
                    <div class="col-1 pt-2">
                        <div><span class="fs-4 fw-bold">Home</span></div>
                    </div>
                    <div class="col-1 me-3">
                        <div><span class="fs-4"><i class="fa fa-star"></i> </span></div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col pt-2 ">
                        <div class="profile-banner" [style.background-image]="'url(' + BannerImageUrl + ')'">
                            <img class="profile-image" [src]="'./img_profile/' + UserID + '/' + UserImg" alt="User Profile">
                        </div>
                                    <!-- POST 1 -->
                                    <div class="row mt-1 border d-flex  ">
                                        <div class="col-1 mt-2">
                                            <div class="user-avatar-container"> 
                                                <img class="circular-image" [src]="UserPostData.ImageDirectory + '/' + UserPostData.UserImg"  alt="" height="50px">
                                            </div>
                                        </div>
                                        <div class="col-11 ">
                                            <div class="row d-flex justify-content-between align-items-center ps-1">
                                                <div class="col-12 pt-2 ">
                                                    <div>
<!--                                                        <p><span class="fw-bold">{{UserPostData.ProfileName}} </span> 
                                                            @{{UserPostData.UserName}} &#x2022; {{UserPostData.PublishDate | relativeTime}}</p>

                                        <span class="fw-bold " 
                                                (mouseover)="loadUserProfileSingle(UserPostData, 1, $event)" 
                                                (mouseleave)="hideUserProfileSingle(1)">
                                            <a [routerLink]="'/' + UserPostData.UserName" class="reposted-profile-link">{{UserPostData.ProfileName}} </a>
                                            <a [routerLink]="'/' + UserPostData.UserName" class="reposted-profile-link">@{{UserPostData.UserName}} &#x2022; </a>{{UserPostData.PublishDate | relativeTime}}
                                            <div *ngIf="userProfileState.get(1)?.visible" [ngStyle]="hoverBoxStyle" class="user-hover-box">
                                                <img [src]="UserPostData.ImageDirectory + '/' + userProfileState.get(1).profile.UserImg" alt="User Image">
                                                <h3>{{ userProfileState.get(1).profile.ProfileName }}</h3>
                                                <p>@{{ userProfileState.get(1).profile.UserName }}</p>
                                                <button (click)="followUser(userProfileState.get(1).profile.UserName); $event.stopPropagation()">Follow</button>
                                                <p>{{ userProfileState.get(1).profile.ProfileBio }}</p>
                                                <div>
                                                <span>Following: {{ userProfileState.get(1).profile.FollowingCount }}</span> |
                                                <span>Followers: {{ userProfileState.get(1).profile.FollowersCount }}</span>
                                                </div>
                                            </div>
                                        </span>       -->
                                                      <app-profile-menu
                                                        [ProfileName]="UserPostData.ProfileName"
                                                        [Username]="UserPostData.UserName"
                                                        [PublishDate]="UserPostData.PublishDate"
                                                        [ImageDirectory]="UserPostData.ImageDirectory">
                                                      </app-profile-menu>                                                    
                                                    </div>
												        <a href="post-detail.html" class="post-title">{{UserPostData.PostTitle}}</a>
                                                        <p [innerHTML]="UserPostData.PostContent | parseHashtags"></p>
                                                        <div *ngIf="UserPostData.FileName">
                                                          <!-- Check if the MIME type is a video format -->
                                                          <div class="video-container" *ngIf="UserPostData.VideoMimeType?.startsWith('video/')">
                                                            <video controls class="responsive-video">
                                                                <source [src]="'api/content/media?fileName=' + UserPostData.FileName + '&mimeType=' + UserPostData.VideoMimeType + '&userID=' + UserPostData.OrigUserID" [type]="UserPostData.VideoMimeType">
                                                                Your browser does not support the video tag.
                                                            </video>
                                                          </div>
                                                        <!-- Check if the MIME type is an image format -->
                                                        <div class="image-container" *ngIf="UserPostData.VideoMimeType?.startsWith('image/')">
                                                            <div class="responsive-image-wrapper">
                                                            <img class="responsive-image" [src]="'api/content/media?fileName=' + UserPostData.FileName + '&mimeType=' + UserPostData.VideoMimeType + '&userID=' + UserPostData.OrigUserID" [alt]="UserPostData.FileName" >
                                                            </div>
                                                        </div>                                                    
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-12 reactions">
                                                <svg (click)="toggleReaction(UserPostData.TSID, '1'); $event.stopPropagation();" 
                                                     xmlns="http://www.w3.org/2000/svg" 
                                                     viewBox="0 0 24 24" 
                                                     width="24" 
                                                     height="24">
                                                    <path [style.fill]="UserPostData.Reactions.replySelected ? '#d70000' : '#878787'" d="M2 15V5c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v15a1 1 0 0 1-1.7.7L16.58 17H4a2 2 0 0 1 -2-2z M20 5H4v10h13a1 1 0 0 1 .7.3l2.3 2.29V5z Z"/>
                                                </svg>
                                                <span class="reactions-number">{{ UserPostData.Reactions.Replies }}</span>
                                                <div class="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 24 24"
                                                         width="24"
                                                         height="24"
                                                         class="dropdown-toggle"
                                                         id="dropdownRepostMenu"
                                                         data-toggle="dropdown"
                                                         aria-haspopup="true"
                                                         aria-expanded="false"
                                                         (click)="$event.stopPropagation()">

                                                        <path [style.fill]="UserPostData.Reactions.repostSelected ? '#d70000' : '#878787'" d="M5.41 16H18a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-4 4H5.41l2.3 2.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.4L5.4 16zM6 8a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4h12.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4L18.6 8H6z"/>
                                                    </svg>
                                                    <span class="reactions-number">{{ UserPostData.Reactions.Reposts }}</span>


                                                <div class="dropdown-menu" aria-labelledby="dropdownRepostMenu">
                                                    <a class="dropdown-item" href="#" (click)="saveRePost(UserPostData.TSID, '2', UserPostData.Reactions.repostSelected, '1', null); $event.stopPropagation();">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" class="mr-1"><path class="heroicon-ui" d="M5.41 16H18a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-4 4H5.41l2.3 2.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.4L5.4 16zM6 8a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4h12.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4L18.6 8H6z"/></svg>
                                                        Repost
                                                    </a>
                                                    <a class="dropdown-item" href="#" (click)="openQuoteModal(UserPostData); $event.stopPropagation()">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" class="mr-1"><path class="heroicon-ui" d="M6.3 12.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h6a1 1 0 0 1 0 2H4v14h14v-6z"/></svg>
                                                        Quote
                                                    </a>
                                                </div>
                                                </div>

                                                    <svg (click)="toggleReaction(UserPostData.TSID, '3'); $event.stopPropagation()" 
                                                         xmlns="http://www.w3.org/2000/svg" 
                                                         viewBox="0 0 24 24" 
                                                         width="24" 
                                                         height="24">
                                                      <path [style.fill]="UserPostData.Reactions.likeSelected ? '#d70000' : '#878787'" [attr.d]="UserPostData.Reactions.likeSelected ? 
                                                            'M 12.76 3.76 a 6 6 0 0 1 8.48 8.48 l -8.53 8.54 a 1 1 0 0 1 -1.42 0 l -8.53 -8.54 a 6 6 0 0 1 8.48 -8.48 l 0.76 0.75 l 0.76 -0.75 z z' : 
                                                            'M12.76 3.76a6 6 0 0 1 8.48 8.48l-8.53 8.54a1 1 0 0 1-1.42 0l-8.53-8.54a6 6 0 0 1 8.48-8.48l.76.75.76-.75zm7.07 7.07a4 4 0 1 0-5.66-5.66l-1.46 1.47a1 1 0 0 1-1.42 0L9.83 5.17a4 4 0 1 0-5.66 5.66L12 18.66l7.83-7.83z'"
                                                            class="heroicon-ui">
                                                    </path>
                                                    </svg>
                                                    <span class="reactions-number">{{ UserPostData.Reactions.Likes }}</span>
                                            </div>
                                        </div>
                                    </div>

                            <div *ngIf="isLoading">
                              Loading more posts...
                            </div>								

                    </div>
                </div>
                <div class="row mt-4">  <!-- This is where the Replies are listed -->
                    <div class="col pt-2 ">

                        <ng-container *ngFor="let postData of UserPostsData; let i = index" >
                            <div [id]="'post' + ((currentPage - 1) * pageSize + i)" class="post-container" (click)="navigateToPost(postData.UserName, postData.TSID)">
                                <ng-container *ngIf="postData.PostType === 4"> <!-- share post without image -->

                                    <!-- POST 1 -->
                                    <div class="row mt-1 border d-flex  ">
                                        <div class="col-1 mt-2">
                                            <div class="user-avatar-container"> 
<!--                                                <img class="circular-image" [src]="'./img_profile/' + postData.UserID + '/' + postData.UserImg"  alt="" height="50px">-->
                                                <img class="circular-image" [src]="postData.ImageDirectory + '/' + postData.UserImg"  alt="" height="50px">
                                            </div>
                                        </div>
                                        <div class="col-11 ">
                                            <div class="row d-flex justify-content-between align-items-center ps-1">
                                                <div class="col-12 pt-2 ">
                                                    <div>
<!--                                                        <p><span class="fw-bold">{{postData.ProfileName}} </span> 
                                                            @{{postData.UserName}} &#x2022; {{postData.PublishDate | relativeTime}}</p>

                                        <span class="fw-bold " 
                                                (mouseover)="loadUserProfile(postData, i, $event)" 
                                                (mouseleave)="hideUserProfile(i)">
                                            <a [routerLink]="'/' + postData.UserName" class="reposted-profile-link">{{postData.ProfileName}} </a>
                                            <a [routerLink]="'/' + postData.UserName" class="reposted-profile-link">@{{postData.UserName}} &#x2022; </a>{{postData.PublishDate | relativeTime}}
                                            <div *ngIf="userProfileStates.get(i)?.visible" [ngStyle]="hoverBoxStyle" class="user-hover-box">
                                                <img [src]="postData.ImageDirectory + '/' + userProfileStates.get(i).profile.UserImg" alt="User Image">
                                                <h3>{{ userProfileStates.get(i).profile.ProfileName }}</h3>
                                                <p>@{{ userProfileStates.get(i).profile.UserName }}</p>
                                                <button (click)="followUser(userProfileStates.get(i).profile.UserName); $event.stopPropagation()">Follow</button>
                                                <p>{{ userProfileStates.get(i).profile.ProfileBio }}</p>
                                                <div>
                                                <span>Following: {{ userProfileStates.get(i).profile.FollowingCount }}</span> |
                                                <span>Followers: {{ userProfileStates.get(i).profile.FollowersCount }}</span>
                                                </div>
                                            </div>
                                        </span>       -->
                                                      <app-profile-menu
                                                        [ProfileName]="postData.ProfileName"
                                                        [Username]="postData.UserName"
                                                        [PublishDate]="postData.PublishDate"
                                                        [ImageDirectory]="postData.ImageDirectory">
                                                      </app-profile-menu>                                                    
                                                    </div>
												        <a href="post-detail.html" class="post-title">{{postData.PostTitle}}</a>
                                                        <p [innerHTML]="postData.PostContent | parseHashtags"></p>
    <!--												        <p >
                                                            {{postData.PostContent | parseHashtags}}													        
												        </p>-->
                                                        <div *ngIf="postData.FileName">
                                                          <!-- Check if the MIME type is a video format -->
                                                          <div class="video-container" *ngIf="postData.VideoMimeType?.startsWith('video/')">
                                                            <video controls class="responsive-video">
                                                                <source [src]="'api/content/media?fileName=' + postData.FileName + '&mimeType=' + postData.VideoMimeType + '&userID=' + postData.OrigUserID" [type]="postData.VideoMimeType">
                                                                Your browser does not support the video tag.
                                                            </video>
                                                          </div>
                                                        <!-- Check if the MIME type is an image format -->
                                                        <div class="image-container" *ngIf="postData.VideoMimeType?.startsWith('image/')">
                                                            <div class="responsive-image-wrapper">
                                                            <img class="responsive-image" [src]="'api/content/media?fileName=' + postData.FileName + '&mimeType=' + postData.VideoMimeType + '&userID=' + postData.OrigUserID" [alt]="postData.FileName" >
                                                            </div>
                                                        </div>                                                    
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-12 reactions">
                                                <svg (click)="toggleReaction(postData.TSID, '1'); $event.stopPropagation();" 
                                                     xmlns="http://www.w3.org/2000/svg" 
                                                     viewBox="0 0 24 24" 
                                                     width="24" 
                                                     height="24">
                                                    <path [style.fill]="postData.Reactions.replySelected ? '#d70000' : '#878787'" d="M2 15V5c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v15a1 1 0 0 1-1.7.7L16.58 17H4a2 2 0 0 1 -2-2z M20 5H4v10h13a1 1 0 0 1 .7.3l2.3 2.29V5z Z"/>
                                                </svg>
                                                <span class="reactions-number">{{ postData.Reactions.Replies }}</span>
                                                <div class="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 24 24"
                                                         width="24"
                                                         height="24"
                                                         class="dropdown-toggle"
                                                         id="dropdownRepostMenu"
                                                         data-toggle="dropdown"
                                                         aria-haspopup="true"
                                                         aria-expanded="false"
                                                         (click)="$event.stopPropagation()">

                                                        <path [style.fill]="postData.Reactions.repostSelected ? '#d70000' : '#878787'" d="M5.41 16H18a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-4 4H5.41l2.3 2.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.4L5.4 16zM6 8a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4h12.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4L18.6 8H6z"/>
                                                    </svg>
                                                    <span class="reactions-number">{{ postData.Reactions.Reposts }}</span>


                                                <div class="dropdown-menu" aria-labelledby="dropdownRepostMenu">
                                                    <a class="dropdown-item" href="#" (click)="saveRePost(postData.TSID, '2', postData.Reactions.repostSelected, '1', null); $event.stopPropagation();">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" class="mr-1"><path class="heroicon-ui" d="M5.41 16H18a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-4 4H5.41l2.3 2.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.4L5.4 16zM6 8a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4h12.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4L18.6 8H6z"/></svg>
                                                        Repost
                                                    </a>
                                                    <a class="dropdown-item" href="#" (click)="openQuoteModal(postData); $event.stopPropagation()">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" class="mr-1"><path class="heroicon-ui" d="M6.3 12.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h6a1 1 0 0 1 0 2H4v14h14v-6z"/></svg>
                                                        Quote
                                                    </a>
                                                </div>
                                                </div>

                                                    <svg (click)="toggleReaction(postData.TSID, '3'); $event.stopPropagation()" 
                                                         xmlns="http://www.w3.org/2000/svg" 
                                                         viewBox="0 0 24 24" 
                                                         width="24" 
                                                         height="24">
                                                      <path [style.fill]="postData.Reactions.likeSelected ? '#d70000' : '#878787'" [attr.d]="postData.Reactions.likeSelected ? 
                                                            'M 12.76 3.76 a 6 6 0 0 1 8.48 8.48 l -8.53 8.54 a 1 1 0 0 1 -1.42 0 l -8.53 -8.54 a 6 6 0 0 1 8.48 -8.48 l 0.76 0.75 l 0.76 -0.75 z z' : 
                                                            'M12.76 3.76a6 6 0 0 1 8.48 8.48l-8.53 8.54a1 1 0 0 1-1.42 0l-8.53-8.54a6 6 0 0 1 8.48-8.48l.76.75.76-.75zm7.07 7.07a4 4 0 1 0-5.66-5.66l-1.46 1.47a1 1 0 0 1-1.42 0L9.83 5.17a4 4 0 1 0-5.66 5.66L12 18.66l7.83-7.83z'"
                                                            class="heroicon-ui">
                                                    </path>
                                                    </svg>
                                                    <span class="reactions-number">{{ postData.Reactions.Likes }}</span>

    <!--                                            </div>-->
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="postData.PostType === 2"><!-- share audio post -->
                                    <!-- POST Type 2: Repost -->
                                    <div class="row mt-1 border d-flex  ">
                                        <span class="fw-bold repost-link" 
                                                (mouseover)="loadUserProfile(postData, i, $event)" 
                                                (mouseleave)="hideUserProfile(i)">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" class="mr-1"><path class="heroicon-ui" d="M5.41 16H18a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-4 4H5.41l2.3 2.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.4L5.4 16zM6 8a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4h12.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4L18.6 8H6z"/></svg>
                                            <a [routerLink]="'/' + postData.UserName" class="reposted-profile-link">{{postData.ProfileName}} reposted</a>
                                            <div *ngIf="userProfileStates.get(i)?.visible" [ngStyle]="hoverBoxStyle" class="user-hover-box">
                                                <img [src]="postData.ImageDirectory + '/' + userProfileStates.get(i).profile.UserImg" alt="User Image">
                                                <h3>{{ userProfileStates.get(i).profile.ProfileName }}</h3>
                                                <p>@{{ userProfileStates.get(i).profile.UserName }}</p>
                                                <button (click)="followUser(userProfileStates.get(i).profile.UserName)">Follow</button>
                                                <p>{{ userProfileStates.get(i).profile.ProfileBio }}</p>
                                                <div>
                                                <span>Following: {{ userProfileStates.get(i).profile.FollowingCount }}</span> |
                                                <span>Followers: {{ userProfileStates.get(i).profile.FollowersCount }}</span>
                                                </div>
                                            </div>
                                        </span>
                                        <br>
                                        <div class="col-1 mt-2">
                                            <div class="user-avatar-container"> 
<!--                                                <img class="circular-image" [src]="'./img_profile/' + postData.UserID + '/' + postData.UserImg"  alt="" height="50px">-->
                                                <img class="circular-image" [src]="postData.ImageDirectory + '/' + postData.OrigUserImg"  alt="" height="50px">
                                            </div>
                                        </div>
                                        <div class="col-11 ">
                                            <div class="row d-flex justify-content-between align-items-center ps-1">
                                                <div class="col-12 pt-2 ">
                                                    <div>
                                                        <p><span class="fw-bold">{{postData.OrigProfileName}} </span> 
                                                            @{{postData.OrigUserName}} &#x2022; {{postData.OrigPublishDate | relativeTime}}</p>
                                                    </div>
												        <a href="post-detail.html" class="post-title">{{postData.PostTitle}}</a>
                                                        <p [innerHTML]="postData.PostContent | parseHashtags"></p>

                                                        <div *ngIf="postData.FileName">
                                                          <!-- Check if the MIME type is a video format -->
                                                          <div class="video-container" *ngIf="postData.VideoMimeType?.startsWith('video/')">
                                                            <video controls class="responsive-video">
                                                                <source [src]="'api/content/media?fileName=' + postData.FileName + '&mimeType=' + postData.VideoMimeType + '&userID=' + postData.OrigUserID" [type]="postData.VideoMimeType">
                                                                Your browser does not support the video tag.
                                                            </video>
                                                          </div>
                                                        <!-- Check if the MIME type is an image format -->
                                                        <div class="image-container" *ngIf="postData.VideoMimeType?.startsWith('image/')">
                                                            <div class="responsive-image-wrapper">
                                                            <img class="responsive-image" [src]="'api/content/media?fileName=' + postData.FileName + '&mimeType=' + postData.VideoMimeType + '&userID=' + postData.OrigUserID" [alt]="postData.FileName" >
                                                            </div>
                                                        </div>                                                    
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-12 reactions">
<!--                                                            [ngClass]="{'selected': postData.Reactions.replySelected}"  -->
<!--                                                            [style.fill]="postData.Reactions.replySelected ? 'red' : 'blue'">  -->

                                                <svg (click)="toggleReaction(postData.TSID, '1')" 
                                                     xmlns="http://www.w3.org/2000/svg" 
                                                     viewBox="0 0 24 24" 
                                                     width="24" 
                                                     height="24">
                                                    <path [style.fill]="postData.Reactions.replySelected ? '#d70000' : '#878787'" d="M2 15V5c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v15a1 1 0 0 1-1.7.7L16.58 17H4a2 2 0 0 1 -2-2z M20 5H4v10h13a1 1 0 0 1 .7.3l2.3 2.29V5z Z"/>
                                                </svg>
                                                <span class="reactions-number">{{ postData.Reactions.Replies }}</span>
                                                <div class="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 24 24"
                                                         width="24"
                                                         height="24"
                                                         class="dropdown-toggle"
                                                         id="dropdownRepostMenu"
                                                         data-toggle="dropdown"
                                                         aria-haspopup="true"
                                                         aria-expanded="false"
                                                         (click)="$event.stopPropagation()">

                                                        <path [style.fill]="postData.Reactions.repostSelected ? '#d70000' : '#878787'" d="M5.41 16H18a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-4 4H5.41l2.3 2.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.4L5.4 16zM6 8a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4h12.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4L18.6 8H6z"/>
                                                    </svg>
                                                    <span class="reactions-number">{{ postData.Reactions.Reposts }}</span>


                                                <div class="dropdown-menu" aria-labelledby="dropdownRepostMenu">
                                                    <a class="dropdown-item" href="#" (click)="saveRePost(postData.TSID, '2', postData.Reactions.repostSelected, '1', null)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" class="mr-1"><path class="heroicon-ui" d="M5.41 16H18a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-4 4H5.41l2.3 2.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.4L5.4 16zM6 8a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4h12.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4L18.6 8H6z"/></svg>
                                                        Repost
                                                    </a>
                                                    <a class="dropdown-item" href="#" (click)="openQuoteModal(postData)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" class="mr-1"><path class="heroicon-ui" d="M6.3 12.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h6a1 1 0 0 1 0 2H4v14h14v-6z"/></svg>
                                                        Quote
                                                    </a>
                                                </div>
                                                </div>

                                                    <svg (click)="toggleReaction(postData.TSID, '3')" 
                                                         xmlns="http://www.w3.org/2000/svg" 
                                                         viewBox="0 0 24 24" 
                                                         width="24" 
                                                         height="24">
                                                      <path [style.fill]="postData.Reactions.likeSelected ? '#d70000' : '#878787'" [attr.d]="postData.Reactions.likeSelected ? 
                                                            'M 12.76 3.76 a 6 6 0 0 1 8.48 8.48 l -8.53 8.54 a 1 1 0 0 1 -1.42 0 l -8.53 -8.54 a 6 6 0 0 1 8.48 -8.48 l 0.76 0.75 l 0.76 -0.75 z z' : 
                                                            'M12.76 3.76a6 6 0 0 1 8.48 8.48l-8.53 8.54a1 1 0 0 1-1.42 0l-8.53-8.54a6 6 0 0 1 8.48-8.48l.76.75.76-.75zm7.07 7.07a4 4 0 1 0-5.66-5.66l-1.46 1.47a1 1 0 0 1-1.42 0L9.83 5.17a4 4 0 1 0-5.66 5.66L12 18.66l7.83-7.83z'"
                                                            class="heroicon-ui">
                                                    </path>
                                                    </svg>
                                                    <span class="reactions-number">{{ postData.Reactions.Likes }}</span>

    <!--                                            </div>-->
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="postData.PostType === 3"><!-- share video post -->
                                </ng-container>
                                <ng-container *ngIf="postData.PostType === 4"><!-- share image with post -->
                                </ng-container>
                                <ng-container *ngIf="postData.PostType === 5"><!-- share image album -->
                                </ng-container>
                                <ng-container *ngIf="postData.PostType === 6"><!-- gif image post -->
                                </ng-container>
                                <ng-container *ngIf="postData.PostType === 7"><!-- share link -->
                                </ng-container>
                                <ng-container *ngIf="postData.PostType === 8"><!-- share video -->
                                </ng-container>
                                <ng-container *ngIf="postData.PostType === 9"><!-- video playlist carousel -->
                                </ng-container>
                                <ng-container *ngIf="postData.PostType === 10"><!-- post sell book -->
                                </ng-container>
                                <ng-container *ngIf="postData.PostType === 11"><!-- advertisment carousel -->
                                </ng-container>
                            </div>
                            <div *ngIf="isLoading">
                              Loading more posts...
                            </div>								
                        </ng-container>

                    </div>
                </div>

            </div>
            <!-- RIGHT SIDE BAR -->
            <div class="col-lg-3 d-none d-lg-block  w-25 h-25 right_side_bar">
                <div class="">
                    <div class="bg-opacity-10 bg-black rounded-pill">
                        <div class="row d-flex align-items-center ">
                            <div class="col-2 text-end fs-6">
                                <span><i class="fa fa-magnifying-glass ps-4"></i></span>
                            </div>
                            <div class="col-10"><input type="text"
                                    class="form-control-lg border-0 me-1 w-75 bg-transparent"
                                    placeholder="Search Twitter"></div>
                        </div>
                    </div>
                    <div class="row bg-opacity-10 bg-black mx-1 my-2 pb-3 rounded-3">
                        <div class="col">
                            <div class="trending">
                                <p class="fs-6 fw-bold py-2">Trends for you</p>
                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in Pakistan</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">Nawaz Sharif</p>
                                        <p class="">12.7k Tweets</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Technology . Trending</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">#TikTok</p>
                                        <p class="">101k Tweets</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in Pakistan</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">Audio</p>
                                        <p class="">132k Tweets</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in Pakistan</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">#Bajwa</p>
                                        <p class="">8,104 Tweets</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in Pakistan</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">Shame</p>
                                        <p class="">141k Tweets</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in Pakistan</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">#dollarvsrupee</p>
                                        <p class="">2,449 Tweets</p>
                                    </div>
                                </div>

                                <span class="text-info">Show more</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
 