                                        <div style="display: inline-block;" #repostLink>
                                        <span class="fw-bold repost-link"  
                                                (mouseover)="loadUserProfile($event)" 
                                                (mouseleave)="hideUserProfile()">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" class="mr-1"><path class="heroicon-ui" d="M5.41 16H18a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-4 4H5.41l2.3 2.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.4L5.4 16zM6 8a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4h12.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4L18.6 8H6z"/></svg>
                                            <a [routerLink]="'/' + Username" class="reposted-profile-link">{{ProfileName}} reposted</a>
                                                <div *ngIf="showProfileBox && userProfile" [ngStyle]="hoverBoxStyle" class="user-hover-box"
                                                        (mouseenter)="keepHoverBoxVisible()"
                                                        (mouseleave)="hideUserProfile()">                                                                

                                                <img [src]="userProfile?.ImageDirectory + '/' + userProfile?.UserImg" alt="User Image">
                                                <div class="profile-link">{{ userProfile?.ProfileName }}</div>
                                                <div class="profile-username-link">@{{ userProfile?.UserName }}</div>
                                                <button (click)="followUser(); $event.stopPropagation()">Follow</button>
                                                <p>{{ userProfile?.ProfileBio }}</p>
                                                <div>
                                                <span>Following: {{ userProfile?.FollowingCount }}</span> |
                                                <span>Followers: {{ userProfile?.FollowersCount }}</span>
                                                </div>
                                            </div>
                                        </span>
                                        </div>
