import { Injectable } from '@angular/core';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, ReplaySubject, forkJoin, throwError } from 'rxjs';

import { PostsDataUrls } from '../global.urls';
import * as Classes from '../classes/models.classes';
import { ShortsPostSave } from '../interfaces/models.interfaces';
import { tap } from 'rxjs/operators';

//import { Http } from '@angular/http';

@Injectable({
    providedIn: 'any'
})
export class UserPostsDataService {
    lcl_params: Classes.UserID_Params;
    private postsSubject = new BehaviorSubject<Classes.ShortsPosts[]>([]); // Observable stream for posts
    private reactionsSubject = new BehaviorSubject<any>(null);  // For reactions

  constructor(
    private http: HttpClient) {

  }

    loadUserPostsData(UserID: number) {
        let requestHeaders: HttpHeaders = new HttpHeaders();
        requestHeaders = requestHeaders.append('Content-Type', 'application/json');

        //let params = new HttpParams();

        //params.append("UserID", UserID);
        //params.append("CategoryID", CategoryID.toString());

            this.lcl_params = new Classes.UserID_Params();

            this.lcl_params.UserID = UserID;

            let getUserPostsDataRequest: HttpRequest<Classes.UserID_Params> = new HttpRequest<Classes.UserID_Params>('POST', PostsDataUrls.GetUserPosts, this.lcl_params, {
        //let getFraudIncidentDataRequest: HttpRequest<Classes.VoterFraudIncident> = new HttpRequest<Classes.VoterFraudIncident>('POST', VoterFraudDataUrls.Get_Incidents, VoterFraudIncidentData, {
        //let getFraudIncidentDataRequest = new HttpRequest('GET', VoterFraudDataUrls.Get_Incidents, '', {
            reportProgress: true,
            headers: requestHeaders
            //params: params
        });

        return this.http.request<Array<Classes.ShortsPosts>>(getUserPostsDataRequest);

    }

    getDashboardPosts(userID: number, currentPage: number, pageSize: number, postTypes?: number[]): Observable<Classes.ShortsPosts[]> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        // Create request body with userID
        const body = { userID, currentPage, pageSize, postTypes };

        return this.http.post<Classes.ShortsPosts[]>(PostsDataUrls.GetDashboardPosts, body, { headers })
            .pipe(tap(posts => this.postsSubject.next(posts))); // Update the posts stream
    }

    getPosts(userID: number, currentPage: number, pageSize: number, postTypes?: number[]): Observable<Classes.ShortsPosts[]> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        // Create request body with userID
        const body = { userID, currentPage, pageSize, postTypes };

        return this.http.post<Classes.ShortsPosts[]>(PostsDataUrls.GetUserPosts, body, { headers })
            .pipe(tap(posts => this.postsSubject.next(posts))); // Update the posts stream
    }

    getReplyPosts(userID: number, postID: number, currentPage: number, pageSize: number, postTypes?: number[]): Observable<Classes.ShortsPosts[]> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        // Create request body with userID
        const body = { userID, postID, currentPage, pageSize, postTypes };

        return this.http.post<Classes.ShortsPosts[]>(PostsDataUrls.GetUserReplyPosts, body, { headers })
            .pipe(tap(posts => this.postsSubject.next(posts))); // Update the posts stream
    }

    getPostsObservable(): Observable<Classes.ShortsPosts[]> {
        return this.postsSubject.asObservable(); // Return posts as an observable stream
    }

    getPost(userID: number, postID: number): Observable<Classes.ShortsPosts> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        // Create request body with userID
        const body = { userID, postID };

        return this.http.post<Classes.ShortsPosts>(PostsDataUrls.GetUserPost, body, { headers }); 
    }

    getReactions(postId: number): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const body = { postId };
        return this.http.post<any>(`${PostsDataUrls.GetReactions}`, body, { headers })
            .pipe(tap(reactions => this.reactionsSubject.next(reactions)));
    }

    toggleReaction(postId: number, userId: number, reactionType: string): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const body = { postId, userId, reactionType };
        return this.http.post<any>(PostsDataUrls.ToggleReaction, body, { headers })
            .pipe(tap(reactions => this.reactionsSubject.next(reactions)));
    }

    saveUserRePostsData(postId: number, userId: number, reactionType: string, postContent: string, active: boolean): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const body = { postId, userId, reactionType, postContent, active };
        return this.http.post<any>(PostsDataUrls.SaveUserRePosts, body, { headers })
            .pipe(tap(reactions => this.reactionsSubject.next(reactions)));
    }

    //saveQuotePost(postData: any, comment: string, file: File | null): Observable<any> {
    //    const formData = new FormData();
    //    formData.append('postData', JSON.stringify(postData));
    //    //formData.append('comment', comment);
    //    if (file) {
    //        formData.append('file', file);
    //    }
    //    return this.http.post<any>(PostsDataUrls.SaveQuotePost, formData);
    //}

    getReactionsObservable(): Observable<any> {
        return this.reactionsSubject.asObservable();
    }

    //saveUserPostsData(UserPostsDataContent: ShortsPostSave) {
    saveUserPostsData(UserPostsDataContent: FormData) {
        //let requestHeaders: HttpHeaders = new HttpHeaders();
        //requestHeaders = requestHeaders.append('Content-Type', 'application/json');

        //this.lcl_params = new Classes.UserID_Params();

        let saveUserPostsDataRequest: HttpRequest<FormData> = new HttpRequest<FormData>('POST', PostsDataUrls.SaveUserPosts, UserPostsDataContent, {
            reportProgress: true,
            //headers: requestHeaders
        });

        //return this.http.request<Array<Classes.ShortsPosts>>(saveUserPostsDataRequest);

        // Use HttpClient to send the request
        //return this.http.request(saveUserPostsDataRequest);
        return this.http.request<number>(saveUserPostsDataRequest);
    }

    saveQuotePost(UserPostsDataContent: FormData) {

        let saveUserPostsDataRequest: HttpRequest<FormData> = new HttpRequest<FormData>('POST', PostsDataUrls.SaveQuotePost, UserPostsDataContent, {
            reportProgress: true,
        });

        // Use HttpClient to send the request
        return this.http.request<number>(saveUserPostsDataRequest);
    }


    savePost(UserPostsDataContent: FormData, UserID: number): Observable<any> {
        //const formData = new FormData();
        //formData.append('postData', JSON.stringify(postData)); // Serialize text data
        //if (postData.videoFile) {
        //    formData.append('file', postData.videoFile, postData.videoFile.name); // Append video file if present
        //}


        //var UserID = UserPostsDataContent["UserID"].ToString();
        // TODO. Check to see if this is pulling the correct UserID. MB 6/7/24

        return this.http.post(PostsDataUrls.SaveUserPosts, UserPostsDataContent)
            .pipe(tap(() => this.refreshPosts(UserID))); // Refresh posts after a successful save
    }

    private refreshPosts(userID: number): void {
        this.getPosts(userID,1,10).subscribe(); // Refresh posts by fetching new ones and updating the stream
    }

}
