<!-- reactions.component.html -->
<svg (click)="toggleReaction(postData.TSID, '1'); $event.stopPropagation();" 
     xmlns="http://www.w3.org/2000/svg" 
     viewBox="0 0 24 24" 
     width="24" 
     height="24">
    <path [style.fill]="postData.Reactions.replySelected ? '#d70000' : '#878787'" d="M2 15V5c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v15a1 1 0 0 1-1.7.7L16.58 17H4a2 2 0 0 1 -2-2z M20 5H4v10h13a1 1 0 0 1 .7.3l2.3 2.29V5z Z"/>
</svg>
<span class="reactions-number">{{ postData.Reactions.Replies }}</span>

<div class="dropdown">
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24"
         width="24"
         height="24"
         class="dropdown-toggle"
         id="dropdownRepostMenu"
         aria-haspopup="true"
         aria-expanded="false"
         (click)="toggleDropdown(); $event.stopPropagation()">
        <path [style.fill]="postData.Reactions.repostSelected ? '#d70000' : '#878787'" d="M5.41 16H18a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-4 4H5.41l2.3 2.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.4L5.4 16zM6 8a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4h12.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4L18.6 8H6z"/>
    </svg>
    <span class="reactions-number">{{ postData.Reactions.Reposts }}</span>
    <div class="dropdown-menu" [ngClass]="{'show': isDropdownOpen}" aria-labelledby="dropdownRepostMenu">
        <a class="dropdown-item" href="#" (click)="saveRePost(postData.TSID, '2', postData.Reactions.repostSelected, '1', null); $event.stopPropagation();">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" class="mr-1"><path class="heroicon-ui" d="M5.41 16H18a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-4 4H5.41l2.3 2.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.4L5.4 16zM6 8a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4h12.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4L18.6 8H6z"/></svg>
            {{ postData.Reactions.repostSelected ? 'Undo Repost' : 'Repost' }}
        </a>
        <a class="dropdown-item" href="#" (click)="openQuoteModal(postData); $event.stopPropagation()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" class="mr-1"><path class="heroicon-ui" d="M6.3 12.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h6a1 1 0 0 1 0 2H4v14h14v-6z"/></svg>
            Quote
        </a>
    </div>
</div>

<svg (click)="toggleReaction(postData.TSID, '3'); $event.stopPropagation()" 
     xmlns="http://www.w3.org/2000/svg" 
     viewBox="0 0 24 24" 
     width="24" 
     height="24">
    <path [style.fill]="postData.Reactions.likeSelected ? '#d70000' : '#878787'" [attr.d]="postData.Reactions.likeSelected ? 
          'M 12.76 3.76 a 6 6 0 0 1 8.48 8.48 l -8.53 8.54 a 1 1 0 0 1 -1.42 0 l -8.53 -8.54 a 6 6 0 0 1 8.48 -8.48 l 0.76 0.75 l 0.76 -0.75 z z' : 
          'M12.76 3.76a6 6 0 0 1 8.48 8.48l-8.53 8.54a1 1 0 0 1-1.42 0l-8.53-8.54a6 6 0 0 1 8.48-8.48l.76.75.76-.75zm7.07 7.07a4 4 0 1 0-5.66-5.66l-1.46 1.47a1 1 0 0 1-1.42 0L9.83 5.17a4 4 0 1 0-5.66 5.66L12 18.66l7.83-7.83z'"
          class="heroicon-ui">
    </path>
</svg>
<span class="reactions-number">{{ postData.Reactions.Likes }}</span>

<svg (click)="openScoreModal(postData); $event.stopPropagation();" 
     xmlns="http://www.w3.org/2000/svg" 
     viewBox="0 0 24 24" 
     width="24" 
     height="24">
    <path [style.fill]="postData.Reactions.replySelected ? '#d70000' : '#878787'" d="M2 15V5c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v15a1 1 0 0 1-1.7.7L16.58 17H4a2 2 0 0 1 -2-2z M20 5H4v10h13a1 1 0 0 1 .7.3l2.3 2.29V5z Z"/>
</svg>
