            <div class="d-none col-sm-2  d-sm-block  col-md-3 col-lg-3 d-flex flex-column text-sm-end text-md-start  align-items-lg-start left_sidebar border-end ">
                <div>
                    <span>
<!--                        <i class="fa-brands fa-twitter display-5 text-info py-3  "></i>-->
                        <img src="../../../../../images/mainlogo_lg.png" alt="Truth Shorts Logo" width="50" height="50" />

                    </span>
                    
                </div>
                <div class="d-flex align-items-center justify-content-sm-end justify-content-md-start">
                    <span><i class="fa fa-house fs-3 py-3"></i></span>
                    <p class="d-none d-md-block fs-6 pt-3 ps-2 fw-bold">Home</p>
                </div>
                <div class="d-flex align-items-center d-block d-md-none  justify-content-sm-end justify-content-md-start justify-content-sm-end justify-content-md-start">
                    <span><i class="fa fa-magnifying-glass fs-5 py-3 "></i></span>
                    <!-- <p class="d-none d-lg-block fs-3 pt-3 ps-1">Home</p> -->
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span class="col-1"><i class="fa fa-compass fs-5 py-3 d-none d-md-block"></i></span>
                    <p class="d-none d-md-block fs-6 pt-3 ps-2">Explore</p>
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span class="col-1"><i class="fa fa-bell fs-5 py-3"></i></span>
                    <p class="d-none d-md-block fs-6 pt-3 ps-2">Notifications</p>
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span class="col-1"><i class="fa fa-envelope fs-5 py-3"></i></span>
                    <p class="d-none d-md-block fs-6 pt-3 ps-2">Messages</p>
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span class="col-1"><i class="fa fa-bookmark fs-5 py-3"></i></span>
                    <p class="d-none d-md-block fs-6 pt-3 ps-2">Bookmark</p>
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span class="col-1"><i class="fa fa-list fs-5 py-3"></i></span>
                    <p class="d-none d-md-block fs-6 pt-3 ps-2">List</p>
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span class="col-1"><i class="fa fa-user fs-5 py-3"></i></span>
<!--                    <p class="d-none d-md-block fs-6 pt-3 ps-2"><a routerLink="/{{Username}}">Profile</a></p>-->
                    <p class="d-none d-md-block fs-6 pt-3 ps-2"><a [routerLink]="'/' + Username">Profile</a></p>
                </div>      
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span class="col-1"><i class="fa fa-cog fs-5 py-3"></i></span>
                    <p class="d-none d-md-block fs-6 pt-3 ps-2"><a routerLink="/dashboard_login">Settings</a></p>
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span class="col-1"><i class="fa fa-cog fs-5 py-3"></i></span>
                    <p class="d-none d-md-block fs-6 pt-3 ps-2"><a routerLink="/admin_dashboard">Admin Dashboard</a></p>
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span class="col-1"><i class="fa fa-ellipsis-h fs-5 py-3"></i></span>
                    <p class="d-none d-md-block fs-6 pt-3 ps-2">More</p>
                </div>
            </div>
