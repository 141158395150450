import { Injectable } from '@angular/core';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject, ReplaySubject, forkJoin, throwError } from 'rxjs';

import { PostsDataUrls } from '../global.urls';
import * as Classes from '../classes/models.classes';

//import { Http } from '@angular/http';

@Injectable({
    providedIn: 'any'
})
export class GlobalService {
    // Define your global variables here

    //private readonly tokenKey = 'auth_token';
    private inactivityTimeout: any;

    // Define static variables
    static readonly timeoutDuration: number = 60000; // 10 minutes. Timeout duration in milliseconds
    static readonly tokenName: string = 'auth_token'; // Name of the JWT token in local storage

    isLoggedIn: boolean = false;
    username: string = '';


  constructor(
    private http: HttpClient) {

  }

    // Define methods to manipulate the global variables
    setLoggedInStatus(status: boolean): void {
        this.isLoggedIn = status;
    }

    setUsername(name: string): void {
        this.username = name;
    }

}
