import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
//import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { LeftSidebarComponent } from './components/menus/left_sidebar/left_sidebar.component';
import { ProfileMenuComponent } from './components/menus/profile_menu/profile_menu.component';
import { ProfileMenuRepostComponent } from './components/menus/profile_menu_repost/profile_menu_repost.component';

import { CustomSliderComponent } from './components/insertable/custom-slider/custom-slider.component';
import { TruthScoreComponent } from './components/insertable/truth-score/truth-score.component';
import { ReactionsComponent } from './components/insertable/reactions/reactions.component';


import { HomeComponent } from './home/home.component';
import { HomeComponent_New } from './components/home/home.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { ProfileComponent } from './components/profile/profile.component';
import { PostComponent } from './components/post/post.component'; // Ensure this is imported 
import { HomeLanding } from './components/home_landing/home_landing.component';
import { Sign_In } from './components/authentications/sign_in/sign_in.component';
//import { CounterComponent } from './counter/counter.component';
//import { FetchDataComponent } from './fetch-data/fetch-data.component';

/* Application Routes */
import { AppRoutingModule } from './routing/routing.module'

/* Applicable Service */
import { GlobalService } from './services/global.service'
import { UserService } from './services/user.service'
import { InactivityTimeoutService } from './services/inactivity.timeout.service'
import { FraudIncidentDataService } from './services/fraud_incident.service'
import { UserPostsDataService } from './services/posts.service';
import { EmailService } from './services/email.service';
import { SessionService } from './services/session.service';

/* Applicable Modals */
import { QuoteModalComponent } from './modals/quote-modal/quote-modal.component';
import { ScoreModalComponent } from './modals/score-modal/score-modal.component';
import { RegistrationComponent } from './modals/sign_up.modal/sign_up.modal.component';
import { RegistrationValidationComponent } from './modals/sign_up_validation.modal/sign_up_validation.modal.component';

/* Applicable Pipes */
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ParseHashtagsPipe } from './pipes/parse-hashtags.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { UniquePipe } from './pipes/unique.pipe';

/* Applicable Guards */
import { AuthGuard } from './guards/auth.guard';

/* Toastr Module for Pop-Ups */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,

        BrowserAnimationsModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            timeOut: 5000 // 3 seconds timeout
        }),

        //RouterModule.forRoot([
        //  { path: '', component: HomeComponent, pathMatch: 'full' },
        //  { path: 'counter', component: CounterComponent },
        //  { path: 'fetch-data', component: FetchDataComponent },
        //])


        // or declare an AppRoutingModule component. It must be declared last
        AppRoutingModule,
        CommonModule,
        NgbModule /* Add me last */
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    exports: [
        UniquePipe,
        // Other components, pipes, directives
    ],
    declarations: [
        AppComponent,

        CustomSliderComponent,
        TruthScoreComponent,
        ReactionsComponent,

        NavMenuComponent,
        LeftSidebarComponent,
        ProfileMenuComponent,
        ProfileMenuRepostComponent,

        SafeHtmlPipe,
        HomeComponent,
        AdminDashboardComponent,
        ProfileComponent,
        PostComponent,
        HomeLanding,
        Sign_In,
        QuoteModalComponent,
        ScoreModalComponent,
        RegistrationComponent,
        RegistrationValidationComponent,
        HomeComponent_New,
        ParseHashtagsPipe,
        UniquePipe,
        RelativeTimePipe
    //CounterComponent,
    //FetchDataComponent
    //Modals declarations go here as well
    ],
    providers: [
        //SafeHtmlPipe,
        GlobalService,
        FraudIncidentDataService,
        UserService,
        InactivityTimeoutService,
        UserPostsDataService,
        AuthGuard,
        EmailService,
        SessionService
  ],
    entryComponents: [
        // Declarations of modal components here as well
        QuoteModalComponent,
        ScoreModalComponent,
        RegistrationComponent,
        RegistrationValidationComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
