import { Injectable } from '@angular/core';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject, ReplaySubject, forkJoin, throwError } from 'rxjs';

import { PostsDataUrls } from '../global.urls';
import * as Classes from '../classes/models.classes';

//import { Http } from '@angular/http';

@Injectable({
    providedIn: 'any'
})

export class DynamicRouteService {
    constructor(private http: HttpClient) { }

    getContent(route: string): Observable<string> {
        return this.http.get<string>(`https://api.example.com/content/${route}`);
    }
}