<div class="display-flex display-height-100" >

<!--    <header class="container-fluid bg-light">
      <div class="d-flex justify-content-center py-2">
        <img src="assets/logo.png" alt="Logo" class="logo">
      </div>
    </header>-->
    <main class="container main-content">
        <div class="row col-md-12">
            <!-- Left section with text and an image -->
            <div class="col-md-6 d-flex flex-column justify-content-center align-items-center">
<!--                <p>Welcome to XYZ Corp. Explore our services and features.</p>-->
                <img src="images/mainlogo_lg.png" alt="Introduction Image" class="img-fluid">
            </div>
            <!-- Right section with login form -->
            <div class="col-md-6 d-flex align-items-center" (keydown.enter)="login()" tabindex="0">
                <div class="w-100">
                    <div class="mb-3">
                        <input type="email" placeholder="Email" class="form-control" [(ngModel)]="email">
                    </div>
                    <div class="mb-3">
                        <input type="password" placeholder="Password" class="form-control" [(ngModel)]="password">
                    </div>
                    <!-- Error Message Display -->
                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>                    
                    <button class="btn btn-primary mb-2 w-100" (click)="login()">Log In</button>
                    <a href="#" class="d-block text-center mb-2">Forgot Password?</a>
                    <div class="text-center">
                        <button class="btn btn-secondary" (click)="openModal()">Create Account</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <footer class="container-fluid bg-light thin-footer">
        <ul class="footer-menu">
            <li><a href="#">About</a></li>
            <li><a routerLink="/privacy">Privacy Policy</a></li>
            <li><a routerLink="/tos">Terms of Service</a></li>
            <li><a href="#">Advertising</a></li>
        </ul>
    </footer>

</div>
