                                                    <div class="content-wrapper" style="display: inline-block;" #profileLink>
                                                        <span class="fw-bold username-link" >
                                                            <a [routerLink]="'/' + Username" class="profile-link" 
                                                                (mouseover)="loadUserProfile($event)" 
                                                                (mouseleave)="hideUserProfile()">{{ProfileName}} </a>
                                                            <a [routerLink]="'/' + Username" class="profile-username-link"
                                                                (mouseover)="loadUserProfile($event)" 
                                                                (mouseleave)="hideUserProfile()">@{{Username}} &#x2022; </a>
                                                            <span class="profile-link publish-date" 
                                                                (mouseover)="showTimeDetailHover($event)" 
                                                                (mouseleave)="hideUserProfile()">
                                                                {{PublishDate | relativeTime}}
                                                                <div *ngIf="showTooltip" class="tooltip">
                                                                    {{PublishDate | date:'mediumDate'}} at {{PublishDate | date:'shortTime'}}
                                                                </div>
                                                            </span>
                                                            <div class="score-graphic"
                                                                (mouseover)="loadUserProfile($event)" 
                                                                (mouseleave)="hideUserProfile()">
                                                                  <!-- <svg viewBox="0 0 200 151" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                                                                  <!-- Red line 
                                                                  <rect x="0" y="0" width="200" height="45" 
                                                                                [attr.fill]="userProfile?.FreedomScoreColor || '#ccc'" />
                                                                  <!-- Thin white line 
                                                                  <rect x="0" y="40" width="200" height="4" fill="none" />
                                                                  <!-- Blue line 
                                                                  <rect x="40" y="50" width="160" height="35" 
                                                                                [attr.fill]="userProfile?.TruthScoreColor || '#ccc'" />
                                                                  <!-- Thin white line 
                                                                  <rect x="0" y="80" width="200" height="4" fill="none" />
                                                                  <!-- Green line 
                                                                  <rect x="80" y="90" width="120" height="30" 
                                                                                [attr.fill]="userProfile?.PoliticalScoreColor || '#ccc'" />
                                                                  <!-- Thin white line 
                                                                  <rect x="0" y="0" width="200" height="4" fill="none" />
                                                                  <!-- Orange line 
                                                                  <rect x="120" y="125" width="80" height="25" 
                                                                                [attr.fill]="userProfile?.TransparencyScoreColor || '#ccc'" />
                                                                </svg>
                                                                -->

                                                                    <svg width="80px" height="80px" viewBox="0 0 207.96 209.541" xmlns="http://www.w3.org/2000/svg">
                                                                      <defs/>
                                                                      <g transform="matrix(1, 0, 0, 1, -270.426979, 0)">
                                                                        <path d="M 273.183 63.609 C 273.183 35.994 295.569 13.609 323.183 13.609 L 348.183 13.609 L 348.183 63.609 L 273.183 63.609 Z" 
                                                                          [ngStyle]="{ fill: userProfile?.FreedomScoreColor || '#DA6E1D' }"/>
                                                                        <rect x="273.188" y="63.1" width="123.126" height="30.021" [ngStyle]="{ fill: userProfile?.FreedomScoreColor || '#DA6E1D' }" rx="0.62" ry="0.62"/>
                                                                        <rect x="339.124" y="13.608" width="57.163" height="53.959"    [ngStyle]="{ fill: userProfile?.FreedomScoreColor || '#DA6E1D' }"/>
                                                                      </g>
                                                                      <g transform="matrix(1, 0, 0, 1, -270.426979, 0)">
                                                                        <path d="M 414.11 2.468 C 441.724 2.468 464.11 24.853 464.11 52.468 L 464.11 77.468 L 414.11 77.468 L 414.11 2.468 Z" 
                                                                          [ngStyle]="{ fill: userProfile?.TruthScoreColor || '#DA6E1D' }"/>
                                                                        <rect x="397.546" y="2.468" width="17.613" height="140.986" [ngStyle]="{ fill: userProfile?.TruthScoreColor || '#DA6E1D' }"/>
                                                                        <rect x="410.238" y="71.237" width="53.874" height="72.223" [ngStyle]="{ fill: userProfile?.TruthScoreColor || '#DA6E1D' }"/>
                                                                      </g>
                                                                      <g transform="matrix(1, 0, 0, 1, -270.426979, 0)">
                                                                        <path d="M 474.981 144.706 C 474.981 172.32 452.595 194.706 424.981 194.706 L 399.981 194.706 L 399.981 144.706 L 474.981 144.706 Z" 
                                                                          [ngStyle]="{ fill: userProfile?.PoliticalScoreColor || '#DA6E1D' }"/>
                                                                        <rect x="321.111" y="144.697" width="92.711" height="50.005" [ngStyle]="{ fill: userProfile?.PoliticalScoreColor || '#DA6E1D' }"/>
                                                                      </g>
                                                                      <g transform="matrix(1, 0, 0, 1, -270.426979, 0)">
                                                                        <path d="M 320.151 207.375 C 304.37 201.889 285.924 187.732 285.924 160.118 L 285.924 135.118 L 319.465 135.118 L 320.151 207.375 Z" 
                                                                          [ngStyle]="{ fill: userProfile?.TransparencyScoreColor || '#DA6E1D' }"/>
                                                                        <rect x="285.935" y="95.206" width="33.571" height="45.181" [ngStyle]="{ fill: userProfile?.TransparencyScoreColor || '#DA6E1D' }"/>
                                                                      </g>
                                                                    </svg>


<!--                                                                    <svg viewBox="0 0 200 200" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                                                                    <g>
                                                                        <title>Layer 1</title>
                                                                        <circle cx="100" cy="100" r="80" fill="none" 
                                                                                [attr.stroke]="userProfile?.FreedomScoreColor || '#ccc'" stroke-width="15"/>
                                                                        <circle cx="100" cy="100" r="60" fill="none" 
                                                                                [attr.stroke]="userProfile?.TruthScoreColor || '#ccc'" stroke-width="12"/>
                                                                        <circle cx="100" cy="100" r="40" fill="none" 
                                                                                [attr.stroke]="userProfile?.PoliticalScoreColor || '#ccc'" stroke-width="10"/>
                                                                        <circle cx="100" cy="100" r="20" fill="none" 
                                                                                [attr.stroke]="userProfile?.TransparencyScoreColor || '#ccc'" stroke-width="10"/>
                                                                    </g>
                                                                </svg>-->

                                                <!--                <div *ngIf="userProfile.freedomConfidenceScore !== null">Freedom Confidence: {{ userProfile.freedomConfidenceScore | percent }}</div>
                                                                <div *ngIf="userProfile.truthConfidenceScore !== null">Truth Confidence: {{ userProfile.truthConfidenceScore | percent }}</div>
                                                                <div *ngIf="userProfile.politicalConfidenceScore !== null">Political Confidence: {{ userProfile.politicalConfidenceScore | percent }}</div>
                                                                <div *ngIf="userProfile.transparencyConfidenceScore !== null">Transparency Confidence: {{ userProfile.transparencyConfidenceScore | percent }}</div>-->
                                                            </div>
                                                            <div *ngIf="showProfileBox && userProfile" [ngStyle]="hoverBoxStyle" class="user-hover-box"
                                                                 (mouseenter)="keepHoverBoxVisible()"
                                                                 (mouseleave)="hideUserProfile()">                                                                

                                                                <img [src]="userProfile?.ImageDirectory + '/' + userProfile?.UserImg" alt="User Image">
                                                                <div class="profile-link">{{ userProfile?.ProfileName }}</div>
                                                                <div class="profile-username-link">@{{ userProfile?.UserName }}</div>
                                                                <button (click)="followUser(); $event.stopPropagation()">Follow</button>
                                                                <p>{{ userProfile?.ProfileBio }}</p>
                                                                <div>
                                                                <span>Following: {{ userProfile?.FollowingCount }}</span> |
                                                                <span>Followers: {{ userProfile?.FollowersCount }}</span>
                                                                </div>


                                                            </div>
                                                        </span>       
                                                    </div>
