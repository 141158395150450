import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserPostsDataService } from '../../../services/posts.service';
import { QuoteModalComponent } from '../../../modals/quote-modal/quote-modal.component';
import { ScoreModalComponent } from '../../../modals/score-modal/score-modal.component';

@Component({
    selector: 'app-reactions',
    templateUrl: './reactions.component.html',
    styleUrls: ['./reactions.component.css']
})
export class ReactionsComponent {
    @Input() postData: any;
    @Input() UserID: number;
    @Output() reactionsUpdated = new EventEmitter<any>();

    isDropdownOpen = false;

    constructor(
        private userPostsDataService: UserPostsDataService,
        private modalService: NgbModal
    ) { }

    toggleReaction(postId: number, type: '1' | '2' | '3'): void {
        this.userPostsDataService.toggleReaction(postId, this.UserID, type).subscribe(updatedReactions => {
            this.postData.Reactions = updatedReactions;
            this.reactionsUpdated.emit(updatedReactions);
        }, error => {
            console.error('Error toggling reaction:', error);
        });
    }

    saveRePost(postId: number, type: '1' | '2' | '3', repostSelected: boolean, repostType: string, comment: string): void {
        this.userPostsDataService.saveUserRePostsData(postId, this.UserID, type, comment, repostSelected).subscribe(updatedReactions => {
            this.postData.Reactions = updatedReactions;
            this.reactionsUpdated.emit(updatedReactions);
            this.isDropdownOpen = false; // Close the dropdown menu
        }, error => {
            console.error('Error saving repost:', error);
        });
    }

    openQuoteModal(postData: any): void {
        const modalRef = this.modalService.open(QuoteModalComponent, {
            keyboard: false,
            backdrop: 'static',
            size: 'lg'
        });

        modalRef.componentInstance.postData = postData;
        modalRef.componentInstance.postQuote.subscribe((comment: string) => {
            if (comment.length > 0) {
                this.saveRePost(postData.TSID, '2', postData.Reactions.repostSelected, '2', comment);
            } else {
                this.saveRePost(postData.TSID, '2', postData.Reactions.repostSelected, '1', null);
            }
        });
    }

    openScoreModal(postData: any): void {
        const modalRef = this.modalService.open(ScoreModalComponent, {
            keyboard: false,
            backdrop: 'static',
            size: 'lg'
        });

        modalRef.componentInstance.postData = postData;
    }

    toggleDropdown(): void {
        this.isDropdownOpen = !this.isDropdownOpen;
    }
}
