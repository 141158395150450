// Validator Function
import { FormGroup, ValidatorFn } from '@angular/forms';

export function matchPasswordValidator(): ValidatorFn {
    return (formGroup: FormGroup): { [key: string]: any } | null => {
        const password = formGroup.get('Password');
        const confirmPassword = formGroup.get('ConfirmPassword');

        if (!password || !confirmPassword || !password.value || !confirmPassword.value) {
            return null;  // return null if fields haven't been initialized
        }

        return password.value === confirmPassword.value ? null : { 'passwordMismatch': true };
    };
}
