import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private expiration = new BehaviorSubject<string>(null);
    private userID = new BehaviorSubject<string>(null); 
    private username = new BehaviorSubject<string>(null);
    private userImg = new BehaviorSubject<string>(null);
    private userBannerImg = new BehaviorSubject<string>(null);

    constructor() {
        this.loadInitialData();
    }

    private loadInitialData() {
        const expiration = localStorage.getItem('expiration');
        const userID = localStorage.getItem('userID');
        const username = localStorage.getItem('username');
        const userImg = localStorage.getItem('userImg');
        const userBannerImg = localStorage.getItem('userBannerImg');
        if (expiration) {
            this.expiration.next(expiration);
        }
        if (userID) {
            this.userID.next(userID);
        }
        if (username) {
            this.username.next(username);
        }
        if (userImg) {
            this.userImg.next(userImg);
        }
        if (userBannerImg) {
            this.userBannerImg.next(userBannerImg);
        }
    }

    setExpiration(expiration: string) {
        localStorage.setItem('expiration', expiration);
        this.expiration.next(expiration);
    }

    setUserID(userID: string) {
        localStorage.setItem('userID', userID);
        this.userID.next(userID);
    }

    setUsername(username: string) {
        localStorage.setItem('username', username);
        this.username.next(username);
    }

    setUserImg(userImg: string) {
        localStorage.setItem('userImg', userImg);
        this.userImg.next(userImg);
    }

    setUserBannerImg(userBannerImg: string) {
        localStorage.setItem('userBannerImg', userBannerImg);
        this.userBannerImg.next(userBannerImg);
    }


    getExpiration(): Observable<string> {
        return this.expiration.asObservable();
    }

    getUserID(): Observable<string> {
        return this.userID.asObservable();
    }

    getUsername(): Observable<string> {
        return this.username.asObservable();
    }

    getUserImg(): Observable<string> {
        return this.userImg.asObservable();
    }

    getUserBannerImg(): Observable<string> {
        return this.userBannerImg.asObservable();
    }

    clearSession() {
        localStorage.removeItem('expiration');
        localStorage.removeItem('userID');
        localStorage.removeItem('username');
        localStorage.removeItem('userImg');
        localStorage.removeItem('userBannerImg');
        this.expiration.next(null);
        this.userID.next(null);
        this.username.next(null);
        this.userImg.next(null);
        this.userBannerImg.next(null);
    }
}
