// src/app/validators/phone-email.validator.ts
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function phoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null; // if the control has no value, return no error
        }

        const phonePattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        const isPhoneValid = phonePattern.test(control.value);

        return isPhoneValid ? null : { invalidPhone: true };
    };
}
