import { Injectable } from '@angular/core';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject, ReplaySubject, forkJoin, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Router, ActivatedRoute, NavigationStart } from '@angular/router';

import * as URLs from '../global.urls';
import { GlobalService } from '../services/global.service';
import { SessionService } from '../services/session.service';
import * as Classes from '../classes/models.classes';

//import { Http } from '@angular/http';

@Injectable({
    providedIn: 'any'
})
export class UserService {
    lcl_params: Classes.UserID_Params;
    //private readonly tokenKey = 'auth_token';
    private inactivityTimeout: any;

    private isAuthenticated = new BehaviorSubject<boolean>(false);

    isAuthenticated$ = this.isAuthenticated.asObservable();

    constructor(
        private globalService: GlobalService,
        private sessionService: SessionService,
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.checkAuthentication();
  }

    login(username: string, password: string): Observable<any> {
        //return this.http.post<any>(URLs.UserUrls.CheckLogin, { username, password });
        return this.http.post<any>(URLs.UserUrls.CheckLogin, { username, password }).pipe(
            tap(response => {
                this.sessionService.setExpiration(response.expiration);
                this.sessionService.setUserID(response.userID);
                this.sessionService.setUsername(response.username);
                this.sessionService.setUserImg(response.userImg);
                this.sessionService.setUserBannerImg(response.userBannerImg);
            })
        );
    }

    checkAuthentication2(): Observable<boolean> {
        return this.http.get<Classes.AuthResponse>(URLs.UserUrls.CheckToken).pipe(
            map(response => response.authenticated), // Directly map to the `authenticated` boolean
            catchError(error => {
                console.error('Error checking authentication', error);
                return of(false); // Ensure it returns an Observable<boolean>
            })
        );
    }

    checkAuthentication(): Observable<boolean> {
        return this.http.get<boolean>(URLs.UserUrls.CheckToken, { observe: 'response' })
            .pipe(
                map(response => response.status === 200),
                catchError(error => {
                    if (error.status === 401) {
                        // Unauthorized access
                        console.error('Authentication failed', error);
                        return of(false);
                    } else {
                        // Handle other types of errors
                        console.error('Error checking authentication', error);
                        return throwError(error);
                    }
                })
            );
    }


    registerUser(formData: any): Observable<any> {
        return this.http.post<any>(URLs.UserUrls.AddNewUser, formData);
    }

    logout(): void {

        this.http.post(URLs.UserUrls.Logout, {}).subscribe(() => {
            this.isAuthenticated.next(false);
            // Redirect to login or home page
            this.sessionService.clearSession;

            this.router.navigate(['/dashboard_login'], {
                relativeTo: this.route,

                // do not trigger navigation
                //skipLocationChange: true

            });
        });

        // Using HTTP-only tokens for Authentication so localStorage not necessary anymore.

        // Clear JWT token from local storage
        //localStorage.removeItem(GlobalService.tokenName);

        // Perform additional logout cleanup actions if needed

        // Clear token ('jwtToken') from storage or mark as expired
        //localStorage.removeItem(GlobalService.tokenName);




        // Redirect to login page or perform other actions
        this.router.navigate(['/dashboard_login'], {
            relativeTo: this.route,

            // do not trigger navigation
            //skipLocationChange: true

        });

    }

    getUserProfile(userName: string): Observable<Classes.ProfileModel> {
        const params = new HttpParams().set('userName', userName);
        return this.http.get<Classes.ProfileModel>(URLs.UserUrls.GetUserProfile, { params });
    }

    followUser(userName: string): Observable<any> {
        const body = { userName }; // Preparing the body data 
        return this.http.post(URLs.UserUrls.FollowUser, body);
    }

    // Method to start the inactivity timeout
    startInactivityTimeout(timeout: number): void {
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(() => {
            // Expire token or perform other actions on timeout
            this.expireToken();
        }, timeout);
    }

    // Method to reset the inactivity timeout
    resetInactivityTimeout(timeout: number): void {
        this.startInactivityTimeout(timeout);
    }

    // Method to expire the token
    expireToken(): void {
        this.logout();
    }

    // Method to handle user activity
    handleUserActivity(timeout: number): void {
        this.resetInactivityTimeout(timeout);
    }

    getToken(): string | null {
        // Retrieve JWT token from local storage
        return localStorage.getItem(GlobalService.tokenName);
    }

    isTokenExpired(): boolean {
        const token = this.getToken();
        if (!token) {
            // Token not found or expired
            return true;
        }

        // Extract expiration time from JWT token payload
        const expirationTime = this.getExpirationTime(token);

        // Calculate remaining time until expiration
        const currentTime = Math.floor(Date.now() / 1000); // Convert milliseconds to seconds
        const expiresIn = expirationTime - currentTime;

        // Return true if token has expired, false otherwise
        return expiresIn <= 0;
    }

    private getExpirationTime(token: string): number {
        // Extract expiration time (exp) from JWT token payload
        const payload = this.parseJwt(token);
        return payload ? payload.exp : 0;
    }

    private parseJwt(token: string): any {
        // Parse JWT token payload (base64url-encoded JSON)
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    }

}
