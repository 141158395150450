import { Component, Input, OnInit, OnChanges, ElementRef, ViewChild, ViewChildren, SimpleChange, SimpleChanges, OnDestroy, QueryList, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';


//import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

//import {ActiveToast, IndividualToastrConfig, ToastrService } from 'ngx-toaster';

import { UserService } from '../../../services/user.service';
import { UserPostsDataService } from '../../../services/posts.service';
import { InactivityTimeoutService  } from '../../../services/inactivity.timeout.service';

import  *  as Classes from '../../../classes/models.classes'

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}


@Component({
  selector: 'sign_in_page',
    templateUrl: './sign_in.component.html',
    styleUrls: ["./sign_in.component.css"]
})
export class Sign_In implements OnInit, OnDestroy, OnChanges{


    @Input() Mode: string;
    @Input() StateID: string;
    @Input() CategoryID: string; 

    FraudIncidentData: Array<Classes.VoterFraudIncident>;
    UserPostsData: Array<Classes.ShortsPosts>;

    FirstTabChecked: boolean;
    SecondTabChecked: boolean;
    ThirdTabChecked: boolean;
    FourthTabChecked: boolean;

    Category: string;
    State: string;
    UserID: number;
    //@ViewChild('tab1Checkbox') tab1Checkbox: ElementRef;
    //@ViewChild('tab2') tab2Checkbox;
    //@ViewChild('tab3') tab3Checkbox;
    //@ViewChild('tab5') tab4Checkbox;

    @ViewChild('ulContent') ulElementView: QueryList<ElementRef>;

    @ViewChildren('ulContent') ulChildrenComponent: QueryList<ElementRef>; 
    @ViewChildren('liContent') liChildrenComponent: QueryList<ElementRef>;

    @ViewChildren('incidentDiv') incidentDivComponent: QueryList<ElementRef>;
    @ViewChildren('incidentDivHome') incidentDivHomeComponent: QueryList<ElementRef>;
    @ViewChildren('incidentDivAbout') incidentDivAboutComponent: QueryList<ElementRef>;

    ulContentHeight: number;

  constructor(
        private userPostsDataService: UserPostsDataService,
        private _route: ActivatedRoute,
        private authService: UserService,
        private inactivityService: InactivityTimeoutService,
        private _router: Router
    //private formBuilder: FormBuilder
  ) {

  }

  ngOnInit() {
    debugger;
      this.FraudIncidentData = new Array<Classes.VoterFraudIncident>();
      this.UserPostsData = new Array<Classes.ShortsPosts>();

      this.UserID = 1;
      //console.log('this.StateID = ' + this.StateID + '; this.CategoryID = ' + this.CategoryID );

    }

    ngAfterViewInit() {

        console.log(this._route.snapshot.queryParams);

    }

      ngOnDestroy() {

      }
      ngOnChanges() {

        }

    login(username: string, password: string): void {
        // Call authentication service to authenticate user
        this.authService.login(username, password).subscribe(
            () => {
                // Authentication successful, start inactivity timeout
                this.inactivityService.initializeTimer();
                // Successful login: navigate to home or dashboard page
                this._router.navigate(['/dashboard']);

            },
            (error) => {
                // Handle authentication error
                console.error('Authentication failed:', error);
            }
        );
    }

    navigateToPage() {
        // changes the route without moving from the current view or
        // triggering a navigation event,
        this._router.navigate(['/dashboard_login'], {
            relativeTo: this._route,
            //queryParams: {
            //  stateIDParams: '1'
            //},
            // preserve the existing query params in the route
            //queryParamsHandling: 'merge',

            // do not trigger navigation
            skipLocationChange: true

        });
    }

}
