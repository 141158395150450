// src/app/services/registration.data.service.ts
import { Injectable } from '@angular/core';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, ReplaySubject, forkJoin, throwError } from 'rxjs';

import * as URLs from '../global.urls';
import { RegistrationUrls } from '../global.urls';
import * as Classes from '../classes/models.classes';


@Injectable({
    providedIn: 'root'
})
export class RegistrationDataService {
    private registrationData = new BehaviorSubject<any>({
        Name: '',
        Email: '',
        Phone: '',
        Birthday: '',
        Password: '',
        ConfirmPassword: ''
    });

    constructor(
        private http: HttpClient
    ) {

    }

    registrationData$ = this.registrationData.asObservable();

    setRegistrationData(data: any): void {
        this.registrationData.next(data);
    }

    getRegistrationData(): any {
        return this.registrationData.getValue();
    }

    sendVerificationCode(verificationDataContent: Classes.Verification): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        // Create request body with toEmail, toName, emailBody
        //const body = {
        //    ToEmail: toEmail,
        //    ToName: toName,
        //    EmailBody: emailBody
        //};

        return this.http.post<any>(RegistrationUrls.RequestVerificationCode, verificationDataContent, { headers });

    }

    verifyCode(data: Classes.CodeVerificationRequest): Observable<{ isValid: boolean }> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<{ isValid: boolean }>(RegistrationUrls.VerifyCodeUrl,  data , { headers });
    }


    registerUser(formData: any): Observable<any> {
        return this.http.post<any>(URLs.UserUrls.AddNewUser, formData);
    }

}
