import { Component, OnInit, Input  } from '@angular/core';

interface MenuItem {
    icon: string;
    label: string;
    link: string;
}

@Component({
    selector: 'app-left-sidebar',
    templateUrl: './left_sidebar.component.html',
    styleUrls: ['./left_sidebar.component.css']
})
export class LeftSidebarComponent implements OnInit {
    @Input() Username: string;  // Input property to receive username    menuItems: MenuItem[];  // Array to hold menu items
    menuItems: MenuItem[];  // Array to hold menu items

    constructor() {
        // Define menu items - this could instead be loaded from a service
        this.menuItems = [
            { icon: 'fa-house', label: 'Home', link: '/' },
            { icon: 'fa-compass', label: 'Explore', link: '/explore' },
            { icon: 'fa-bell', label: 'Notifications', link: '/notifications' },
            // Add more items as needed
        ];
    }

    ngOnInit(): void {
        // Additional initialization can be done here
    }
}
