// auth.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private authService: UserService,
        private router: Router
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (state.url === '/dashboard_login') {
            return of(true); // or `of(false)` depending on your logic to handle already being on the login page
        }
        return this.authService.checkAuthentication().pipe(
            map(authenticated => {
                if (authenticated) {
                    return true;
                }
                // Not authenticated, redirect to login with the return URL
                this.router.navigate(['/dashboard_login'], { queryParams: { returnUrl: state.url } });
                return false;
            }),
            catchError(() => {
                // In case of an error, redirect to login
                this.router.navigate(['/dashboard_login'], { queryParams: { returnUrl: state.url } });
                return of(false);
            })
        );
    }
}

