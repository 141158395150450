import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';

/*
 * Components that the router needs to be aware of should be listed below.
 */
import { HomeComponent_New } from '../components/home/home.component';
import { AdminDashboardComponent } from '../components/admin-dashboard/admin-dashboard.component';
import { HomeLanding } from '../components/home_landing/home_landing.component';
import { DynamicRouteComponent } from '../components/dynamic/dynamic.component';

import { Sign_In } from '../components/authentications/sign_in/sign_in.component';
import { ProfileComponent } from '../components/profile/profile.component'; // Ensure this is imported
import { PostComponent } from '../components/post/post.component'; // Ensure this is imported

import { PrivacyPolicy } from '../components/home_landing/link-pages/privacy-policy/privacy-policy.component'; // Ensure this is imported
import { TermsOfService } from '../components/home_landing/link-pages/terms-of-service/terms-of-service.component'; // Ensure this is imported

//import { RegistrationComponent } from '../modals/sign_up.modal/sign_up.modal.component';
//import { RegistrationValidationComponent } from '../modals/sign_up_validation.modal/sign_up_validation.modal.component';
//import { CounterComponent } from '../counter/counter.component';
//import { FetchDataComponent } from '../fetch-data/fetch-data.component';

import { AuthGuard } from '../guards/auth.guard';



const applicationRoutes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    { path: 'dashboard', component: HomeComponent_New, canActivate: [AuthGuard] },
    { path: 'sign_in', component: Sign_In },
    //{ path: 'sign_up', component: RegistrationComponent },
    //{ path: 'sign_up_validation', component: RegistrationValidationComponent },
    { path: 'dashboard_login', component: HomeLanding },
    { path: 'admin_dashboard', component: AdminDashboardComponent },

    { path: 'privacy', component: PrivacyPolicy },
    { path: 'tos', component: TermsOfService },

    { path: ':username', component: ProfileComponent }, // Use this for all profiles
    {
        path: ':username/posts/:postId',
        component: PostComponent
    },

    { path: '**', component: DynamicRouteComponent },
    //{ path: '', component: HomeComponent, pathMatch: 'full' },
    //{ path: 'counter', component: CounterComponent },
    //{ path: 'fetch-data', component: FetchDataComponent }
];

@NgModule({
  imports: [
    //RouterModule.forRoot(applicationRoutes)
    RouterModule.forRoot(applicationRoutes, { useHash: true })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {

}
