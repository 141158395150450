import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'parseHashtags'
})
export class ParseHashtagsPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }

    transform(value: string): SafeHtml {
        if (!value) return value;

        const regex = /#(\w+)/g;
        const replacedValue = value.replace(regex, `<a href="/hashtag/$1">#$1</a>`);

        return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
    }

}
