import { Component, OnInit  } from '@angular/core';
import { ScoreService } from '../../../services/scores.service';


@Component({
    selector: 'app-truth-score',
    templateUrl: './truth-score.component.html',
    styleUrls: ['./truth-score.component.css']
})
export class TruthScoreComponent implements OnInit {
    truthScore = 10;
    freedomScoreDescription = '';
    truthScoreDescription = '';
    politicalScoreDescription = '';
    objectivityScoreDescription = '';

    maxGroups = 3;
    truthScoreDetails = [{ link: '', linkType: '', reason: '' }];

    constructor(
        //public activeModal: NgbActiveModal,
        //private fb: FormBuilder,
        private scoreService: ScoreService
    ) { }

    ngOnInit(): void {
        this.updateScoreDescription('truth', this.truthScore);
    }

    onTruthScoreChange(newScore: number): void {
        this.truthScore = newScore;
        this.updateScoreDescription('truth', newScore);
    }

    updateScoreDescription(type: string, value: number): void {
        this.scoreService.getScoreDescription(type, value).subscribe(description => {
            if (type === 'freedom') {
                this.freedomScoreDescription = description;
            } else if (type === 'truth') {
                this.truthScoreDescription = description;
            } else if (type === 'political') {
                this.politicalScoreDescription = description;
            } else if (type === 'objectivity') {
                this.objectivityScoreDescription = description;
            }
        });
        //this.updateSliderThumbValue();
    }

    addGroup(): void {
        if (this.scoreService.truthScoreDetails.length < this.maxGroups) {
            this.scoreService.truthScoreDetails.push({ link: '', linkType: '', reason: '' });
        }
    }

    removeGroup(index: number): void {
        //this.truthScoreDetails.splice(index, 1);
        this.scoreService.truthScoreDetails.splice(index, 1);
    }
}
