// src/app/validators/name.validator.ts
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function nameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null; // If no value, we consider it as valid here, adjust based on requirement
        }
        const valid = /^[A-Za-z ]+$/.test(control.value); // Regex to allow letters and spaces
        return valid ? null : { invalidName: true }; // Return an error object if not valid
    };
}
