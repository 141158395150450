<div class="slider-container">
<!--  <input type="range" class="custom-slider" [min]="min" [max]="max" [value]="value" (input)="onSliderChange($event)" />-->
    <input type="range" class="custom-slider"
           [min]="min - 1" 
           [max]="max" 
           [value]="value !== null ? value : min - 1" 
           (input)="onSliderChange($event)" 
           (change)="onChange($event)" 
           [class.no-score]="value === null" />
  <div class="slider-thumb" [style.left.%]="getThumbPosition()">{{ value !== null ? value : '?' }}</div>
</div>
