import { Component, Input, OnInit, OnChanges, ElementRef, ViewChild, ViewChildren, SimpleChange, SimpleChanges, OnDestroy, QueryList, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

//import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

//import {ActiveToast, IndividualToastrConfig, ToastrService } from 'ngx-toaster';

import { GlobalService } from '../../services/global.service';
import { UserService } from '../../services/user.service';
import { InactivityTimeoutService } from '../../services/inactivity.timeout.service';
import { UserPostsDataService } from '../../services/posts.service';
import { DynamicRouteService } from '../../services/dynamic-route.service';



import  *  as Classes from '../../classes/models.classes'

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}


@Component({
  selector: 'app-dynamic-route',
  templateUrl: './dynamic.component.html',
    styleUrls: ["./dynamic.component.css"]
})
export class DynamicRouteComponent implements OnInit, OnDestroy, OnChanges{


    @Input() Mode: string;
    @Input() StateID: string;
    @Input() CategoryID: string; 

    routeContent: string;

    FraudIncidentData: Array<Classes.VoterFraudIncident>;
    UserPostsData: Array<Classes.ShortsPosts>;

    ProfileIconImage: string;

    FirstTabChecked: boolean;

    Category: string;
    State: string;
    UserID: number;

    @ViewChild('ulContent') ulElementView: QueryList<ElementRef>;

    @ViewChildren('ulContent') ulChildrenComponent: QueryList<ElementRef>; 
    @ViewChildren('liContent') liChildrenComponent: QueryList<ElementRef>;

    @ViewChildren('incidentDiv') incidentDivComponent: QueryList<ElementRef>;
    @ViewChildren('incidentDivHome') incidentDivHomeComponent: QueryList<ElementRef>;
    @ViewChildren('incidentDivAbout') incidentDivAboutComponent: QueryList<ElementRef>;

    ulContentHeight: number;

    timeoutDuration: number = 600000; // 10 minutes in milliseconds

    constructor(
        //private authService: UserService,
        //private globalService: GlobalService,
        private inactivityService: InactivityTimeoutService,
        private userPostsDataService: UserPostsDataService,
        private dynamicRouteService: DynamicRouteService,
        private route: ActivatedRoute,
        private router: Router
        //private formBuilder: FormBuilder
  ) {

  }

  ngOnInit() {
      debugger;

      const path = this.route.snapshot.url[0].path;
      this.dynamicRouteService.getContent(path).subscribe(
          content => {
              this.routeContent = content;
          },
          error => {
              this.router.navigateByUrl('/not-found');
          }
      );

      this.FraudIncidentData = new Array<Classes.VoterFraudIncident>();
      this.UserPostsData = new Array<Classes.ShortsPosts>();


      //console.log(this._route.snapshot.queryParams);

      this.FirstTabChecked = false;

      this.UserID = 1;

      // Start the inactivity timeout when the component initializes
      this.inactivityService.initializeTimer();

      this.getUserPostsData();
      //console.log('this.StateID = ' + this.StateID + '; this.CategoryID = ' + this.CategoryID );
    }

    ngAfterViewInit() {

        console.log(this.route.snapshot.queryParams);
    }

      ngOnDestroy() {

      }
      ngOnChanges() {

        }


    getUserPostsData() {

        this.userPostsDataService.loadUserPostsData(this.UserID).subscribe(data => {
            if (data.type === HttpEventType.DownloadProgress) {
                let percentDone: number = Math.round(100 + data.loaded / data.total);
                if (percentDone > 100) {
                    percentDone = 100;
                }
                else if (percentDone < 0) {
                    percentDone = 0;
                }
            } else if (data instanceof HttpResponse) {
                let loadedData: Array<Classes.ShortsPosts> = data.body;
                this.UserPostsData = loadedData;

                //this.ProfileIconImage = this.UserPostsData.

                //this.FraudIncidentData = data.body.map(x => Object.assign({}, x));

                //this.FraudIncidentData.forEach((elem: any) => {
                //console.log(elem);
                //});

            }
        })
    };

  navigateTo(stateID: string, categoryID: string, event: Event) {
    this.StateID = stateID;
    this.CategoryID = categoryID;

    this.router.navigate([], {
      queryParams: {
        StateID: stateID,
        CategoryID: categoryID
      },
      queryParamsHandling: 'merge',
    });
      this.FraudIncidentData = new Array<Classes.VoterFraudIncident>();


  }

  navigateToPage() {
    // changes the route without moving from the current view or
    // triggering a navigation event,
      this.router.navigate(['/dashboard_login'], {
      relativeTo: this.route,
      //queryParams: {
      //  stateIDParams: '1'
      //},
      // preserve the existing query params in the route
      //queryParamsHandling: 'merge',

      // do not trigger navigation
      skipLocationChange: true

    });
  }

}
