<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Terms of Service</title>
</head>
<body>
    <nav class="navbar">
        <div class="navbar-container">
            <a href="/" class="home-link">
                <img src="../../../../../../images/mainlogo_lg.png" alt="Truth Shorts Logo" width="50" height="50">
            </a>
            <button class="pdf-button" (click)="downloadPDF()">Download PDF</button>
        </div>
    </nav>
    <div class="terms-of-service-container" id="termsContent">
        <h1>Terms of Service</h1>
        <p>Effective Date: July 10, 2024</p>

        <h2>1. Introduction</h2>
        <p>Welcome to TruthShorts. By accessing or using our website, you agree to be bound by these Terms of Service and our Privacy Policy.</p>

        <h2>2. Changes to Terms</h2>
        <p>We reserve the right to modify these terms at any time. We will notify you of any changes by posting the new Terms of Service on this page. You are advised to review these Terms periodically for any changes.</p>

        <h2>3. Use of the Site</h2>
        <p>You agree to use the site only for lawful purposes. You are prohibited from violating or attempting to violate the security of the site or using it to gain unauthorized access to other computer systems.</p>

        <h2>4. User Accounts</h2>
        <p>When you create an account with us, you must provide us with accurate information. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer.</p>

        <h2>5. Intellectual Property</h2>
        <p>All content on this site, including text, graphics, logos, and images, is the property of TruthShorts or its content suppliers and is protected by copyright laws.</p>

        <h2>6. Termination</h2>
        <p>We may terminate or suspend your account and bar access to the site immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>

        <h2>7. Limitation of Liability</h2>
        <p>In no event shall TruthShorts, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the service.</p>

        <h2>8. Governing Law</h2>
        <p>These Terms shall be governed and construed in accordance with the laws of the United States, without regard to its conflict of law provisions.</p>

        <h2>9. Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us at:</p>
        <p><strong>TruthShorts</strong></p>
        <p>Email: support@truthshorts.com</p>
        <p>Address: PO Box 4100, Oakton, VA 22124, USA</p>
    </div>
</body>
</html>
