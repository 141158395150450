
<div id="main">
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
    <div class="container">
        <div style="margin-bottom:35px; margin-left:-10%;text-align:center;background:#404040;width:100%; position:fixed; top:0; z-index:50">
            <!--<img src="https://4.bp.blogspot.com/-7OHSFmygfYQ/VtLSb1xe8kI/AAAAAAAABjI/FxaRp5xW2JQ/s320/logo.png" style="width: 100px;margin-bottom:15px">-->
            &nbsp;
            <h1 id="title">TruthShorts.com</h1>
        </div>
        <nav>
            <div class="nav-fostrap">
                <ul>
                    <li class="{{(StateID == '8') ? 'highlight' : '' }}">
                        <a href="javascript:void(0);" (click)="navigateTo('8', '1', $event)">Home</a>
                    </li>
                    <li class="{{(StateID == '1') ? 'highlight' : '' }}">
                        <a href="javascript:void(0);" (click)="navigateTo('1', '1', $event)">Overview<span class="arrow-down"></span></a>
                        <ul class="dropdown">
                            <li><a href="javascript:void(0);" (click)="navigateTo('1', '1', $event)">Voter Fraud</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('1', '2', $event)">Election Fraud</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('1', '3', $event)">Election Irregularities</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('1', '4', $event)">Vote Integrity</a></li>
                        </ul>
                    </li>
                    <li class="{{(StateID == '2') ? 'highlight' : '' }}">
                        <a href="javascript:void(0);" (click)="navigateTo('2', '1', $event)">Arizona<span class="arrow-down"></span></a>
                        <ul class="dropdown">
                            <li><a href="javascript:void(0);" (click)="navigateTo('2', '1', $event)">Voter Fraud</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('2', '2', $event)">Election Fraud</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('2', '3', $event)">Election Irregularities</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('2', '4', $event)">Vote Integrity</a></li>
                        </ul>
                    </li>
                    <li class="{{(StateID == '3') ? 'highlight' : '' }}">
                        <a href="javascript:void(0);" (click)="navigateTo('3', '1', $event)">Georgia<span class="arrow-down"></span></a>
                        <ul class="dropdown">
                            <li><a href="javascript:void(0);" (click)="navigateTo('3', '1', $event)">Voter Fraud</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('3', '2', $event)">Election Fraud</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('3', '3', $event)">Election Irregularities</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('3', '4', $event)">Vote Integrity</a></li>
                        </ul>
                    </li>
                    <li class="{{(StateID == '4') ? 'highlight' : '' }}">
                        <a href="javascript:void(0);" (click)="navigateTo('4', '1', $event)">Michigan<span class="arrow-down"></span></a>
                        <ul class="dropdown">
                            <li><a href="javascript:void(0);" (click)="navigateTo('4', '1', $event)">Voter Fraud</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('4', '2', $event)">Election Fraud</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('4', '3', $event)">Election Irregularities</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('4', '4', $event)">Vote Integrity</a></li>
                        </ul>
                    </li>
                    <li class="{{(StateID == '5') ? 'highlight' : '' }}">
                        <a href="javascript:void(0);" (click)="navigateTo('5', '1', $event)">Nevada<span class="arrow-down"></span></a>
                        <ul class="dropdown">
                            <li><a href="javascript:void(0);" (click)="navigateTo('5', '1', $event)">Voter Fraud</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('5', '2', $event)">Election Fraud</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('5', '3', $event)">Election Irregularities</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('5', '4', $event)">Vote Integrity</a></li>
                        </ul>
                    </li>
                    <li class="{{(StateID == '6') ? 'highlight' : '' }}">
                        <a href="javascript:void(0);" (click)="navigateTo('6', '1', $event)">Pennsylvania<span class="arrow-down"></span></a>
                        <ul class="dropdown">
                            <li><a href="javascript:void(0);" (click)="navigateTo('6', '1', $event)">Voter Fraud</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('6', '2', $event)">Election Fraud</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('6', '3', $event)">Election Irregularities</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('6', '4', $event)">Vote Integrity</a></li>
                        </ul>
                    </li>
                    <li class="{{(StateID == '7') ? 'highlight' : '' }}">
                        <a href="javascript:void(0);" (click)="navigateTo('7', '1', $event)">Wisconsin<span class="arrow-down"></span></a>
                        <ul class="dropdown">
                            <li><a href="javascript:void(0);" (click)="navigateTo('7', '1', $event)">Voter Fraud</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('7', '2', $event)">Election Fraud</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('7', '3', $event)">Election Irregularities</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('7', '4', $event)">Vote Integrity</a></li>
                        </ul>
                    </li>
                    <li class="{{(StateID == '9') ? 'highlight' : '' }}">
                        <a href="javascript:void(0);" (click)="navigateTo('9', '1', $event)">About</a>
                    </li>
                </ul>
            </div>
            <div class="nav-bg-fostrap">
                <div class="navbar-fostrap"> <span></span> <span></span> <span></span> </div>
                <a href="" class="title-mobile">TruthShorts.com</a>
            </div>
        </nav>
        <!--<div class="top-bar" id="content-wrapper">
            <ng-container *ngIf="StateID == '8'">
                <div>
                    <h4 class="header-style"><span style="font-weight:700">Home</span></h4>
                </div>
            </ng-container>
            <ng-container *ngIf="StateID == '9'">
                <div>
                    <h4 class="header-style"><span style="font-weight:700">About</span></h4>
                </div>
            </ng-container>
            <ng-container *ngIf="StateID != '8' && StateID != '9'">
                <div>
                    <h4 class="header-style"><span style="font-weight:700">State:</span> {{State}}; <span style="font-weight:700"> Category: </span>{{Category}}</h4>
                </div>
            </ng-container>
        </div>-->
        <div id="content-wrapper" style="">
            <ng-container *ngIf="StateID == '8'">
                <div class="top-bar2">
                    <h4 class="header-style"><span style="font-weight:700">Home</span></h4>
                </div>
            </ng-container>
            <ng-container *ngIf="StateID == '9'">
                <div class="top-bar2">
                    <h4 class="header-style"><span style="font-weight:700">About</span></h4>
                </div>
            </ng-container>
            <ng-container *ngIf="StateID != '8' && StateID != '9'">
                <div class="top-bar2">
                    <h4 class="header-style"><span style="font-weight:700">State:</span> {{State}}; <span style="font-weight:700"> Category: </span>{{Category}}</h4>
                </div>
            </ng-container>


            <ng-container *ngFor="let data of FraudIncidentData; let i = index;">
                <ng-container *ngIf="data.StateID == 8">
                    <div style="padding:8px;font-size:15px;font-weight:bold" [innerHTML]="data.AllegationSummary | safeHtml">
                    </div>
                    <div class="col-sm-8" style="display:inline-block">
                        Last Update: <span style="color:#2ab5ea;font-size:12px;">{{data.LastUpdate | date:'short':'EST'}}</span>
                    </div>
                    <div style="border:1px #e8e8e8 solid;margin:0px 0px 10px 0px">
                        <a href="javascript:void(0);" (click)="expandTabHome(i, $event)">
                            &nbsp;<span class="fa fa-window-maximize"></span>
                        </a>
                        <a href="javascript:void(0);" (click)="minimizeTabHome(i, $event)">
                            &nbsp;<span class="fa fa-window-minimize"></span>
                        </a>
                    </div>
                    <div #incidentDivHome style="padding:8px;font-size:14px;max-height:370px;overflow:auto; " [innerHTML]="data.IncidentSummary | safeHtml">
                    </div>
                </ng-container>
                <ng-container *ngIf="data.StateID == 9">
                    <div style="padding:8px;font-size:17px;" [innerHTML]="data.AllegationSummary | safeHtml">
                    </div>
                    <div #incidentDivAbout style="padding:8px;font-size:14px;max-height:370px;overflow:auto; " [innerHTML]="data.IncidentSummary | safeHtml">
                    </div>
                </ng-container>
                <ng-container *ngIf="data.StateID != 8 && data.StateID != 9">
                    <div style="border:1px #e8e8e8 solid;margin:0px 0px 10px 0px">
                        <div style="border-bottom:1px #e8e8e8 solid;background-color:#f3f3f3;padding:8px;font-size:13px;font-weight:700;color:#45484d;">
                            <div class="col-sm-3" style="display:inline-block" [innerHTML]="'Allegation Type: ' + data.AllegationType | safeHtml">
                            </div>
                            <div class="col-sm-3" style="display:inline-block" [innerHTML]="'Incident Severity: ' + data.IncidentWeightDesc | safeHtml">
                            </div>
                            <div class="col-sm-3" style="display:inline-block" [innerHTML]="'Election Impact: ' + data.PotentialImpactDesc | safeHtml">
                            </div>
                        </div>
                        <div style="border-bottom:1px #e8e8e8 solid;background-color:#f3f3f3;padding:8px;font-size:13px;font-weight:700;color:#45484d;">
                            <div class="col-sm-3" style="display:inline-block" [innerHTML]="'Veracity Score: ' + data.VeracityScoreDesc | safeHtml">
                            </div>
                            <div class="col-sm-3" style="display:inline-block" [innerHTML]="'Affected Votes: ' + data.NumberOfVotesAffected | safeHtml">
                            </div>
                            <div class="col-sm-3" style="display:inline-block" [innerHTML]="'Affidavit: ' + data.AffidavitDesc | safeHtml">
                            </div>
                            <div class="col-sm-3" style="display:inline-block">
                                Last Update: <span style="color:#2ab5ea;">{{data.LastUpdate | date:'short':'EST'}}</span>
                            </div>
                        </div>
                        <h5 class="header-style">Allegation Summary</h5>
                        <div style="padding:8px;font-size:13px;" [innerHTML]="data.AllegationSummary | safeHtml">
                        </div>
                        <h5 class="header-style">
                            Incident Analysis
                            <span style="border:1px #e8e8e8 solid;margin:0px 0px 10px 0px">
                                <a href="javascript:void(0);" (click)="expandTab(i, $event)">
                                    &nbsp;<span class="fa fa-window-maximize"></span>
                                </a>
                                <a href="javascript:void(0);" (click)="minimizeTab(i, $event)">
                                    &nbsp;<span class="fa fa-window-minimize"></span>
                                </a>
                            </span>

                        </h5>

                        <div #incidentDiv style="padding:8px;font-size:13px;max-height:170px;overflow:auto; " [innerHTML]="data.IncidentSummary | safeHtml">
                        </div>
                    </div>
                </ng-container>
            </ng-container>






            <!--<div style="border:1px #e8e8e8 solid;width:49%;float:left;margin:10px 0px 10px 0px">
        <div style="border-bottom:1px #e8e8e8 solid;background-color:#f3f3f3;padding:8px;font-size:13px;font-weight:700;color:#45484d;">
            Admin Menu Content
        </div>
        <div style="padding:8px;font-size:13px;">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
        </div>
    </div>
    <div style="border:1px #e8e8e8 solid;width:49%;float:right;margin:10px 0px 10px 0px">
        <div style="border-bottom:1px #e8e8e8 solid;background-color:#f3f3f3;padding:8px;font-size:13px;font-weight:700;color:#45484d;">
            Admin Menu Content
        </div>
        <div style="padding:8px;font-size:13px;">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
        </div>
    </div>-->

        </div>
        </div>
    </div>
<!--<script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.0/jquery.min.js"></script>-->
