<!-- score-modal.component.html -->
<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">{{ postData.ProfileName }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
        <div class="row">
            <div class="col tablink" [class.active]="activeTab === 'freedom'" (click)="openTab('freedom')">Freedom Score</div>
            <div class="col tablink" [class.active]="activeTab === 'truth'" (click)="openTab('truth')">Truth Score</div>
            <div class="col tablink" [class.active]="activeTab === 'political'" (click)="openTab('political')">Bias Score</div>
            <div class="col tablink" [class.active]="activeTab === 'objectivity'" (click)="openTab('objectivity')">Objectivity Score</div>
<!--            <div class="col tablink" [class.active]="activeTab === 'categories'" (click)="openTab('categories')">Entity Categories</div>
            <div class="col tablink" [class.active]="activeTab === 'alternates'" (click)="openTab('alternates')">Alternate Entities</div>-->
        </div>

    <form [formGroup]="scoreForm">

        <div id="freedom" class="tabcontent" *ngIf="activeTab === 'freedom'">
            <!-- Freedom Score Content -->
              <h4>Freedom Score</h4>
                <app-custom-slider 
                    [value]="freedomScore" 
                    [min]="0" 
                    [max]="20" 
                    [noScoreValue]="-1" 
                    (valueChange)="onFreedomScoreChange($event)">
                </app-custom-slider>
                <div class="slider-label" *ngIf="freedomScore === null">No Score</div>
                <div class="slider-label" *ngIf="freedomScore !== null">{{ freedomScoreDescription }}</div>

              <!-- Score Details -->
            <div formArrayName="freedomScoreDetails">
                      <div *ngFor="let detail of freedomScoreDetails.controls; let i = index" [formGroupName]="i">
                      <div class="form-group">
                        <label for="freedomSupportingLink{{i}}">Supporting Link</label>
                        <input type="url" class="form-control" id="freedomSupportingLink{{i}}" formControlName="reasonLink" (blur)="validateUrl(detail, 'freedom', i)">
                        <div *ngIf="detail.get('reasonLink').invalid && (detail.get('reasonLink').dirty || detail.get('reasonLink').touched)">
                            <small class="text-danger" *ngIf="detail.get('reasonLink').errors.url">Invalid URL.</small>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="freedomScoreReason{{i}}">Score Reason</label>
                        <textarea class="form-control" id="freedomScoreReason{{i}}" formControlName="reasonDetail"  (blur)="validateReasonDetail(detail)"></textarea>
                        <div *ngIf="detail.get('reasonDetail').invalid && (detail.get('reasonDetail').dirty || detail.get('reasonDetail').touched)">
                          <small class="text-danger" *ngIf="detail.get('reasonDetail').errors.minLength">Score Reason must be at least 10 characters long.</small>
                          <small class="text-danger" *ngIf="detail.get('reasonDetail').errors.minWords">Score Reason must be at least 3 words long.</small>
                        </div>
                      </div>
                      <button type="button" class="btn btn-danger" (click)="removeFreedomGroup(i)" *ngIf="freedomScoreDetails.length > 1">-</button>
                    </div>

                    <button type="button" class="btn btn-primary" (click)="addFreedomGroup()" *ngIf="freedomScoreDetails.length < maxGroups" title="Add additional supporting information">+</button>

                  </div>
          </div>

        <div id="truth" class="tabcontent" *ngIf="activeTab === 'truth'">
        <!-- Truth Score Content -->
<!--            <app-truth-score></app-truth-score>-->
          <h4>Truth Score</h4>
<!--          <app-custom-slider [value]="truthScore" [min]="0" [max]="20" (valueChange)="onTruthScoreChange($event)"></app-custom-slider>
          <div class="slider-label">{{ truthScoreDescription }}</div>-->

                <app-custom-slider 
                    [value]="truthScore" 
                    [min]="0" 
                    [max]="20" 
                    [noScoreValue]="-1" 
                    (valueChange)="onTruthScoreChange($event)">
                </app-custom-slider>
                <div class="slider-label" *ngIf="truthScore === null">No Score</div>
                <div class="slider-label" *ngIf="truthScore !== null">{{ truthScoreDescription }}</div>

          <!-- Score Details -->
        <div formArrayName="truthScoreDetails">
            <div *ngFor="let detail of truthScoreDetails.controls; let i = index" [formGroupName]="i">
            <div class="form-group">
              <label for="truthSupportingLink{{i}}">Supporting Link</label>
              <input type="url" class="form-control" id="truthSupportingLink{{i}}" formControlName="reasonLink" (blur)="validateUrl(detail, 'truth', i)">
                <div *ngIf="detail.get('reasonLink').invalid && (detail.get('reasonLink').dirty || detail.get('reasonLink').touched)">
                    <small class="text-danger" *ngIf="detail.get('reasonLink').errors.url">Invalid URL.</small>
                </div>
            </div>
            <div class="form-group">
              <label for="truthScoreReason{{i}}">Score Reason</label>
              <textarea class="form-control" id="truthScoreReason{{i}}" formControlName="reasonDetail" (blur)="validateReasonDetail(detail)"></textarea>
                <div *ngIf="detail.get('reasonDetail').invalid && (detail.get('reasonDetail').dirty || detail.get('reasonDetail').touched)">
                    <small class="text-danger" *ngIf="detail.get('reasonDetail').errors.minLength">Score Reason must be at least 10 characters long.</small>
                    <small class="text-danger" *ngIf="detail.get('reasonDetail').errors.minWords">Score Reason must be at least 3 words long.</small>
                </div>
            </div>
            <button type="button" class="btn btn-danger" (click)="removeTruthGroup(i)" *ngIf="truthScoreDetails.length > 1">-</button>
          </div>
        </div>
          <button type="button" class="btn btn-primary" (click)="addTruthGroup()" *ngIf="truthScoreDetails.length < maxGroups" title="Add additional supporting information">+</button>

        </div>

        <div id="political" class="tabcontent" *ngIf="activeTab === 'political'">
        <!-- Political Leaning Content -->
          <h4>Bias Score</h4>
<!--          <app-custom-slider [value]="politicalScore" [min]="0" [max]="20" (valueChange)="onPoliticalScoreChange($event)"></app-custom-slider>
          <div class="slider-label">{{ politicalScoreDescription }}</div>-->

                <app-custom-slider 
                    [value]="politicalScore" 
                    [min]="0" 
                    [max]="20" 
                    [noScoreValue]="-1" 
                    (valueChange)="onPoliticalScoreChange($event)">
                </app-custom-slider>
                <div class="slider-label" *ngIf="politicalScore === null">No Score</div>
                <div class="slider-label" *ngIf="politicalScore !== null">{{ politicalScoreDescription }}</div>


          <!-- Score Details -->
        <div formArrayName="politicalScoreDetails">
          <div *ngFor="let detail of politicalScoreDetails.controls; let i = index" [formGroupName]="i">
            <div class="form-group">
              <label for="politicalSupportingLink{{i}}">Supporting Link</label>
              <input type="url" class="form-control" id="politicalSupportingLink{{i}}" formControlName="reasonLink" (blur)="validateUrl(detail, 'political', i)">
                <div *ngIf="detail.get('reasonLink').invalid && (detail.get('reasonLink').dirty || detail.get('reasonLink').touched)">
                    <small class="text-danger" *ngIf="detail.get('reasonLink').errors.url">Invalid URL.</small>
                </div>
            </div>
            <div class="form-group">
              <label for="politicalScoreReason{{i}}">Score Reason</label>
              <textarea class="form-control" id="politicalScoreReason{{i}}" formControlName="reasonDetail" (blur)="validateReasonDetail(detail)"></textarea>
                <div *ngIf="detail.get('reasonDetail').invalid && (detail.get('reasonDetail').dirty || detail.get('reasonDetail').touched)">
                    <small class="text-danger" *ngIf="detail.get('reasonDetail').errors.minLength">Score Reason must be at least 10 characters long.</small>
                    <small class="text-danger" *ngIf="detail.get('reasonDetail').errors.minWords">Score Reason must be at least 3 words long.</small>
                </div>
            </div>
            <button type="button" class="btn btn-danger" (click)="removePoliticalGroup(i)" *ngIf="politicalScoreDetails.length > 1">-</button>
          </div>
        </div>

          <button type="button" class="btn btn-primary" (click)="addPoliticalGroup()" *ngIf="politicalScoreDetails.length < maxGroups" title="Add additional supporting information">+</button>

        </div>

        <div id="objectivity" class="tabcontent" *ngIf="activeTab === 'objectivity'">
        <!-- Objectivity Content -->
          <h4>Objectivity Score</h4>
<!--          <app-custom-slider [value]="objectivityScore" [min]="0" [max]="20" (valueChange)="onObjectivityScoreChange($event)"></app-custom-slider>
          <div class="slider-label">{{ objectivityScoreDescription }}</div>-->

                <app-custom-slider 
                    [value]="objectivityScore" 
                    [min]="0" 
                    [max]="20" 
                    [noScoreValue]="-1" 
                    (valueChange)="onObjectivityScoreChange($event)">
                </app-custom-slider>
                <div class="slider-label" *ngIf="objectivityScore === null">No Score</div>
                <div class="slider-label" *ngIf="objectivityScore !== null">{{ objectivityScoreDescription }}</div>
          <!-- Score Details -->
        <div formArrayName="objectivityScoreDetails">
          <div *ngFor="let detail of objectivityScoreDetails.controls; let i = index" [formGroupName]="i">
            <div class="form-group">
              <label for="objectivitySupportingLink{{i}}">Supporting Link</label>
              <input type="url" class="form-control" id="objectivitySupportingLink{{i}}" formControlName="reasonLink" (blur)="validateUrl(detail, 'objectivity', i)">
                <div *ngIf="detail.get('reasonLink').invalid && (detail.get('reasonLink').dirty || detail.get('reasonLink').touched)">
                    <small class="text-danger" *ngIf="detail.get('reasonLink').errors.url">Invalid URL.</small>
                </div>
            </div>
            <div class="form-group">
              <label for="objectivityScoreReason{{i}}">Score Reason</label>
              <textarea class="form-control" id="objectivityScoreReason{{i}}" formControlName="reasonDetail" (blur)="validateReasonDetail(detail)"></textarea>
                <div *ngIf="detail.get('reasonDetail').invalid && (detail.get('reasonDetail').dirty || detail.get('reasonDetail').touched)">
                    <small class="text-danger" *ngIf="detail.get('reasonDetail').errors.minLength">Score Reason must be at least 10 characters long.</small>
                    <small class="text-danger" *ngIf="detail.get('reasonDetail').errors.minWords">Score Reason must be at least 3 words long.</small>
                </div>
            </div>
            <button type="button" class="btn btn-danger" (click)="removeObjectivityGroup(i)" *ngIf="objectivityScoreDetails.length > 1">-</button>
          </div>
        </div>

          <button type="button" class="btn btn-primary" (click)="addObjectivityGroup()" *ngIf="objectivityScoreDetails.length < maxGroups" title="Add additional supporting information">+</button>

    </div>
<!--    </div>-->
    </form>
<!--<div>
  <h1>Content Analysis</h1>
  <input type="text" [(ngModel)]="url" placeholder="Enter URL">
  <button (click)="analyzeContent()">Analyze</button>

  <div *ngIf="analysisResult">
    <h2>Analysis Results</h2>
    <pre>{{ analysisResult | json }}</pre>
  </div>
</div>-->

<!--<div>
  <h1>Fetch Meta Tags</h1>
  <input type="text" [(ngModel)]="url" placeholder="Enter URL">
  <button (click)="fetchMetaTags()">Fetch Meta Tags</button>

  <div *ngIf="metaTags">
    <h2>Meta Tags</h2>
    <p><strong>Title:</strong> {{ metaTags.Title }}</p>
    <p><strong>Description:</strong> {{ metaTags.Description }}</p>
    <p><strong>Image:</strong> <img [src]="metaTags.Image" alt="Meta Image"></p>
    <p><strong>URL:</strong> <a [href]="metaTags.Url">{{ metaTags.Url }}</a></p>
    <p><strong>Link Type:</strong> {{ linkType }}</p>
  </div>
</div>-->


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="onSave()">Save</button>

  </div>
</div>
