import { Component, Input, OnInit, AfterViewInit, AfterViewChecked, OnChanges, ElementRef, ViewChild, ViewChildren, SimpleChange, SimpleChanges, OnDestroy, HostListener, QueryList  } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import ResizeObserver from 'resize-observer-polyfill';

//import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

//import {ActiveToast, IndividualToastrConfig, ToastrService } from 'ngx-toaster'; 

import { ToastrService } from 'ngx-toastr';

import { GlobalService } from '../../services/global.service';
import { UserService } from '../../services/user.service';
import { InactivityTimeoutService } from '../../services/inactivity.timeout.service';
import { UserPostsDataService } from '../../services/posts.service';
import { SessionService } from '../../services/session.service';
import { SocialMediaService } from '../../services/social-media.service';

import { QuoteModalComponent } from '../../modals/quote-modal/quote-modal.component';
import { ScoreModalComponent } from '../../modals/score-modal/score-modal.component';

import  *  as Classes from '../../classes/models.classes'

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ["./admin-dashboard.component.css"]
})
export class AdminDashboardComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy, OnChanges{
    //@Input() Mode: string;
    @ViewChildren('postElement', { read: ElementRef }) postElements: QueryList<ElementRef>;


    private scrollEventSubscription: Subscription;
    allPostsLoaded = false; // Flag to check if all posts are loaded

    UserPostsData: Array<Classes.ShortsPosts>;
    UserPostsDataContent: Classes.ShortsPostSave;
    userProfileStates = new Map<number, any>();

    ProfileIconImage: string;

    selectedFile: File | null = null;

    UserID: number;
    Username: string;
    isOwnProfile: boolean = false;

    currentPage = 1;
    pageSize = 10;
    isLoading = false;

    showProfileBox : boolean = false;
    showTooltip: boolean = false;

    reactions: any = {};

    timeoutDuration: number = 600000; // 10 minutes in milliseconds

    postContent: string = '';

    userProfileVisible = false;
    hoverBoxStyle = {};
    userProfile: any = {};
    hoverTimer: any; // To hold the timer reference
    private timeoutId: any; // Holds the reference to the timeout

    private isTwitterWidgetsLoaded = false;


    constructor(
        //private authService: UserService,
        private socialMediaService: SocialMediaService,
        private sessionService: SessionService,
        private inactivityService: InactivityTimeoutService,
        private userPostsDataService: UserPostsDataService,
        private userService: UserService,
        private modalService: NgbModal,

        private toastr: ToastrService,

        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router
        //private formBuilder: FormBuilder
  ) {

  }

  ngOnInit(): void {
    debugger;
      this.UserPostsData = new Array<Classes.ShortsPosts>();
      this.UserPostsDataContent = new Classes.ShortsPostSave();

      //console.log(this._route.snapshot.queryParams);

      this.sessionService.getUserID().subscribe(userID => {
          this.UserID = Number(userID);
      });

      this.sessionService.getUsername().subscribe(username => {
          this.Username = username;
          //this.isOwnProfile = username === this.Username; // Check if the profile belongs to the logged-in user
      });

      // Start the inactivity timeout when the component initializes
      this.inactivityService.initializeTimer();

      this.getUserPostsDataNew();
      this.subscribeToScrollEvents();
      //console.log('this.StateID = ' + this.StateID + '; this.CategoryID = ' + this.CategoryID );
    }

    ngAfterViewInit() {
        this.postElements.changes.subscribe(() => {
            this.setupIntersectionObservers();
        });
        this.setupIntersectionObservers();

    }

    ngAfterViewChecked(): void {
    }

      ngOnDestroy() {
          this.scrollEventSubscription?.unsubscribe();
    }

      ngOnChanges() {

    }

    private subscribeToScrollEvents() {
        this.scrollEventSubscription = fromEvent(window, 'scroll')
            .pipe(throttleTime(500))
            .subscribe(() => this.onScroll());
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(): void {
        this.showProfileBox = false;
        this.showTooltip = false;
        // Clear the timeoutId after it executes
        this.timeoutId = null;
        this.hoverTimer = null;        

        if (this.isLoading || this.allPostsLoaded) return;

        const nearToEndIndex = Math.max(this.UserPostsData.length - 3, 0);
        const triggerElementId = `post${(this.currentPage - 1) * this.pageSize + nearToEndIndex}`;
        const triggerElement = document.getElementById(triggerElementId);

        if (triggerElement && (window.scrollY + window.innerHeight > triggerElement.offsetTop) && !this.isLoading) {
            this.currentPage++;
            this.getUserPostsDataNew();
        }
    }


    fetchAndSaveTweets(): void {
        this.socialMediaService.fetchAndSavePopularTweets().subscribe({
            next: () => this.toastr.success('Tweets fetched and saved successfully!'),
            error: () => this.toastr.warning('Failed to fetch and save tweets.')
        });
    }


    loadTwitterWidgets(): void {
        const script = document.createElement('script');
        script.src = 'https://platform.twitter.com/widgets.js';
        script.async = true;
        script.charset = 'utf-8';
        document.head.appendChild(script);
    }

    setupIntersectionObservers(): void {
        if (this.postElements && this.postElements.length > 0) {
            this.postElements.forEach((postElement) => {
                this.setupIntersectionObserver(postElement);
            });
        }
    }

    setupIntersectionObserver(postElement: ElementRef): void {
        const observer = new IntersectionObserver((entries, obs) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const postContent = (entry.target as HTMLElement).innerHTML;
                    if (this.isTwitterPost(postContent)) {
                        this.loadTwitterWidgets();
                    }
                    if (this.isFacebookPostType(postContent)) {
                        this.loadFacebookWidgets(entry.target as HTMLElement);
                    }
                    obs.unobserve(entry.target); // Ensure the element is unobserved after being intersected
                    //observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.1 });

        if (postElement.nativeElement instanceof HTMLElement) {
            observer.observe(postElement.nativeElement);
        }
    }

    isTwitterPost(content: string): boolean {
        return content.includes('twitter-tweet');
    }

    isFacebookPostType(content: string): boolean {
        const facebookPatterns = ['facebook.com', 'fb.watch'];
        return facebookPatterns.some(pattern => content.includes(pattern));
    }

    isFacebookPost(content: string): boolean {
        const facebookPatterns = ['post.php'];
        return facebookPatterns.some(pattern => content.includes(pattern));
    }

    isFacebookEmbeddedPost(content: string): boolean {
        const facebookPatterns = ['iframe'];
        return facebookPatterns.some(pattern => content.includes(pattern));
    }

    //loadTwitterWidgets(): void {
    //    if (window['twttr'] && window['twttr'].widgets) {
    //        window['twttr'].widgets.load();
    //    } else {
    //        const script = document.createElement('script');
    //        script.src = 'https://platform.twitter.com/widgets.js';
    //        script.async = true;
    //        script.charset = 'utf-8';
    //        document.head.appendChild(script);
    //    }
    //}

    loadFacebookWidgets(element: HTMLElement): void {
        if (window['FB'] && window['FB'].XFBML) {
            window['FB'].XFBML.parse();
            this.resizeFacebookIframes(element);
        } else {
            const script = document.createElement('script');
            script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v11.0';
            //script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
            script.async = true;
            script.defer = true;
            script.crossOrigin = 'anonymous';
            script.onload = () => {
                window['FB'].XFBML.parse();
                //this.resizeFacebookIframes(element);
                this.observeFacebookIframes(element);
            };
            document.head.appendChild(script);
        }
    }

    resizeFacebookIframes(element: HTMLElement): void {
        const iframes = element.querySelectorAll('iframe');
        iframes.forEach((iframe: HTMLIFrameElement) => {
            iframe.style.width = '100%';
            //iframe.style.height = 'auto';
            iframe.style.borderRadius = '0.85rem';

            //const resizeObserver = new ResizeObserver(entries => {
            //    for (let entry of entries) {
            //        if (entry.target instanceof HTMLIFrameElement) {
            //            entry.target.style.height = entry.contentRect.height + 'px';
            //        }
            //    }
            //});

            //resizeObserver.observe(iframe);
        });
    }

    observeFacebookIframes(element: HTMLElement): void {
        const fbPosts = element.querySelectorAll('.fb-post-embedded');
        fbPosts.forEach((fbPost: HTMLElement) => {
            const resizeObserver = new ResizeObserver(() => {
                this.adjustIframeHeight(fbPost);
            });

            resizeObserver.observe(fbPost);
        });
    }

    adjustIframeHeight(fbPost: HTMLElement): void {
        try {
            const iframe = fbPost.querySelector('iframe');
            if (iframe) {
                //iframe.style.height = (fbPost.clientHeight*.958) + 'px';
                iframe.style.width = '100%';
                iframe.style.borderRadius = '0.85rem';
            }
        } catch (error) {
            console.warn('Could not access iframe content due to cross-origin restrictions', error);
        }
    }

    getFacebookPostHref(content: string): string {
        console.log('Post Content:', content); // Debugging line to check content
        // Adjust regex to be more flexible with possible variations
        const match = content.match(/data-href=['"]([^'"]+)['"]/);
        const href = match ? match[1] : '';
        console.log('Extracted Href:', href); // Debugging line to check extracted href
        return href;
    }

    sanitizeHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    followUser(userName: string) {
        this.userService.followUser(userName).subscribe({
            next: (result) => {
                console.log('User followed successfully');
            },
            error: (error) => {
                console.error('Error following the user', error);
            }
        });
    }

    getUserPostsDataNew() {
        this.isLoading = true;
        let postTypes: number[] | undefined;
        // For User Profile Posts, I gather Original - 1; Reposts - 2; and Quotes - 3; Replies - 4; Quotes - 5
        // For User Profile Posts, I gather Twitter Admin Post - 20;
        postTypes = [1, 3, 5, 20]; // Specify the actual types you need

        this.userPostsDataService.getDashboardPosts(this.UserID, this.currentPage, this.pageSize, postTypes).subscribe({
        //this.userPostsDataService.getDashboardPosts(this.UserID, this.currentPage, this.pageSize).subscribe({
            next: posts => {
                this.UserPostsData = this.UserPostsData.concat(posts); // Append new data to existing posts
                this.isLoading = false;
                if (posts.length < this.pageSize) { // Less data than expected
                    this.allPostsLoaded = true; // Assume all posts are loaded
                }
            },
            error: error => {
                console.error('There was an error!', error);
                this.isLoading = false;
            }
        });
    }

    getUserPostsData() {
        this.userPostsDataService.loadUserPostsData(this.UserID).subscribe(data => {
            if (data.type === HttpEventType.DownloadProgress) {
                let percentDone: number = Math.round(100 + data.loaded / data.total);
                if (percentDone > 100) {
                    percentDone = 100;
                }
                else if (percentDone < 0) {
                    percentDone = 0;
                }
            } else if (data instanceof HttpResponse) {
                let loadedData: Array<Classes.ShortsPosts> = data.body;
                this.UserPostsData = loadedData;

                //this.ProfileIconImage = this.UserPostsData.

                //this.FraudIncidentData = data.body.map(x => Object.assign({}, x));

                //this.FraudIncidentData.forEach((elem: any) => {
                //console.log(elem);
                //});

            }
        })
    };

    loadReactions(postId: number) {
        this.userPostsDataService.getReactions(postId).subscribe(data => {
            this.reactions = data;
        });
    }

    toggleReaction(postId: number, type: '1' | '2' | '3'): void {
        this.userPostsDataService.toggleReaction(postId, this.UserID, type).subscribe(updatedReactions => {
            const post = this.UserPostsData.find(p => p.TSID === postId);
            if (post) {
                post.Reactions = updatedReactions; // Update the reactions with the returned data
                // Toggle the selected state for the specific reaction type
                //switch (type) {
                //    case 'reply':
                //        post.Reactions.replySelected = !post.Reactions.replySelected;
                //        break;
                //    case 'repost':
                //        post.Reactions.repostSelected = !post.Reactions.repostSelected;
                //        break;
                //    case 'like':
                //        post.Reactions.likeSelected = !post.Reactions.likeSelected;
                //        break;
                //}
            }
        }, error => {
            console.error('Error toggling reaction:', error);
        });
    }

    saveRePost(postId: number, type: '1' | '2' | '3', repostSelected: boolean, repostType: string, comment: string ): void {
        this.userPostsDataService.saveUserRePostsData(postId, this.UserID, type, comment, repostSelected).subscribe(updatedReactions => {
            const post = this.UserPostsData.find(p => p.TSID === postId);
            if (post) {
                post.Reactions = updatedReactions; // Update the reactions with the returned data
            }
        }, error => {
            console.error('Error toggling reaction:', error);
        });
    }

    submitPost() {

        // Call checkVideo with the mock event
        //this.checkVideo(event as any);

        this.UserPostsDataContent.UserID = this.UserID;
        this.UserPostsDataContent.PostType = 1;
        this.UserPostsDataContent.PublishDate = new Date() // Date is set at the time of submission
        if (this.selectedFile.name.length > 0 ) {
            this.UserPostsDataContent.FileName = this.selectedFile.name;
            this.UserPostsDataContent.VideoMimeType = this.selectedFile.type;
        }

        const formData = new FormData();

        formData.append('postData', JSON.stringify(this.UserPostsDataContent)); // Serialize your object into a JSON string
        if (this.selectedFile) {
            formData.append('file', this.selectedFile, this.selectedFile.name);
        }


        this.userPostsDataService.saveUserPostsData(formData).subscribe(data => {
            if (data.type === HttpEventType.DownloadProgress) {
                let percentDone: number = Math.round(100 + data.loaded / data.total);
                if (percentDone > 100) {
                    percentDone = 100;
                }
                else if (percentDone < 0) {
                    percentDone = 0;
                }
            } else if (data instanceof HttpResponse) {
                let loadedData: number = data.body;
                //var x = loadedData;

                //this.ProfileIconImage = this.UserPostsData.

                //this.FraudIncidentData = data.body.map(x => Object.assign({}, x));

                //this.FraudIncidentData.forEach((elem: any) => {
                //console.log(elem);
                //});

            }
        })
    };

    savePost(): void {

        this.UserPostsDataContent.UserID = this.UserID;
        this.UserPostsDataContent.PostType = 1;
        this.UserPostsDataContent.PublishDate = new Date() // Date is set at the time of submission
        if (this.selectedFile.name.length > 0) {
            this.UserPostsDataContent.FileName = this.selectedFile.name;
            this.UserPostsDataContent.VideoMimeType = this.selectedFile.type;
        }

        const formData = new FormData();

        formData.append('postData', JSON.stringify(this.UserPostsDataContent)); // Serialize your object into a JSON string
        if (this.selectedFile) {
            formData.append('file', this.selectedFile, this.selectedFile.name);
        }

        this.userPostsDataService.savePost(formData, this.UserID).subscribe(
            () => console.log("Post saved successfully."),
            error => console.error("Error saving post", error)
        );
    }

    // Method to trigger the file input click event
    triggerFileUpload(): void {
        document.getElementById('videoUpload')!.click();
    }

    checkVideo(event: any): void {
        this.selectedFile = event.target.files[0];

        if (!this.selectedFile) {
            return;
        }

        const fileType = this.selectedFile.type;

        //if (this.selectedFile) {
        // Handle videos
        if (this.selectedFile.type.startsWith('video/')) {
            // Check the file type
            if (this.selectedFile.type !== 'video/mp4' && this.selectedFile.type !== 'video/quicktime') {
                alert('Unsupported video format. Please upload a MP4 or MOV file.');
                return;
            }

            // Load the file into a video element to check duration
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                const duration = video.duration;

                if (duration > 120) { // Check if video is longer than 2 minutes
                    alert('Video is too long. Maximum allowed duration is 2 minutes.');
                } else {
                    alert('Video is valid. Duration: ' + duration + ' seconds.');
                    // Proceed with further processing like uploading
                }
            };

            video.onerror = () => {
                alert('Unable to load the video for validation.');
            };

            video.src = URL.createObjectURL(this.selectedFile);

            return; // Exit function after handling video
        }

        // Handle images
        if (this.selectedFile.type.startsWith('image/')) {
            // Image-specific processing logic here

            if (this.selectedFile.type === 'image/gif') {
                alert('GIF file selected.');
                // Additional GIF-specific checks can go here
            } else {
                alert('Image file selected.');
            }

            // Proceed with further processing like uploading
        }
    }

    uploadVideo(file: File): void {
        const formData = new FormData();
        formData.append('video', file);

        //this.http.post('https://yourserver.com/upload', formData).subscribe(
        //    response => {
        //        console.log('Upload successful', response);
        //    },
        //    error => {
        //        console.error('Upload failed', error);
        //    }
        //);
    }

    openQuoteModal(postData: any): void {
        const modalRef = this.modalService.open(QuoteModalComponent, {
            //windowClass: "myCustomModalClass",
            keyboard: false,
            backdrop: 'static',
            size: 'lg'
        });
        //modalRef.componentInstance.registrationModalRef = this;

        modalRef.componentInstance.postData = postData;
        modalRef.componentInstance.postQuote.subscribe((comment: string) => {
            if (comment.length > 0) {
                // Handle the quote with the comment
                console.log('Comment to post:', comment);
                // Add your saveRePost logic here
                this.saveRePost(postData.TSID, '2', postData.Reactions.repostSelected, '2', comment);
            }
            else {
                this.saveRePost(postData.TSID, '2', postData.Reactions.repostSelected, '1', null);
            }
        });

    }

    openScoreModal(postData: any): void {
        const modalRef = this.modalService.open(ScoreModalComponent, {
            //windowClass: "myCustomModalClass",
            keyboard: false,
            backdrop: 'static',
            size: 'lg'
        });
        //modalRef.componentInstance.registrationModalRef = this;

        modalRef.componentInstance.postData = postData;

    }

    navigateToPost(username: string, postId: number): void {
        this.router.navigate([`${username}/posts/${postId}`]);
    }

    navigateToPage() {
    // changes the route without moving from the current view or
    // triggering a navigation event,
      this.router.navigate(['/dashboard_login'], {
      relativeTo: this.route,
      //queryParams: {
      //  stateIDParams: '1'
      //},
      // preserve the existing query params in the route
      //queryParamsHandling: 'merge',

      // do not trigger navigation
      skipLocationChange: true

    });
    }

    testToastr(): void {
        this.toastr.success('Toastr is working!');
    }

}
