import { Component, Input, OnInit, OnChanges, ElementRef, ViewChild, ViewChildren, SimpleChange, SimpleChanges, OnDestroy, HostListener, QueryList, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

//import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

//import {ActiveToast, IndividualToastrConfig, ToastrService } from 'ngx-toaster';

import { GlobalService } from '../../services/global.service';
import { UserService } from '../../services/user.service';
import { InactivityTimeoutService } from '../../services/inactivity.timeout.service';
import { UserPostsDataService } from '../../services/posts.service';
import { SessionService } from '../../services/session.service';

import { QuoteModalComponent } from '../../modals/quote-modal/quote-modal.component';

import  *  as Classes from '../../classes/models.classes'

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ["./profile.component.css"]
})
export class ProfileComponent implements OnInit, OnDestroy, OnChanges{

    private scrollEventSubscription: Subscription;
    allPostsLoaded = false; // Flag to check if all posts are loaded

    UserPostsData: Array<Classes.ShortsPosts>;
    UserPostsDataContent: Classes.ShortsPostSave;
    userProfileStates = new Map<number, any>();

    ProfileIconImage: string;

    selectedFile: File | null = null;

    UserID: number;
    Username: string;
    UserImg: string;
    BannerImageUrl: string;

    isOwnProfile: boolean = false;

    currentPage = 1;
    pageSize = 10;
    isLoading = false;

    showProfileBox: boolean = false;
    showTooltip: boolean = false;

    reactions: any = {};

    ulContentHeight: number;

    timeoutDuration: number = 600000; // 10 minutes in milliseconds

    postContent: string = '';

    userProfileVisible = false;
    hoverBoxStyle = {};
    userProfile: any = {};
    hoverTimer: any; // To hold the timer reference
    private timeoutId: any; // Holds the reference to the timeout

    constructor(
        //private authService: UserService,
        //private globalService: GlobalService,
        private sessionService: SessionService,
        private inactivityService: InactivityTimeoutService,
        private userPostsDataService: UserPostsDataService,
        private userService: UserService,
        private modalService: NgbModal,

        private route: ActivatedRoute,
        private router: Router
        //private formBuilder: FormBuilder
  ) {

  }

  ngOnInit(): void {
    debugger;
      this.UserPostsData = new Array<Classes.ShortsPosts>();
      this.UserPostsDataContent = new Classes.ShortsPostSave();

      this.route.params.subscribe(params => {
          this.Username = params['username'];
      });

      this.sessionService.getUserID().subscribe(userID => {
          this.UserID = Number(userID);
      });

      this.sessionService.getUserImg().subscribe(userImg => {
          this.UserImg = userImg;
      });

      this.sessionService.getUserBannerImg().subscribe(bannerImageUrl => {
          this.BannerImageUrl = "./img_profile/" + this.UserID + "/" + bannerImageUrl;
      });

      this.sessionService.getUsername().subscribe(username => {
          this.isOwnProfile = username === this.Username; // Check if the profile belongs to the logged-in user
      });

      // Start the inactivity timeout when the component initializes
      this.inactivityService.initializeTimer();

      this.getUserPostsDataNew();
      this.subscribeToScrollEvents();
    }

    ngAfterViewInit() {

        //console.log(this.route.snapshot.queryParams);
    }

    ngOnDestroy() {
        this.scrollEventSubscription?.unsubscribe();
    }

      ngOnChanges() {

    }

    private subscribeToScrollEvents() {
        this.scrollEventSubscription = fromEvent(window, 'scroll')
            .pipe(throttleTime(500))
            .subscribe(() => this.onScroll());
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(): void {
        this.showProfileBox = false;
        this.showTooltip = false;
        // Clear the timeoutId after it executes
        this.timeoutId = null;
        this.hoverTimer = null;        

        if (this.isLoading || this.allPostsLoaded) return;

        const nearToEndIndex = Math.max(this.UserPostsData.length - 3, 0);
        const triggerElementId = `post${(this.currentPage - 1) * this.pageSize + nearToEndIndex}`;
        const triggerElement = document.getElementById(triggerElementId);

        if (triggerElement && (window.scrollY + window.innerHeight > triggerElement.offsetTop) && !this.isLoading) {
            this.currentPage++;
            this.getUserPostsDataNew();
        }

    }

    loadUserProfile(UserName: any, index: number, event: MouseEvent) {

        event.stopPropagation(); // Add this to prevent event bubbling
        // Check the class of the event target to determine which functionality to trigger
        const target = event.target as HTMLElement;
        if (target.classList.contains('publish-date')) {
            // Logic for showing the date tooltip
            this.hoverTimer = setTimeout(() => {
                this.showTooltip = true;
            }, 1000);  // Delay in milliseconds
        } else {
            this.hoverTimer = setTimeout(() => {
                if (!this.userProfileStates.has(index)) {
                    this.userProfileStates.set(index, { visible: false });
                }

                this.userService.getUserProfile(UserName).subscribe(data => {

                    const element = event.target as HTMLElement;
                    const rect = element.getBoundingClientRect();

                    // Calculate available space below the link
                    const spaceBelow = window.innerHeight - rect.bottom;
                    const hoverBoxHeight = 200; // Assume a fixed height or calculate dynamically

                    if (spaceBelow < hoverBoxHeight) {

                        // Not enough space below, position above
                        this.hoverBoxStyle = {
                            bottom: `30px` // Position above the link
                        };
                    }
                    else {
                        this.hoverBoxStyle = {
                            top: `30px` // Position above the link
                        };
                    }

                    this.userProfileStates.get(index).profile = data;
                    this.userProfileStates.get(index).visible = true;

                    this.showProfileBox = true;
                });
            }, 1000); // Delay of 1000 milliseconds (1 second)
        }
    }

    hideUserProfile(index: number) {
        clearTimeout(this.hoverTimer); // Clear the timer if the mouse leaves the link
        this.showTooltip = false;
        this.showProfileBox = false;
        if (this.userProfileStates.has(index)) {
            this.userProfileStates.get(index).visible = false;
        }
    }

    followUser(userName: string) {
        this.userService.followUser(userName).subscribe({
            next: (result) => {
                console.log('User followed successfully');
            },
            error: (error) => {
                console.error('Error following the user', error);
            }
        });
    }
    getUserPostsDataNew() {
        this.isLoading = true;
        let postTypes: number[] | undefined;
        // For User Profile Posts, I gather Original - 1; Reposts - 2; and Quotes - 3; Replies - 4; Quotes - 5
        postTypes = [1, 2, 3, 5]; // Specify the actual types you need

        this.userPostsDataService.getPosts(this.UserID, this.currentPage, this.pageSize, postTypes).subscribe({
            next: posts => {
                this.UserPostsData = this.UserPostsData.concat(posts); // Append new data to existing posts
                this.isLoading = false;
                if (posts.length < this.pageSize) { // Less data than expected
                    this.allPostsLoaded = true; // Assume all posts are loaded
                }
            },
            error: error => {
                console.error('There was an error!', error);
                this.isLoading = false;
            }
        });    }

    getUserPostsData() {
        this.userPostsDataService.loadUserPostsData(this.UserID).subscribe(data => {
            if (data.type === HttpEventType.DownloadProgress) {
                let percentDone: number = Math.round(100 + data.loaded / data.total);
                if (percentDone > 100) {
                    percentDone = 100;
                }
                else if (percentDone < 0) {
                    percentDone = 0;
                }
            } else if (data instanceof HttpResponse) {
                let loadedData: Array<Classes.ShortsPosts> = data.body;
                this.UserPostsData = loadedData;
            }
        })
    };

    loadReactions(postId: number) {
        this.userPostsDataService.getReactions(postId).subscribe(data => {
            this.reactions = data;
        });
    }

    toggleReaction(postId: number, type: '1' | '2' | '3'): void {
        this.userPostsDataService.toggleReaction(postId, this.UserID, type).subscribe(updatedReactions => {
            const post = this.UserPostsData.find(p => p.TSID === postId);
            if (post) {
                post.Reactions = updatedReactions; // Update the reactions with the returned data
            }
        }, error => {
            console.error('Error toggling reaction:', error);
        });
    }

    saveRePost(postId: number, type: '1' | '2' | '3', repostSelected: boolean, repostType: string, comment: string ): void {
        this.userPostsDataService.saveUserRePostsData(postId, this.UserID, type, comment, repostSelected).subscribe(updatedReactions => {
            const post = this.UserPostsData.find(p => p.TSID === postId);
            if (post) {
                post.Reactions = updatedReactions; // Update the reactions with the returned data
            }
        }, error => {
            console.error('Error toggling reaction:', error);
        });
    }

    submitPost() {

        // Call checkVideo with the mock event
        //this.checkVideo(event as any);

        this.UserPostsDataContent.UserID = this.UserID;
        this.UserPostsDataContent.PostType = 1;
        this.UserPostsDataContent.PublishDate = new Date() // Date is set at the time of submission
        if (this.selectedFile.name.length > 0 ) {
            this.UserPostsDataContent.FileName = this.selectedFile.name;
            this.UserPostsDataContent.VideoMimeType = this.selectedFile.type;
        }

        const formData = new FormData();

        formData.append('postData', JSON.stringify(this.UserPostsDataContent)); // Serialize your object into a JSON string
        if (this.selectedFile) {
            formData.append('file', this.selectedFile, this.selectedFile.name);
        }


        this.userPostsDataService.saveUserPostsData(formData).subscribe(data => {
            if (data.type === HttpEventType.DownloadProgress) {
                let percentDone: number = Math.round(100 + data.loaded / data.total);
                if (percentDone > 100) {
                    percentDone = 100;
                }
                else if (percentDone < 0) {
                    percentDone = 0;
                }
            } else if (data instanceof HttpResponse) {
                let loadedData: number = data.body;

            }
        })
    };

    savePost(): void {

        this.UserPostsDataContent.UserID = this.UserID;
        this.UserPostsDataContent.PostType = 1;
        this.UserPostsDataContent.PublishDate = new Date() // Date is set at the time of submission
        if (this.selectedFile.name.length > 0) {
            this.UserPostsDataContent.FileName = this.selectedFile.name;
            this.UserPostsDataContent.VideoMimeType = this.selectedFile.type;
        }

        const formData = new FormData();

        formData.append('postData', JSON.stringify(this.UserPostsDataContent)); // Serialize your object into a JSON string
        if (this.selectedFile) {
            formData.append('file', this.selectedFile, this.selectedFile.name);
        }

        this.userPostsDataService.savePost(formData, this.UserID).subscribe(
            () => console.log("Post saved successfully."),
            error => console.error("Error saving post", error)
        );
    }

    // Method to trigger the file input click event
    triggerFileUpload(): void {
        document.getElementById('videoUpload')!.click();
    }

    checkVideo(event: any): void {
        this.selectedFile = event.target.files[0];

        if (!this.selectedFile) {
            return;
        }

        const fileType = this.selectedFile.type;

        //if (this.selectedFile) {
        // Handle videos
        if (this.selectedFile.type.startsWith('video/')) {
            // Check the file type
            if (this.selectedFile.type !== 'video/mp4' && this.selectedFile.type !== 'video/quicktime') {
                alert('Unsupported video format. Please upload a MP4 or MOV file.');
                return;
            }

            // Load the file into a video element to check duration
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                const duration = video.duration;

                if (duration > 120) { // Check if video is longer than 2 minutes
                    alert('Video is too long. Maximum allowed duration is 2 minutes.');
                } else {
                    alert('Video is valid. Duration: ' + duration + ' seconds.');
                    // Proceed with further processing like uploading
                }
            };

            video.onerror = () => {
                alert('Unable to load the video for validation.');
            };

            video.src = URL.createObjectURL(this.selectedFile);

            return; // Exit function after handling video
        }

        // Handle images
        if (this.selectedFile.type.startsWith('image/')) {
            // Image-specific processing logic here

            if (this.selectedFile.type === 'image/gif') {
                alert('GIF file selected.');
                // Additional GIF-specific checks can go here
            } else {
                alert('Image file selected.');
            }

            // Proceed with further processing like uploading
        }
    }

    uploadVideo(file: File): void {
        const formData = new FormData();
        formData.append('video', file);

    }

    openQuoteModal(postData: any): void {
        const modalRef = this.modalService.open(QuoteModalComponent);
        modalRef.componentInstance.postData = postData;
        modalRef.componentInstance.postQuote.subscribe((comment: string) => {
            if (comment.length > 0) {
                // Handle the quote with the comment
                console.log('Comment to post:', comment);
                // Add your saveRePost logic here
                this.saveRePost(postData.TSID, '2', postData.Reactions.repostSelected, '2', comment);
            }
            else {
                this.saveRePost(postData.TSID, '2', postData.Reactions.repostSelected, '1', null);
            }
        });

    }

  navigateTo(stateID: string, categoryID: string, event: Event) {

    this.router.navigate([], {
      queryParams: {
        StateID: stateID,
        CategoryID: categoryID
      },
      queryParamsHandling: 'merge',
    });

  }

    navigateToPost(username: string, postId: number): void {
        this.router.navigate([`${username}/posts/${postId}`]);
    }

  navigateToPage() {
    // changes the route without moving from the current view or
    // triggering a navigation event,
      this.router.navigate(['/dashboard_login'], {
      relativeTo: this.route,
      //queryParams: {
      //  stateIDParams: '1'
      //},
      // preserve the existing query params in the route
      //queryParamsHandling: 'merge',

      // do not trigger navigation
      skipLocationChange: true

    });
  }

}
