
    <div class="container-fluid container-md">
        <div class="row ">
            <!-- LEFT SIDE BAR -->
            <!-- In your parent component's template -->
            <app-left-sidebar class="col-sm-2 col-md-3" [Username]="Username"></app-left-sidebar>

            <!-- MAIN SECTION -->
            <div class="col-12 col-sm-10 col-md-9 col-lg-6  main_bar ">
                <div class="row d-flex justify-content-between align-items-center ps-1">
                    <div class="col-1 pt-2">
                        <div><span class="fs-4 fw-bold">Home</span></div>
                    </div>
                    <div class="col-1 me-3">
                        <div><span class="fs-4"><i class="fa fa-star"></i> </span></div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col pt-2 ">
                        <div class="row">
<!--                            <div class="col-2">
                                <img class="rounded-circle" src="./asserts/images/profile.jfif" alt="" height="50px">
                            </div>-->
                            <div class="col-2 user-avatar-container">
<!--                                <img class="circular-image" [src]="'./img_profile/' + UserPostsData[0].UserID + '/' + UserPostsData[0].UserImg"  alt="" height="50px">-->
                            </div>

                            <div class="col-9 col-sm-10 ">
                                <input type="text" placeholder="What's on your mind?" class="rder-0 bg  form-control-lg"  [(ngModel)]="UserPostsDataContent.PostContent">
                                <div class="ps-3 d-flex text-info">
                                    <p><span><i class="fa fa-earth-americas "></i></span></p>
                                    <p class="fw-bold ps-1 ">Everyone can reply</p>
                                    <hr>
                                </div>
                                <div class="row pb-2 justify-content-center">
                                    <div class="col-9 text-info">
                                        <!-- Hidden file input for uploading videos -->
                                        <input type="file" id="videoUpload" (change)="checkVideo($event)" accept="video/*,image/jpeg,image/png,image/gif"  style="display: none;">
                                        <span class="ps-3" (click)="triggerFileUpload()">
                                            <span style="display: inline-block; "> 
                                            <svg (click)="triggerFileUpload(); $event.stopPropagation();" 
                                                    xmlns="http://www.w3.org/2000/svg" 
                                                    viewBox="0 0 283 283" 
                                                    width="18" 
                                                    height="18">
                                                <path style="fill: rgb(135, 135, 135);" d="m149.823,258.142c-31.398,30.698 -81.882,30.576 -113.105,-0.429c-31.214,-30.987 -31.337,-81.129 -0.42,-112.308l-0.026,-0.018l113.569,-112.772l14.203,-14.098c23.522,-23.356 61.65,-23.356 85.172,0s23.522,61.221 0,84.586l-125.19,123.02l-0.044,-0.035c-15.428,14.771 -40.018,14.666 -55.262,-0.394c-15.244,-15.069 -15.34,-39.361 -0.394,-54.588l-0.044,-0.053l13.94,-13.756l69.701,-68.843l13.931,13.774l-83.632,82.599c-7.701,7.596 -7.701,19.926 0,27.53s20.188,7.604 27.88,0l124.918,-123.37l-0.035,-0.026l0.473,-0.403c15.682,-15.568 15.682,-40.823 0,-56.39s-41.094,-15.568 -56.776,0l-0.42,0.473l-0.026,-0.018l-14.194,14.089l-113.576,112.773c-23.522,23.356 -23.522,61.221 0,84.577s61.659,23.356 85.163,0l99.375,-98.675l14.194,-14.089l14.194,14.089l-14.194,14.098l-99.357,98.675c0,-0.001 -0.018,-0.018 -0.018,-0.018z"/>
                                            </svg>
                                            </span>
                                        </span>
                                        <span class="ps-3"><i class="fa fa-gift"></i></span>
                                        <span class="ps-3"><i class="fa fa-face-grin"></i></span>
                                        <span class="ps-3"><i class="fa fa-location-pin"></i></span>
                                    </div>
                                    <div class="col-3 col-sm-2  ">
                                        <button class="text-decoration-none fs-6  fw-bold text-white bg-info py-1 px-3  rounded-pill"
                                            [disabled]="!UserPostsDataContent.PostContent" (click)="savePost()">Post</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row  d-flex  justify-content-between align-items-center border">
                            <div *ngFor="let postData of UserPostsData; let i = index" #postElement>
                                <div [id]="'post' + ((currentPage - 1) * pageSize + i)" class="post-container" (click)="navigateToPost(postData.UserName, postData.TSID)">
                                    <ng-container *ngIf="postData.PostType === 1"> <!-- share post without image -->

                                        <!-- POST 1 -->
                                        <div class="row mt-1 border d-flex  ">
                                            <div class="col-1 mt-2">
                                                <div class="user-avatar-container"> 
    <!--                                                <img class="circular-image" [src]="'./img_profile/' + postData.UserID + '/' + postData.UserImg"  alt="" height="50px">-->
                                                    <img class="circular-image" [src]="postData?.ImageDirectory + '/' + postData?.UserImg"  alt="" height="50px">
                                                </div>
                                            </div>
                                            <div class="col-11 ">
                                                <div class="row d-flex justify-content-between align-items-center ps-1">
                                                    <div class="col-12 pt-2 ">
                                                        <div>
    <!--                                                    <div class="content-wrapper" style="display: inline-block;">
                                                            <span class="fw-bold username-link" 
                                                                    (mouseover)="loadUserProfile_home(postData.UserName, i, $event)" 
                                                                    (mouseleave)="hideUserProfile_home(i)">
                                                                <a [routerLink]="'/' + postData.UserName" class="profile-link">{{postData.ProfileName}} </a>
                                                                <a [routerLink]="'/' + postData.UserName" class="profile-username-link">@{{postData.UserName}} &#x2022; </a>
                                                                <span class="profile-link publish-date" 
                                                                    (mouseover)="loadUserProfile_home(postData, i, $event)" 
                                                                    (mouseleave)="hideUserProfile_home(i)">
                                                                    {{postData.PublishDate | relativeTime}}
                                                                    <div *ngIf="showTooltip" class="tooltip">
                                                                        {{postData.PublishDate | date:'mediumDate'}} at {{postData.PublishDate | date:'shortTime'}}
                                                                    </div>
                                                                </span>
                                                                <div *ngIf="showProfileBox && userProfileStates.get(i)?.profile" [ngStyle]="hoverBoxStyle" class="user-hover-box">

                                                                    <img [src]="postData.ImageDirectory + '/' + userProfileStates.get(i)?.profile?.UserImg" alt="User Image">
                                                                    <div class="profile-link">{{ userProfileStates.get(i)?.profile?.ProfileName }}</div>
                                                                    <div class="profile-username-link">@{{ userProfileStates.get(i)?.profile?.UserName }}</div>
                                                                    <button (click)="followUser(userProfileStates.get(i)?.profile?.UserName); $event.stopPropagation()">Follow</button>
                                                                    <p>{{ userProfileStates.get(i)?.profile?.ProfileBio }}</p>
                                                                    <div>
                                                                    <span>Following: {{ userProfileStates.get(i)?.profile?.FollowingCount }}</span> |
                                                                    <span>Followers: {{ userProfileStates.get(i)?.profile?.FollowersCount }}</span>
                                                                    </div>
                                                                </div>
                                                            </span>       
                                                        </div>-->
                                                          <app-profile-menu
                                                            [ProfileName]="postData.ProfileName"
                                                            [Username]="postData.UserName"
                                                            [PublishDate]="postData.PublishDate"
                                                            [ImageDirectory]="postData?.ImageDirectory">
                                                          </app-profile-menu>                                                    

    <!--<svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
     <g>
      <title>Layer 1</title>
      <circle cx="100" cy="100" r="80" fill="none" 
              [attr.stroke]="postData.Score.Freedom" stroke-width="15" id="svg_1"/>
      <circle cx="100" cy="100" r="60" fill="none" 
              [attr.stroke]="postData.Score.Truth" stroke-width="12" id="svg_2"/>
      <circle cx="100" cy="100" r="40" fill="none" 
              [attr.stroke]="postData.Score.Political" stroke-width="10" id="svg_3"/>
      <circle cx="100" cy="100" r="20" fill="none" 
              [attr.stroke]="postData.Score.Transparency" stroke-width="10" id="svg_4"/>
     </g>
    </svg>-->

                                                        </div>
												            <a href="post-detail.html" class="post-title">{{postData.PostTitle}}</a>
                                                            <p [innerHTML]="postData.PostContent | parseHashtags"></p>
        <!--												        <p >
                                                                {{postData.PostContent | parseHashtags}}													        
												            </p>-->
                                                            <div *ngIf="postData.FileName">
                                                              <!-- Check if the MIME type is a video format -->
                                                              <div class="video-container" *ngIf="postData.VideoMimeType?.startsWith('video/')">
                                                                <video controls class="responsive-video">
                                                                    <source [src]="'api/content/media?fileName=' + postData.FileName + '&mimeType=' + postData.VideoMimeType + '&userID=' + postData.OrigUserID" [type]="postData.VideoMimeType">
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                              </div>
                                                            <!-- Check if the MIME type is an image format -->
                                                            <div class="image-container" *ngIf="postData.VideoMimeType?.startsWith('image/')">
                                                                <div class="responsive-image-wrapper">
                                                                <img class="responsive-image" [src]="'api/content/media?fileName=' + postData.FileName + '&mimeType=' + postData.VideoMimeType + '&userID=' + postData.OrigUserID" [alt]="postData.FileName" >
                                                                </div>
                                                            </div>                                                    
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-12 reactions">
                                                    <app-reactions [postData]="postData" [UserID]="UserID" (reactionsUpdated)="onReactionsUpdated(postData.TSID, $event)"></app-reactions>
                                                </div>
    <!--                                            <div class="col-12 reactions">
                                                        <svg (click)="toggleReaction(postData.TSID, '1'); $event.stopPropagation();" 
                                                             xmlns="http://www.w3.org/2000/svg" 
                                                             viewBox="0 0 24 24" 
                                                             width="24" 
                                                             height="24">
                                                            <path [style.fill]="postData.Reactions.replySelected ? '#d70000' : '#878787'" d="M2 15V5c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v15a1 1 0 0 1-1.7.7L16.58 17H4a2 2 0 0 1 -2-2z M20 5H4v10h13a1 1 0 0 1 .7.3l2.3 2.29V5z Z"/>
                                                        </svg>
                                                        <span class="reactions-number">{{ postData.Reactions.Replies }}</span>
                                                        <div class="dropdown">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 viewBox="0 0 24 24"
                                                                 width="24"
                                                                 height="24"
                                                                 class="dropdown-toggle"
                                                                 id="dropdownRepostMenu"
                                                                 data-toggle="dropdown"
                                                                 aria-haspopup="true"
                                                                 aria-expanded="false"
                                                                 (click)="$event.stopPropagation()">

                                                                <path [style.fill]="postData.Reactions.repostSelected ? '#d70000' : '#878787'" d="M5.41 16H18a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-4 4H5.41l2.3 2.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.4L5.4 16zM6 8a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4h12.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4L18.6 8H6z"/>
                                                            </svg>
                                                            <span class="reactions-number">{{ postData.Reactions.Reposts }}</span>
                                                            <div class="dropdown-menu" aria-labelledby="dropdownRepostMenu">
                                                                <a class="dropdown-item" href="#" (click)="saveRePost(postData.TSID, '2', postData.Reactions.repostSelected, '1', null); $event.stopPropagation();">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" class="mr-1"><path class="heroicon-ui" d="M5.41 16H18a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-4 4H5.41l2.3 2.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.4L5.4 16zM6 8a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4h12.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4L18.6 8H6z"/></svg>
                                                                    Repost
                                                                </a>
                                                                <a class="dropdown-item" href="#" (click)="openQuoteModal(postData); $event.stopPropagation()">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" class="mr-1"><path class="heroicon-ui" d="M6.3 12.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h6a1 1 0 0 1 0 2H4v14h14v-6z"/></svg>
                                                                    Quote
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <svg (click)="toggleReaction(postData.TSID, '3'); $event.stopPropagation()" 
                                                             xmlns="http://www.w3.org/2000/svg" 
                                                             viewBox="0 0 24 24" 
                                                             width="24" 
                                                             height="24">
                                                          <path [style.fill]="postData.Reactions.likeSelected ? '#d70000' : '#878787'" [attr.d]="postData.Reactions.likeSelected ? 
                                                                'M 12.76 3.76 a 6 6 0 0 1 8.48 8.48 l -8.53 8.54 a 1 1 0 0 1 -1.42 0 l -8.53 -8.54 a 6 6 0 0 1 8.48 -8.48 l 0.76 0.75 l 0.76 -0.75 z z' : 
                                                                'M12.76 3.76a6 6 0 0 1 8.48 8.48l-8.53 8.54a1 1 0 0 1-1.42 0l-8.53-8.54a6 6 0 0 1 8.48-8.48l.76.75.76-.75zm7.07 7.07a4 4 0 1 0-5.66-5.66l-1.46 1.47a1 1 0 0 1-1.42 0L9.83 5.17a4 4 0 1 0-5.66 5.66L12 18.66l7.83-7.83z'"
                                                                class="heroicon-ui">
                                                        </path>
                                                        </svg>
                                                        <span class="reactions-number">{{ postData.Reactions.Likes }}</span>
                                                        <svg (click)="openScoreModal(postData); $event.stopPropagation();" 
                                                             xmlns="http://www.w3.org/2000/svg" 
                                                             viewBox="0 0 24 24" 
                                                             width="24" 
                                                             height="24">
                                                            <path [style.fill]="postData.Reactions.replySelected ? '#d70000' : '#878787'" d="M2 15V5c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v15a1 1 0 0 1-1.7.7L16.58 17H4a2 2 0 0 1 -2-2z M20 5H4v10h13a1 1 0 0 1 .7.3l2.3 2.29V5z Z"/>
                                                        </svg>
                                                </div>-->
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="postData.PostType === 2"><!-- share audio post -->
                                        <!-- POST Type 2: Repost -->
                                        <div class="row mt-1 border d-flex  ">
    <!--                                        <span class="fw-bold repost-link" 
                                                    (mouseover)="loadUserProfile_home(postData.UserName, i, $event)" 
                                                    (mouseleave)="hideUserProfile_home(i)">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" class="mr-1"><path class="heroicon-ui" d="M5.41 16H18a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-4 4H5.41l2.3 2.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.4L5.4 16zM6 8a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4h12.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4L18.6 8H6z"/></svg>
                                                <a [routerLink]="'/' + postData.UserName" class="reposted-profile-link">{{postData.ProfileName}} reposted</a>
                                                <div *ngIf="userProfileStates.get(i)?.visible" [ngStyle]="hoverBoxStyle" class="user-hover-box">
                                                    <img [src]="postData.ImageDirectory + '/' + userProfileStates.get(i).profile.UserImg" alt="User Image">
                                                    <h3>{{ userProfileStates.get(i).profile.ProfileName }}</h3>
                                                    <p>@{{ userProfileStates.get(i).profile.UserName }}</p>
                                                    <button (click)="followUser(userProfileStates.get(i).profile.UserName)">Follow</button>
                                                    <p>{{ userProfileStates.get(i).profile.ProfileBio }}</p>
                                                    <div>
                                                    <span>Following: {{ userProfileStates.get(i).profile.FollowingCount }}</span> |
                                                    <span>Followers: {{ userProfileStates.get(i).profile.FollowersCount }}</span>
                                                    </div>
                                                </div>
                                            </span>-->
                                              <app-profile-menu-repost
                                                [ProfileName]="postData.ProfileName"
                                                [Username]="postData.UserName"
                                                [PublishDate]="postData.PublishDate"
                                                [ImageDirectory]="postData.ImageDirectory">
                                              </app-profile-menu-repost>
                                            <br>
                                            <div class="col-1 mt-2">
                                                <div class="user-avatar-container"> 
    <!--                                                <img class="circular-image" [src]="'./img_profile/' + postData.UserID + '/' + postData.UserImg"  alt="" height="50px">-->
                                                    <img class="circular-image" [src]="postData.ImageDirectory + '/' + postData.OrigUserImg"  alt="" height="50px">
                                                </div>
                                            </div>
                                            <div class="col-11 ">
                                                <div class="row d-flex justify-content-between align-items-center ps-1">
                                                    <div class="col-12 pt-2 ">
                                                        <div>
    <!--                                                    <div class="content-wrapper" style="display: inline-block;">
                                                            <span class="fw-bold username-link" 
                                                                    (mouseover)="loadUserProfile_home(postData.OrigUserName, i, $event)" 
                                                                    (mouseleave)="hideUserProfile_home(i)">
                                                                <a [routerLink]="'/' + postData.OrigUserName" class="profile-link">{{postData.OrigProfileName}} </a>
                                                                <a [routerLink]="'/' + postData.OrigUserName" class="profile-username-link">@{{postData.OrigUserName}} &#x2022; </a>
                                                                <span class="profile-link publish-date" 
                                                                    (mouseover)="loadUserProfile_home(postData.OrigUserName, i, $event)" 
                                                                    (mouseleave)="hideUserProfile_home(i)">
                                                                    {{postData.PublishDate | relativeTime}}
                                                                    <div *ngIf="showTooltip" class="tooltip">
                                                                        {{postData.PublishDate | date:'mediumDate'}} at {{postData.PublishDate | date:'shortTime'}}
                                                                    </div>
                                                                </span>
                                                                <div *ngIf="showProfileBox && userProfileStates.get(i)?.profile" [ngStyle]="hoverBoxStyle" class="user-hover-box">

                                                                    <img [src]="postData.ImageDirectory + '/' + userProfileStates.get(i)?.profile?.UserImg" alt="User Image">
                                                                    <div class="profile-link">{{ userProfileStates.get(i)?.profile?.ProfileName }}</div>
                                                                    <div class="profile-username-link">@{{ userProfileStates.get(i)?.profile?.UserName }}</div>
                                                                    <button (click)="followUser(userProfileStates.get(i)?.profile?.UserName); $event.stopPropagation()">Follow</button>
                                                                    <p>{{ userProfileStates.get(i)?.profile?.ProfileBio }}</p>
                                                                    <div>
                                                                    <span>Following: {{ userProfileStates.get(i)?.profile?.FollowingCount }}</span> |
                                                                    <span>Followers: {{ userProfileStates.get(i)?.profile?.FollowersCount }}</span>
                                                                    </div>
                                                                </div>
                                                            </span>       
                                                        </div>-->
                                                          <app-profile-menu
                                                            [ProfileName]="postData.OrigProfileName"
                                                            [Username]="postData.OrigUserName"
                                                            [PublishDate]="postData.PublishDate"
                                                            [ImageDirectory]="postData.ImageDirectory">
                                                          </app-profile-menu>

                                                        </div>
												            <a href="post-detail.html" class="post-title">{{postData.PostTitle}}</a>
                                                            <p [innerHTML]="postData.PostContent | parseHashtags"></p>

                                                            <div *ngIf="postData.FileName">
                                                              <!-- Check if the MIME type is a video format -->
                                                              <div class="video-container" *ngIf="postData.VideoMimeType?.startsWith('video/')">
                                                                <video controls class="responsive-video">
                                                                    <source [src]="'api/content/media?fileName=' + postData.FileName + '&mimeType=' + postData.VideoMimeType + '&userID=' + postData.OrigUserID" [type]="postData.VideoMimeType">
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                              </div>
                                                            <!-- Check if the MIME type is an image format -->
                                                            <div class="image-container" *ngIf="postData.VideoMimeType?.startsWith('image/')">
                                                                <div class="responsive-image-wrapper">
                                                                <img class="responsive-image" [src]="'api/content/media?fileName=' + postData.FileName + '&mimeType=' + postData.VideoMimeType + '&userID=' + postData.OrigUserID" [alt]="postData.FileName" >
                                                                </div>
                                                            </div>                                                    
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-12 reactions">
                                                    <app-reactions [postData]="postData" [UserID]="UserID" (reactionsUpdated)="onReactionsUpdated(postData.TSID, $event)"></app-reactions>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="postData.PostType === 3"><!-- share video post -->
                                    </ng-container>
                                    <ng-container *ngIf="postData.PostType === 4"><!-- share image with post -->
                                    </ng-container>
                                    <ng-container *ngIf="postData.PostType === 5"><!-- share image album -->

                                        <!-- POST 5 -->
                                        <div class="row mt-1 border d-flex  ">

                                            <div class="col-1 mt-2">
                                                <div class="user-avatar-container"> 
                                                    <img class="circular-image" [src]="postData.ImageDirectory + '/' + postData.UserImg"  alt="" height="50px">
                                                </div>
                                            </div>
                                            <div class="col-11 ">
                                                <div class="row d-flex justify-content-between align-items-center ps-1">
                                                    <div class="col-12 pt-2 ">
                                                        <div>
                                                          <app-profile-menu
                                                            [ProfileName]="postData.ProfileName"
                                                            [Username]="postData.UserName"
                                                            [PublishDate]="postData.PublishDate"
                                                            [ImageDirectory]="postData.ImageDirectory">
                                                          </app-profile-menu>                                                    

                                                        </div>
												            <a href="post-detail.html" class="post-title">{{postData.PostTitle}}</a>
                                                            <p [innerHTML]="postData.PostContent | parseHashtags"></p>
                                                            <div *ngIf="postData.FileName">
                                                              <!-- Check if the MIME type is a video format -->
                                                              <div class="video-container" *ngIf="postData.VideoMimeType?.startsWith('video/')">
                                                                <video controls class="responsive-video">
                                                                    <source [src]="'api/content/media?fileName=' + postData.FileName + '&mimeType=' + postData.VideoMimeType + '&userID=' + postData.UserID" [type]="postData.VideoMimeType">
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                              </div>
                                                            <!-- Check if the MIME type is an image format -->
                                                            <div class="image-container" *ngIf="postData.VideoMimeType?.startsWith('image/')">
                                                                <div class="responsive-image-wrapper">
                                                                <img class="responsive-image" [src]="'api/content/media?fileName=' + postData.FileName + '&mimeType=' + postData.VideoMimeType + '&userID=' + postData.UserID" [alt]="postData.FileName" >
                                                                </div>
                                                            </div>                                                    
                                                        </div>
                                                        <div class="original-post rounded-cust border" (click)="navigateToPost(postData.UserName, postData.QuotedPosts.QuotedOriginalPostID)" >
                                                                <ng-container *ngIf="postData.QuotedPosts">
                                                                  <div class="quoted-post">
                                                                    <div class="user-avatar-container">
                                                                        <img class="circular-image" [src]="'./img_profile/' + postData.QuotedPosts.QuotedUserID + '/' + postData.QuotedPosts.QuotedUserImg" alt="" height="50px">
                                                                    </div>
                                                                    <app-profile-menu
                                                                    [ProfileName]="postData.QuotedPosts.QuotedProfileName"
                                                                    [Username]="postData.QuotedPosts.QuotedUserName"
                                                                    [PublishDate]="postData.QuotedPosts.QuotedPublishDate"
                                                                    [ImageDirectory]="postData.QuotedPosts.QuotedUserImg">
                                                                    </app-profile-menu>    
                                                                    <p *ngIf="postData.QuotedPosts?.QuotedPostContent" [innerHTML]="postData.QuotedPosts.QuotedPostContent | parseHashtags "></p>
                                                                    <!-- Conditional rendering for media types -->
                                                                    <ng-container *ngIf="postData.QuotedPosts.QuotedVideoMimeType">
                                                                      <div *ngIf="postData.QuotedPosts.QuotedVideoMimeType.startsWith('video/')">
                                                                        <video controls class="responsive-video rounded" style="max-width: 100%;">
                                                                          <source [src]="postData.QuotedPosts.QuotedVideoURL" [type]="postData.QuotedPosts.QuotedVideoMimeType">
                                                                          Your browser does not support the video tag.
                                                                        </video>
                                                                      </div>
                                                                      <div *ngIf="postData.QuotedPosts.QuotedVideoMimeType.startsWith('image/')">
                                                                        <img [src]="postData.QuotedPosts.QuotedVideoURL" alt="Post image" class="rounded" style="max-width: 100%;">
                                                                      </div>
                                                                    </ng-container>
                                                                  </div>
                                                                </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 reactions">
                                                    <app-reactions [postData]="postData" [UserID]="UserID" (reactionsUpdated)="onReactionsUpdated(postData.TSID, $event)"></app-reactions>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="postData.PostType === 6"><!-- gif image post -->
                                    </ng-container>
                                    <ng-container *ngIf="postData.PostType === 7"><!-- share link -->
                                    </ng-container>
                                    <ng-container *ngIf="postData.PostType === 8"><!-- share video -->
                                    </ng-container>
                                    <ng-container *ngIf="postData.PostType === 9"><!-- video playlist carousel -->
                                    </ng-container>
                                    <ng-container *ngIf="postData.PostType === 10"><!-- post sell book -->
                                    </ng-container>
                                    <ng-container *ngIf="postData.PostType === 11"><!-- advertisment carousel -->
                                    </ng-container>
                                    <ng-container *ngIf="postData.PostType === 20"> <!-- Twitter/Facebook Embedded Admin Post -->

                                        <!-- POST 20 -->
                                        <div class="row mt-1 border d-flex  ">
                                            <div class="col-1 mt-2">
                                                <div class="user-avatar-container"> 
                                                    <img class="circular-image" [src]="postData.ImageDirectory + '/' + postData.UserImg"  alt="" height="50px">
                                                </div>
                                            </div>
                                            <div class="col-11 ">
                                                <div class="row d-flex justify-content-between align-items-center ps-1">
                                                    <div class="col-12 pt-2 ">
                                                        <div>
                                                          <app-profile-menu
                                                            [ProfileName]="postData.ProfileName"
                                                            [Username]="postData.UserName"
                                                            [PublishDate]="postData.PublishDate"
                                                            [ImageDirectory]="postData.ImageDirectory">
                                                          </app-profile-menu>                                                    

                                                        </div >
												            <a href="post-detail.html" class="post-title">{{postData.PostTitle}}</a>
                                                              <ng-container *ngIf="isTwitterPost(postData.PostContent)">
    <!--                                                            <div [innerHTML]="sanitizeHtml(postData.PostContent) | parseHashtags" class="twitter-post"></div>-->
                                                                <div [innerHTML]="postData.PostContent | parseHashtags" class="twitter-post"></div>
                                                              </ng-container>
                                                              <ng-container *ngIf="isFacebookPostType(postData.PostContent)">
                                                                  <ng-container *ngIf="isFacebookEmbeddedPost(postData.PostContent); else linkPosts">
                                                                      <div [innerHTML]="postData.PostContent | parseHashtags"
                                                                           [ngClass]="{'fb-post-embedded': isFacebookPostType(postData.PostContent), 'twitter-post': isTwitterPost(postData.PostContent)}">
                                                                      </div>
                                                                  </ng-container>  
                                                                <ng-template #linkPosts>
                                                                  <ng-container *ngIf="!isFacebookPost(postData.PostContent)">
                                                                    <div class="fb-video" 
                                                                         [attr.data-href]="postData.PostContent" 
                                                                         data-width="500" 
                                                                         data-show-text="false">
                                                                      <div class="fb-xfbml-parse-ignore">
                                                                        <blockquote [attr.cite]="postData.PostContent">
                                                                          <a [attr.href]="postData.PostContent"></a>
                                                                        </blockquote>
                                                                      </div>
                                                                    </div>
                                                                  </ng-container>
                                                                  <ng-container *ngIf="isFacebookPost(postData.PostContent)">
                                                                    <div class="fb-post" 
                                                                         [attr.data-href]="postData.PostContent" 
                                                                         data-width="500" >
                                                                    </div>
                                                                  </ng-container>
                                                                </ng-template>

                                                              </ng-container>
    <!--                                                        <p [innerHTML]="postData.PostContent | parseHashtags" [ngClass]="{'fb-post': isFacebookPost(postData.PostContent), 'twitter-post': isTwitterPost(postData.PostContent)}"></p>-->
        <!--												        <p >
                                                                {{postData.PostContent | parseHashtags}}													        
												            </p>-->
                                                            <div *ngIf="postData.FileName">
                                                              <!-- Check if the MIME type is a video format -->
                                                              <div class="video-container" *ngIf="postData.VideoMimeType?.startsWith('video/')">
                                                                <video controls class="responsive-video">
                                                                    <source [src]="'api/content/media?fileName=' + postData.FileName + '&mimeType=' + postData.VideoMimeType + '&userID=' + postData.OrigUserID" [type]="postData.VideoMimeType">
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                              </div>
                                                            <!-- Check if the MIME type is an image format -->
                                                            <div class="image-container" *ngIf="postData.VideoMimeType?.startsWith('image/')">
                                                                <div class="responsive-image-wrapper">
                                                                <img class="responsive-image" [src]="'api/content/media?fileName=' + postData.FileName + '&mimeType=' + postData.VideoMimeType + '&userID=' + postData.OrigUserID" [alt]="postData.FileName" >
                                                                </div>
                                                            </div>                                                    
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-12 reactions">
                                                    <app-reactions [postData]="postData" [UserID]="UserID" (reactionsUpdated)="onReactionsUpdated(postData.TSID, $event)"></app-reactions>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="postData.PostType === 21"> <!-- Scores Post -->

                                        <!-- POST 21 -->
                                        <div class="row mt-1 border d-flex  ">
                                            <div class="col-1 mt-2">
                                                <div class="user-avatar-container"> 
                                                    <img class="circular-image" [src]="postData.ImageDirectory + '/' + postData.UserImg"  alt="" height="50px">
                                                </div>
                                            </div>
                                            <div class="col-11 ">
                                                <div class="row d-flex justify-content-between align-items-center ps-1">
                                                    <div class="col-12 pt-2 ">
                                                        <div>
                                                          <app-profile-menu
                                                            [ProfileName]="postData.ProfileName"
                                                            [Username]="postData.UserName"
                                                            [PublishDate]="postData.PublishDate"
                                                            [ImageDirectory]="postData.ImageDirectory">
                                                          </app-profile-menu>                                                    

                                                        </div >
                                                          <!-- Scores and Badges -->
<div class="score-container d-flex flex-wrap">
  <div class="badge-row">
    <div *ngFor="let scoreDetails of getUniqueScoreTypes(postData.ScoreDetailsList)" 
        class="score-badge-container" 
         [class.selected]="selectedScoreTypeIDs[postData.TSID] === scoreDetails.ScoreTypeID" 
         (click)="selectScore(postData, scoreDetails.ScoreTypeID, $event)">
      <span class="badge-description">{{ scoreDetails.ScoreType }}</span>
      <svg width="80px" height="80px" viewBox="0 0 207.96 209.541" xmlns="http://www.w3.org/2000/svg">
        <defs/>
        <g transform="matrix(1, 0, 0, 1, -270.426979, 0)">
          <path d="M 273.183 63.609 C 273.183 35.994 295.569 13.609 323.183 13.609 L 348.183 13.609 L 348.183 63.609 L 273.183 63.609 Z" 
            [ngStyle]="{ fill: scoreDetails.ScoreTypeID === 1 ? scoreDetails.HexColor : '#ccc' }"/>
          <rect x="273.188" y="63.1" width="123.126" height="30.021" [ngStyle]="{ fill: scoreDetails.ScoreTypeID === 1 ? scoreDetails.HexColor : '#ccc' }" rx="0.62" ry="0.62"/>
          <rect x="339.124" y="13.608" width="57.163" height="53.959" [ngStyle]="{ fill: scoreDetails.ScoreTypeID === 1 ? scoreDetails.HexColor : '#ccc' }"/>
        </g>
        <g transform="matrix(1, 0, 0, 1, -270.426979, 0)">
          <path d="M 414.11 2.468 C 441.724 2.468 464.11 24.853 464.11 52.468 L 464.11 77.468 L 414.11 77.468 L 414.11 2.468 Z" 
            [ngStyle]="{ fill: scoreDetails.ScoreTypeID === 2 ? scoreDetails.HexColor : '#ccc' }"/>
          <rect x="397.546" y="2.468" width="17.613" height="140.986" [ngStyle]="{ fill: scoreDetails.ScoreTypeID === 2 ? scoreDetails.HexColor : '#ccc' }"/>
          <rect x="410.238" y="71.237" width="53.874" height="72.223" [ngStyle]="{ fill: scoreDetails.ScoreTypeID === 2 ? scoreDetails.HexColor : '#ccc' }"/>
        </g>
        <g transform="matrix(1, 0, 0, 1, -270.426979, 0)">
          <path d="M 474.981 144.706 C 474.981 172.32 452.595 194.706 424.981 194.706 L 399.981 194.706 L 399.981 144.706 L 474.981 144.706 Z" 
            [ngStyle]="{ fill: scoreDetails.ScoreTypeID === 3 ? scoreDetails.HexColor : '#ccc' }"/>
          <rect x="321.111" y="144.697" width="92.711" height="50.005" [ngStyle]="{ fill: scoreDetails.ScoreTypeID === 3 ? scoreDetails.HexColor : '#ccc' }"/>
        </g>
        <g transform="matrix(1, 0, 0, 1, -270.426979, 0)">
          <path d="M 320.151 207.375 C 304.37 201.889 285.924 187.732 285.924 160.118 L 285.924 135.118 L 319.465 135.118 L 320.151 207.375 Z" 
            [ngStyle]="{ fill: scoreDetails.ScoreTypeID === 4 ? scoreDetails.HexColor : '#ccc' }"/>
          <rect x="285.935" y="95.206" width="33.571" height="45.181" [ngStyle]="{ fill: scoreDetails.ScoreTypeID === 4 ? scoreDetails.HexColor : '#ccc' }"/>
        </g>
        <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="20px">{{ scoreDetails.Score }}</text>
      </svg>
    </div>
  </div>

  <div class="view-details-container" (click)="$event.stopPropagation()">
    <div (click)="toggleDetails(postData.TSID)" class="view-details">
      <i class="icon icon-details"></i> View Details
    </div>
    <div *ngIf="showDetails[postData.TSID]">
      <button (click)="toggleDetails(postData.TSID)">Minimize</button>
      <div class="reason-details">
        <div *ngFor="let reasonDetail of getReasonDetails(postData.TSID)">
          <ng-container *ngIf="isTwitterPost(reasonDetail.ReasonLink)">
              <p>{{reasonDetail.ReasonDetailText}}</p>
              <div [innerHTML]="reasonDetail.ReasonLink | parseHashtags" class="twitter-post"></div>
          </ng-container>
          <ng-container *ngIf="isFacebookPostType(reasonDetail.ReasonLink)">
            <p>{{reasonDetail.ReasonDetailText}}</p>
              <ng-container *ngIf="isFacebookEmbeddedPost(reasonDetail.ReasonLink); else linkPosts21">
                  <div [innerHTML]="reasonDetail.ReasonLink | parseHashtags"
                       [ngClass]="{'fb-post-embedded': isFacebookPostType(reasonDetail.ReasonLink), 'twitter-post': isTwitterPost(reasonDetail.ReasonLink)}">
                  </div>
              </ng-container>  
            <ng-template #linkPosts21>
              <ng-container *ngIf="!isFacebookPost(reasonDetail.ReasonLink)">
                <div class="fb-video" 
                     [attr.data-href]="reasonDetail.ReasonLink" 
                     data-width="500" 
                     data-show-text="false">
                  <div class="fb-xfbml-parse-ignore">
                    <blockquote [attr.cite]="reasonDetail.ReasonLink">
                      <a [attr.href]="reasonDetail.ReasonLink"></a>
                    </blockquote>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="isFacebookPost(reasonDetail.ReasonLink)">
                <div class="fb-post" 
                     [attr.data-href]="reasonDetail.ReasonLink" 
                     data-width="500">
                </div>
              </ng-container>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="!isTwitterPost(reasonDetail.ReasonLink) && !isFacebookPost(reasonDetail.ReasonLink)">
            <div class="url-preview">
              <a [href]="reasonDetail.ReasonLink" target="_blank">{{reasonDetail.ReasonLink}}</a>
              <p>{{reasonDetail.ReasonDetailText}}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>



												            <a href="post-detail.html" class="post-title">{{postData.PostTitle}}</a>

                                                            <div *ngIf="postData.FileName">
                                                              <!-- Check if the MIME type is a video format -->
                                                              <div class="video-container" *ngIf="postData.VideoMimeType?.startsWith('video/')">
                                                                <video controls class="responsive-video">
                                                                    <source [src]="'api/content/media?fileName=' + postData.FileName + '&mimeType=' + postData.VideoMimeType + '&userID=' + postData.OrigUserID" [type]="postData.VideoMimeType">
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                              </div>
                                                            <!-- Check if the MIME type is an image format -->
                                                            <div class="image-container" *ngIf="postData.VideoMimeType?.startsWith('image/')">
                                                                <div class="responsive-image-wrapper">
                                                                <img class="responsive-image" [src]="'api/content/media?fileName=' + postData.FileName + '&mimeType=' + postData.VideoMimeType + '&userID=' + postData.OrigUserID" [alt]="postData.FileName" >
                                                                </div>
                                                            </div>                                                    
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-12 reactions">
                                                    <app-reactions [postData]="postData" [UserID]="UserID" (reactionsUpdated)="onReactionsUpdated(postData.TSID, $event)"></app-reactions>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>




                                </div>
                                <div *ngIf="isLoading">
                                  Loading more posts...
                                </div>								
                            </div>
                        </div>
                </div>
            </div>
            <!-- RIGHT SIDE BAR -->
            <div class="col-lg-3 d-none d-lg-block  w-25 h-25 right_side_bar">
                <div class="">
                    <div class="bg-opacity-10 bg-black rounded-pill">
                        <div class="row d-flex align-items-center ">
                            <div class="col-2 text-end fs-6">
                                <span><i class="fa fa-magnifying-glass ps-4"></i></span>
                            </div>
                            <div class="col-10"><input type="text"
                                    class="form-control-lg border-0 me-1 w-75 bg-transparent"
                                    placeholder="Search Truth Shorts"></div>
                        </div>
                    </div>
                    <div class="row bg-opacity-10 bg-black mx-1 my-2 pb-3 rounded-3">
                        <div class="col">
                            <div class="trending">
                                <p class="fs-6 fw-bold py-2">Trends for you</p>
                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in USA</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">Donald Trump</p>
                                        <p class="">12.7k Posts</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Technology . Trending</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">CrowdStrike</p>
                                        <p class="">101k Posts</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in Politics</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">Joe Biden</p>
                                        <p class="">132k Posts</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in USA</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">Sky News</p>
                                        <p class="">8,104 Posts</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in USA</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">Azure</p>
                                        <p class="">141k Posts</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in USA</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">The IT</p>
                                        <p class="">2,449 Posts</p>
                                    </div>
                                </div>

                                <span class="text-info">Show more</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
 