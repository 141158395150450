// src/app/validation/validation.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';

import { RegistrationDataService } from '../../services/registration.data.service';
import { EmailService } from '../../services/email.service';

import { RegistrationComponent } from '../../modals/sign_up.modal/sign_up.modal.component';

import *  as Classes from '../../classes/models.classes'


@Component({
    selector: 'app-sign_up_validation-modal',
    templateUrl: './sign_up_validation.modal.component.html',
    styleUrls: ['./sign_up_validation.modal.component.css']
})
export class RegistrationValidationComponent implements OnInit {
    //@Input() registrationModalRef: RegistrationComponent;
    formValidation: FormGroup;
    hasPhone: boolean = false;
    hasEmail: boolean = false;
    codeSentMessage: string = '';
    generatedCode: string = '';
    sendAttempts: number = 0;
    isBlocked: boolean = false;
    blockedUntil: Date = null;

    isCodeValid: boolean = null; // null, true, or false based on validation

    verificationDataContent: Classes.Verification;
    registrationData: any;  // Here's your shared registration data object


    constructor(
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private registrationDataService: RegistrationDataService,
        private emailService: EmailService,
        private http: HttpClient
    ) {
        this.formValidation = this.formBuilder.group({
            validationMethod: ['', Validators.required],  // Ensure this control is initialized
            Code: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]]
        });

        this.registrationData = this.registrationDataService.getRegistrationData();
        this.formValidation.get('Code').valueChanges.subscribe(value => {
            if (value.length === 6) {
                this.validateCode(value, this.registrationData.Email);
            }
        });
    }

    ngOnInit(): void {
        //this.formValidation = this.formBuilder.group({
        //    code: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
        //    validationMethod: ['phone']
        //});

        // Pre-select the validation method
        //const registrationData = this.registrationDataService.getRegistrationData();

        this.verificationDataContent = new Classes.Verification();

        this.hasPhone = !!this.registrationData.Phone;
        this.hasEmail = !!this.registrationData.Email;
        const defaultMethod = this.hasPhone ? 'phone' : 'email';

        this.formValidation.patchValue({ validationMethod: defaultMethod });

        // Disable phone option if the user doesn't have a phone number
        if (!this.hasPhone) {
            this.formValidation.get('validationMethod').disable();
        }
    }

    generateRandomCode(): string {
        return Math.floor(100000 + Math.random() * 900000).toString();
    }

    validateCode(code: string, email: string): void {
        const data: Classes.CodeVerificationRequest = {
            Email: email,
            Code: code
        };

        this.registrationDataService.verifyCode(data).subscribe(
            response => {
                this.isCodeValid = response.isValid;
                if (!response.isValid) {
                    this.formValidation.get('Code').setErrors({ invalidCode: true });
                } else {
                    this.formValidation.get('Code').setErrors(null);  // Clear any previous errors if the code is valid
                }
            }, error => {
                console.error('Verification failed: ', error);
                this.isCodeValid = false;
                this.formValidation.get('Code').setErrors({ invalidCode: true });
            }
        );
    }

    sendValidationCode(): void {
        // Check if user is blocked
        if (this.isBlocked && new Date() < this.blockedUntil) {
            this.codeSentMessage = `You are blocked until ${this.blockedUntil.toLocaleTimeString()}`;
            return;
        }

        //this.generatedCode = this.generateRandomCode();
        const Method = this.formValidation.get('validationMethod').value;
        const registrationData = this.registrationDataService.getRegistrationData();

        this.verificationDataContent.Name = registrationData.Name;
        this.verificationDataContent.ValidationType = Method;
        this.verificationDataContent.Email = registrationData.Email;
        this.verificationDataContent.Phone = registrationData.Phone;

        this.registrationDataService.sendVerificationCode(this.verificationDataContent).subscribe(
            (response) => {
                this.formValidation.get('code').setValue('');
                console.log('Email sent successfully', response);
            },
            (error) => {
                console.error('Error sending email', error);
            }
        );

        //if (Method === 'phone' && registrationData.phone) {
        //    this.sendSMSCode(registrationData.phone, this.generatedCode);
        //} else if (Method === 'email' && registrationData.email) {
        //    this.sendEmailCode(registrationData.email, this.generatedCode);
        //}

        // Increment the send attempts
        this.sendAttempts++;
        if (this.sendAttempts >= 6) {
            this.isBlocked = true;
            this.blockedUntil = new Date(new Date().getTime() + 3 * 60 * 60 * 1000); // Block for 3 hours
            this.codeSentMessage = `You are blocked until ${this.blockedUntil.toLocaleTimeString()}`;
        } else {
            this.codeSentMessage = 'Code sent successfully.';
        }
    }

    sendSMSCode(phoneNumber: string, code: string): void {
        const url = `https://www.voxlinq.com/api/svc1.aspx?apiuid=4CWB0P6QHYGf12NuErRiDw==&apikey=dQmUITtwKjI=&sidvar=148&didvar=7863503562&dnidvar=${phoneNumber}&msgvar=Your%20code%20is:%20${code}&miscvar=`;

        this.http.get(url).subscribe(
            (response) => {
                console.log('SMS Response:', response);
            },
            (error) => {
                console.error('SMS Error:', error);
            }
        );
    }

    sendEmailCode(email: string, code: string): void {
        const emailBody  = `
      <p>Dear user,</p>
      <p>Your verification code is: <b>${code}</b></p>
      <p>Please enter this code on the website to verify your account.</p>
    `;

        //const emailData = {
        //    to: email,
        //    subject: 'Your Verification Code',
        //    htmlContent: emailBody 
        //};

        this.emailService.sendVerificationCode(email, name, emailBody).subscribe(
            (response) => {
                console.log('Email sent successfully', response);
            },
            (error) => {
                console.error('Error sending email', error);
            }
        );
    }

    submitRegistration(): void {
        //if (this.formValidation.get('code').value !== this.generatedCode) {
        //    this.codeSentMessage = 'Invalid code. Please try again.';
        //    return;
        //}

        const registrationData = this.registrationDataService.getRegistrationData();
        const validationData = this.formValidation.value;

        // Convert birthday parts into a Date object
        const birthdayDate = this.createBirthdayDate(registrationData.Birthday);

        //const finalData = {
        //    ...registrationData,
        //    Birthday: birthdayDate.toISOString(), // Convert Date object to an ISO string
        //    ValidationCode: validationData.Code
        //};

        const finalData: Classes.RegistrationData = {
            UserInfo: {
                Name: registrationData.Name,
                ProfileBio: '',  // Assuming you might set this elsewhere or it's optional
                Birthday: birthdayDate.toISOString(),
                Gender: '', // Add other fields similarly
                RegistrationDate: new Date().toISOString(),
                IPAddress: '',  // Determine how you get this
                TrustLevel: 0,  // Default or computed value
                UserImg: '',  // Default or selected value
                EntityType: 0  // Default or computed value
            },
            LoginInfo: {
                Username: registrationData.Email, // Assuming username is email
                Password: registrationData.Password,
                Email: registrationData.Email,
                Phone: registrationData.Phone,
                VerificationLevel: 0, // Default or computed value
                ValidationCode: registrationData.Code
            }
        };


        console.log('Final Registration Data:', finalData);
        // Implement your API call here to submit all data
        this.registrationDataService.registerUser(finalData).subscribe(
            (response) => {
                // Handle success
                console.log('Registration successful:', response);
            },
            (error) => {
                // Handle error
                console.error('Error during registration:', error);
            }
        );


    }

    createBirthdayDate(birthday: any): Date {
        // JavaScript expects the month index to start from 0
        return new Date(birthday.SelectedYear, birthday.SelectedMonth - 1, birthday.SelectedDay);
    }

    goBackToRegistration(): void {
        this.activeModal.close();
        //this.modalService.open(this.registrationModalRef);
        this.modalService.open(RegistrationComponent, { size: 'lg' });
    }
}
