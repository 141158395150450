'use strict';

import * as Interfaces from '../interfaces/models.interfaces';

export class VoterFraudIncident implements Interfaces.VoterFraudIncident {
    IncidentID: number;
    StateID: number;
    CategoryID: number;
    AllegationSummary: string;
    AllegationDetail: string;
    AllegationType: string;
    AffidavitDesc: string;
    IncidentWeightDesc: string;
    PotentialImpactDesc: string;
    VeracityScoreDesc: string;
    NumberOfVotesAffected: string;
    IncidentSummary: string;
    IncidentComments: string;
    LastUpdate: Date;
}

export class Params {
  stateID: string;
  categoryID: string;
}


export class UserID_Params {
    UserID: number;
}

export class ShortsPosts implements Interfaces.ShortsPosts {
    TSID: number;
    UserID: number;
    PostType: number;
    OriginalPostID: number;
    PublishDate: Date;
    PostTitle: string;
    PostContent: string;
    FileName: string;
    VideoURL: string;
    VideoMimeType: string;
    UserName: string;
    ProfileName: string;
    UserImg: string;
    OrigUserID: number;
    OrigUserName: string;
    OrigUserImg: string;
    OrigProfileName: string;
    OrigPublishDate: Date;
    MediaDirectory: string;
    ImageDirectory: string;
    Reactions: Interfaces.Reactions;
    QuotedPosts: Interfaces.QuotedPost;
    ScoreDetailsList: Interfaces.ScoreDetails[]; // New property

    constructor() {
        //this.Reactions = {} as Interfaces.Reactions;
        //this.QuotedPosts = {} as Interfaces.QuotedPost;
        this.ScoreDetailsList = [];
    }
}

export class ScoreDetails implements Interfaces.ScoreDetails {
    ScoreTypeID: number;
    ScoreType: string;
    Score: number;
    HexColor: string;
    ReasonDetails: Interfaces.ReasonDetail[]; // List of reason details

    constructor() {
        this.ReasonDetails = [];
    }
}

export class ReasonDetail implements Interfaces.ReasonDetail {
    ReasonDetailText: string;
    ReasonLink: string;
}

export class ShortsPostSave implements Interfaces.ShortsPostSave {
    UserID: number;
    PostType: number;
    OriginalPostID: number;
    PublishDate: Date;
    PostContent: string;
    FileName: string;
    VideoMimeType: string;
}

export class Verification implements Interfaces.Verification {
    Name: string;
    ValidationType: string;
    Email: string;
    Phone: string;
    MessageBody: string;
}

export class CodeVerificationRequest implements Interfaces.CodeVerificationRequest {
    Email: string;
    Code: string;
}

// Define interfaces to reflect the C# data models
export class RegistrationData implements Interfaces.RegistrationData {
    LoginInfo: LoginModel;
    UserInfo: UserModel;
}

export class LoginModel implements Interfaces.LoginModel {
    Username: string;
    Password: string;
    PasswordHash?: string;  // Include this if it's sent from the client, otherwise, remove it
    Email: string;
    Phone: string;
    VerificationLevel: number;
    ValidationCode?: string;  // Optional, depending on your implementation
}

export class UserModel implements Interfaces.UserModel {
    UserID?: number;  // Optional, usually not set by client but by database on insert
    Name: string;
    ProfileBio?: string;  // Optional
    Birthday: string;
    Gender: string;
    RegistrationDate?: string;  // Optional, usually set by the server
    IPAddress?: string;  // Optional
    TrustLevel?: number;
    UserImg?: string;
    EntityType?: number;
}

export class ProfileModel implements Interfaces.ProfileModel {
    UserName: string;
    ProfileName: string;
    UserImg: string;
    ProfileBio: string;
    ImageDirectory: string;
    FollowingCount: number;
    FollowersCount: number;
    FreedomScore: number | null;
    FreedomConfidenceScore: number | null;
    FreedomScoreColor: string;
    TruthScore: number | null;
    TruthConfidenceScore: number | null;
    TruthScoreColor: string;
    PoliticalScore: number | null;
    PoliticalConfidenceScore: number | null;
    PoliticalScoreColor: string;
    TransparencyScore: number | null;
    TransparencyConfidenceScore: number | null;
    TransparencyScoreColor: string;
}


export class AuthResponse implements Interfaces.AuthResponse {
    authenticated: boolean;
}
