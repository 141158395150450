<div class="modal-content rounded">
  <div class="modal-header">
    <img src="images/mainlogo_lg.png" alt="Logo" class="modal-logo">
    <h2 class="flex-grow-1 text-center">Sign Up</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal($event); false;"></button>
  </div>  
    <form [formGroup]="formRegistration" (ngSubmit)="false;" novalidate>
        <div class="form-group mb-3">
            <label for="Name">Name:</label>
            <input type="text" formControlName="Name" name="Name" class="form-control" maxlength="50" (input)="updateCharacterCount(formRegistration.get('Name').value)">
            <small class="character-count">{{ characterCount }} / 50</small>
                <div *ngIf="formRegistration.get('name')?.touched && formRegistration.get('name').errors" >
                    <small *ngIf="formRegistration.get('Name').errors.required" class="text-danger">
                    Name is required.
                    </small>
                    <small *ngIf="formRegistration.get('Name').errors.minlength" class="text-danger">
                    Name must be at least 4 characters.
                    </small>
                    <small *ngIf="formRegistration.get('Name').errors.maxlength" class="text-danger">
                    Name cannot exceed 50 characters.
                    </small>
                    <small *ngIf="formRegistration.get('Name').errors.invalidName" class="text-danger">
                    Name must only contain letters and spaces.
                    </small>
                </div>
        </div>
        <!-- Repeat for other fields -->

        <div class="form-group mb-3">
            <label for="Email">Email:</label>
            <input type="text" formControlName="Email" class="form-control" name="Email">
<!--            <a href="#" class="d-block mt-1" (click)="togglePhoneEmail()">Use email instead/Use Phone Instead</a>  class="alert alert-danger"   -->
                <div *ngIf="formRegistration.get('Email').touched && formRegistration.get('Email').errors">
                    <small *ngIf="formRegistration.get('Email').errors.required" class="text-danger">
                    Email is required.
                    </small>
                    <small *ngIf="formRegistration.get('Email').errors.invalidEmail" class="text-danger">
                    Please enter a valid email address.
                    </small>
                </div>
        </div>

        <div class="form-group mb-3">
            <label for="Phone">Phone:</label>
            <div class="info-container ms-2 align-self-end">
                <div class="info-icon" (click)="showInfoPhone()">?</div>
                <div class="info-text" *ngIf="showInfoPhoneText">
                    By providing an SMS-enabled phone number, you will achieve a higher trust score. This enhances the visibility and prominence of your posts and comments to the broader community.
                </div>
            </div>
            <input type="text" formControlName="Phone" class="form-control" name="Phone">
<!--            <a href="#" class="d-block mt-1" (click)="togglePhoneEmail()">Use email instead/Use Phone Instead</a>  class="alert alert-danger"   -->
                <div *ngIf="formRegistration.get('Phone').touched && formRegistration.get('Phone').errors">
                    <small *ngIf="formRegistration.get('Phone').errors.invalidPhone" class="text-danger">
                    Please enter a valid US phone number.
                    </small>
                </div>
        </div>        
        <div class="form-group mb-3">
            <label for="Birthday">Birthday:</label>
            <div class="info-container ms-2 align-self-end">
                <div class="info-icon" (click)="showInfo()">?</div>
                <div class="info-text" *ngIf="showInfoText">
                    Providing your birthday helps tailor the content for your age.
                    This information will not be visible to the public.
                </div>
            </div>
            <div class="d-flex" formGroupName="Birthday">  <!-- This line is critical -->
                <div class="form-group me-2 flex-grow-1">
                    <label for="SelectedMonth">Month</label>
                    <select class="form-control" formControlName="SelectedMonth" name="SelectedMonth" (change)="updateDays()">
                        <option *ngFor="let month of months" [value]="month.value">{{ month.name }}</option>
                    </select>
                    <small *ngIf="formRegistration.get('Birthday.SelectedMonth').touched && formRegistration.get('Birthday.SelectedMonth').errors?.required" class="text-danger">
                        Month is required.
                    </small>
                </div>
                <div class="form-group mx-2 flex-grow-1">
                    <label for="SelectedDay">Day</label>
                    <select class="form-control" formControlName="SelectedDay" name="selectedDay">
                        <option *ngFor="let day of days" [value]="day">{{ day }}</option>
                    </select>
                    <small *ngIf="formRegistration.get('Birthday.SelectedDay').touched && formRegistration.get('Birthday.SelectedDay').errors?.required" class="text-danger">
                        Day is required.
                    </small>
                </div>
                <div class="form-group ms-2 flex-grow-1">
                    <label for="SelectedYear">Year</label>
                    <select class="form-control" formControlName="SelectedYear" name="SelectedYear" >
                        <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                    </select>
                    <small *ngIf="formRegistration.get('Birthday.SelectedYear').touched && formRegistration.get('Birthday.SelectedYear').errors?.required" class="text-danger">
                        Year is required.
                    </small>
                </div>
            </div>
        </div>       
        
        <div class="form-group mb-3">
            <label for="Password">Password:</label>
            <input type="password" class="form-control" formControlName="Password" name="Password">
            <div *ngIf="formRegistration.get('Password').touched && formRegistration.get('Password').errors">
                <small class="text-danger" *ngIf="formRegistration.get('Password').errors?.required">
                    Password is required.
                </small>
                <small class="text-danger" *ngIf="formRegistration.get('Password').errors.minlength">
                    Password must be at least 10 characters long.
                </small>
                <small class="text-danger" *ngIf="formRegistration.get('Password').errors.passwordStrength">
                    Password must contain a mix of upper and lower case letters, numbers, and symbols.
                </small>
            </div>



        </div>
        <div class="form-group mb-3">
            <label for="ConfirmPassword">Confirm Password:</label>
            <input type="password" class="form-control" formControlName="ConfirmPassword" name="ConfirmPassword">
                <div *ngIf="formRegistration.get('ConfirmPassword').touched">
                    <!-- Error for not filling the confirmPassword -->
                    <small *ngIf="formRegistration.get('ConfirmPassword').errors?.required" class="text-danger">
                        Confirm Password field is required.
                    </small>
                    <!-- Error for password mismatch -->
                    <small *ngIf="formRegistration.errors?.passwordMismatch && formRegistration.get('ConfirmPassword').dirty" class="text-danger">
                        Passwords do not match.
                    </small>
                </div>

        </div>
<!--        <div class="form-check mb-3">
            <input type="checkbox" class="form-check-input" formControlName="termsOfService" name="termsOfService">
            <label class="form-check-label" for="termsOfService">I have read and agree to the <a href="#" target="_blank">Terms of Service</a></label>
            <small *ngIf="formRegistration.get('termsOfService').touched && formRegistration.get('termsOfService').errors?.required" class="text-danger">
                <br> Terms of Service must be checked.
            </small>
        </div>-->
        <small class="form-statement mb-3">
            By continuing, you agree to TruthShort's <a href="#" target="_blank">Terms of Service</a> and confirm that you have read TruthShort's <a href="#" target="_blank">Privacy Policy</a>.
        </small>


    </form>
        <div class="flex-grow-1 text-center">
            <button type="submit" class="btn btn-primary" [disabled]="!formRegistration.valid" (click)="openValidationModal()">Next</button>
        </div>


</div>

