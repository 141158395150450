import { Component, Input, OnInit, OnChanges, ElementRef, ViewChild, ViewChildren, SimpleChange, SimpleChanges, OnDestroy, QueryList, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ["./privacy-policy.component.css"]
})
export class PrivacyPolicy implements OnInit, OnChanges {


    returnUrl: string;
    errorMessage: string; 

    email: string = '';
    password: string = '';

  constructor(
      private modalService: NgbModal,
      private route: ActivatedRoute,
    private router: Router
    //private formBuilder: FormBuilder
  ) {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
    ngOnChanges(changes: SimpleChanges): void {
        throw new Error("Method not implemented.");
    }
    //ngOnDestroy(): void {
    //    throw new Error("Method not implemented.");
    //}

    ngOnInit() {

    }

    ngAfterViewInit() {


    }

    downloadPDF() {
        const element = document.getElementById('privacyContent');
        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const doc = new jsPDF();
            const imgProps = doc.getImageProperties(imgData);
            const pdfWidth = doc.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            doc.save('privacy-policy.pdf');
        });
    }


}
