'use strict'

export class VoterFraudDataUrls {
  static Get_Incidents: string = "api_voter/VoterFraudIncident/Get_Incidents";
}

export class PostsDataUrls {
    
    static GetDashboardPosts: string = "api_posts/UserPosts/GetDashboardPosts";
    static GetUserPosts: string = "api_posts/UserPosts/GetUserPosts";
    static GetUserReplyPosts: string = "api_posts/UserPosts/GetUserReplyPosts";
    static GetUserPost: string = "api_posts/UserPosts/GetUserPost";
    static SaveUserPosts: string = "api_posts/UserPosts/SaveUserPosts";
    static SaveUserRePosts: string = "api_posts/UserPosts/SaveUserRePosts";
    static SaveQuotePost: string = "api_posts/UserPosts/SaveQuotePost";
    static GetReactions: string = "api_posts/UserPosts/reactions";
    static ToggleReaction: string = "api_posts/UserPosts/ToggleReaction";
}

export class UserUrls {

    static CheckLogin: string = "api/user/Login";
    static Logout: string = "api/user/Logout";
    static CheckToken: string = "api/user/CheckToken";
    static AddNewUser: string = "api/user/AddNewUser"; 
    static GetUserProfile: string = "api/user/GetUserProfile";
    static FollowUser: string = "api/user/FollowUser";
}

export class ScoresUrls {

    static Categories: string = "api_scores/Categories";
    static ScoreDescriptions: string = "api_scores/Score-descriptions";
    static EntitiesSearch: string = "api_scores/Entities/Search";
    static Scores: string = "api_scores/SaveScores";
}

export class ContentAnalysis {

    static AnalyzeContent: string = "api/content/AnalyzeContent";
    static FetchMetaTags: string = "api/content/FetchMetaTags";
}

export class SocialMedia {

    static FetchAndSaveTweets: string = "api/twitter/FetchAndSaveTweets";
}

export class RegistrationUrls {

    static RequestVerificationCode: string = "api/verify/RequestVerificationCode";
    static VerifyCodeUrl: string = "api/verify/VerifyCode";

}

export class NotificationUrls {

    static GetVerificationCode: string = "api/email/SendVerificationCode";

}