import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
    providedIn: 'root',
})
export class PdfmakeService {

    async generatePdfFromHtml(elementId: string, title: string = 'Document') {
        const element = document.getElementById(elementId);
        if (!element) {
            console.error('Element not found');
            return;
        }

        try {
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 210; // A4 size in mm
            const pageHeight = 297; // A4 size in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            const documentDefinition = {
                content: [],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                    },
                },
                pageMargins: [40, 60, 40, 60],
                pageSize: 'A4',
            };

            documentDefinition.content.push({ text: title, style: 'header' });

            // Adding pages
            while (heightLeft > 0) {
                documentDefinition.content.push({
                    image: imgData,
                    width: imgWidth,
                    height: imgHeight,
                });
                heightLeft -= pageHeight;
            }

            pdfMake.createPdf(documentDefinition as any).download(`${title}.pdf`);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    }
}
