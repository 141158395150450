import { Component, Input, OnInit, OnChanges, ElementRef, ViewChild, ViewChildren, SimpleChange, SimpleChanges, OnDestroy, QueryList, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { RegistrationComponent } from '../../modals/sign_up.modal/sign_up.modal.component';

//import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

//import {ActiveToast, IndividualToastrConfig, ToastrService } from 'ngx-toaster';

import { GlobalService } from '../../services/global.service';
import { UserService } from '../../services/user.service';
import { UserPostsDataService } from '../../services/posts.service';
import  *  as Classes from '../../classes/models.classes'

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}


@Component({
    selector: 'app-home-landing',
    templateUrl: './home_landing.component.html',
    styleUrls: ["./home_landing.component.css"]
})
//export class HomeLanding implements OnInit, OnDestroy, OnChanges {
export class HomeLanding implements OnInit, OnChanges {


    @Input() Mode: string;
    @Input() StateID: string;
    @Input() CategoryID: string; 

    FraudIncidentData: Array<Classes.VoterFraudIncident>;
    UserPostsData: Array<Classes.ShortsPosts>;

    FirstTabChecked: boolean;
    SecondTabChecked: boolean;
    ThirdTabChecked: boolean;
    FourthTabChecked: boolean;

    Category: string;
    State: string;
    UserID: number;

    returnUrl: string;
    errorMessage: string; 

    @ViewChild('ulContent') ulElementView: QueryList<ElementRef>;

    @ViewChildren('ulContent') ulChildrenComponent: QueryList<ElementRef>; 
    @ViewChildren('liContent') liChildrenComponent: QueryList<ElementRef>;

    @ViewChildren('incidentDiv') incidentDivComponent: QueryList<ElementRef>;
    @ViewChildren('incidentDivHome') incidentDivHomeComponent: QueryList<ElementRef>;
    @ViewChildren('incidentDivAbout') incidentDivAboutComponent: QueryList<ElementRef>;

    ulContentHeight: number;

    email: string = '';
    password: string = '';

  constructor(
      private userPostsDataService: UserPostsDataService,
      private globalService: GlobalService,
      private modalService: NgbModal,
      private route: ActivatedRoute,
      private authService: UserService,
      //private registrationComponent: RegistrationComponent,
    private router: Router
    //private formBuilder: FormBuilder
  ) {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
    ngOnChanges(changes: SimpleChanges): void {
        throw new Error("Method not implemented.");
    }
    //ngOnDestroy(): void {
    //    throw new Error("Method not implemented.");
    //}

    ngOnInit() {
    debugger;
        this.FraudIncidentData = new Array<Classes.VoterFraudIncident>();
        this.UserPostsData = new Array<Classes.ShortsPosts>();

        this.FirstTabChecked = false;
        this.SecondTabChecked = false;
        this.ThirdTabChecked = false;
        this.FourthTabChecked = false;

        this.UserID = 1;


    }

    ngAfterViewInit() {

        this.route.queryParams.subscribe(params => {
            this.StateID = params['StateID'];
            this.CategoryID = params['CategoryID'];
            if (this.StateID != undefined && this.StateID != null && this.StateID != ''
                && this.CategoryID != undefined && this.CategoryID != null && this.CategoryID != '') {
                //this.getFraudIncidentData();
            }
            else {
                this.StateID = '8';
                this.CategoryID = '1';
                //this.getFraudIncidentData();
            }
        });

        //console.log(this.route.snapshot.queryParams);

        //this.setCategoryAndStateName();

    }

    login() {
        this.authService.login(this.email, this.password).subscribe({
            next: () => {
                // Navigate on successful login
                this.router.navigateByUrl(this.returnUrl);
            },
            error: (err) => {
                // Set the error message to display in the template
                this.errorMessage = "The username or password entered is not correct.";
                console.error('Login failed', err);
            }
        });
    }

    /*
    login() {
        this.authService.login(this.email, this.password).subscribe(
            (success) => {

                if (success) {
                    this.router.navigateByUrl(this.returnUrl);
                }

                //This is now set in the service method. MB 5/24/24
                
                //// Upon successful login, receive the token from the response
                //const token = response.token;

                //// Register the token in local storage or session storage
                //localStorage.setItem(GlobalService.tokenName, token);

                // Redirect to the main dashboard
                //this.router.navigate(['/dashboard']);
                //this.router.navigate(['/dashboard'], {
                //    relativeTo: this.route,
                //    // do not trigger navigation
                //    skipLocationChange: true

                //});
            },
            (error) => {
                console.error('Login error:', error);
            }
        );
    }

*/

    openModal() {
        // Open modal for account creation
        let openRegistrationModal: NgbModalRef = this.modalService.open(RegistrationComponent, {
            keyboard: false,
            backdrop: 'static',
            size: 'lg'
        });

    }



    navigateTo(stateID: string, categoryID: string, event: Event) {
    this.StateID = stateID;
    this.CategoryID = categoryID;

    this.router.navigate([], {
        queryParams: {
        StateID: stateID,
        CategoryID: categoryID
        },
        queryParamsHandling: 'merge',
    });
        this.FraudIncidentData = new Array<Classes.VoterFraudIncident>();

        //this.checkTabStatus();
        console.log('this.StateID = ' + this.StateID + '; this.CategoryID = ' + this.CategoryID);


    }

    navigateToPage() {
    // changes the route without moving from the current view or
    // triggering a navigation event,
        this.router.navigate(['/dashboard_login'], {
        relativeTo: this.route,
        //queryParams: {
        //  stateIDParams: '1'
        //},
        // preserve the existing query params in the route
        //queryParamsHandling: 'merge',

        // do not trigger navigation
        skipLocationChange: true

    });
    }

}
