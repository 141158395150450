import { Injectable } from '@angular/core';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, ReplaySubject, forkJoin, throwError } from 'rxjs';

import { ScoresUrls } from '../global.urls';
import * as Classes from '../classes/models.classes';
import { ShortsPostSave } from '../interfaces/models.interfaces';
import { tap } from 'rxjs/operators';

//import { Http } from '@angular/http';

@Injectable({
    providedIn: 'any'
})
export class ScoreService  {
    lcl_params: Classes.UserID_Params;
    private postsSubject = new BehaviorSubject<Classes.ShortsPosts[]>([]); // Observable stream for posts
    private reactionsSubject = new BehaviorSubject<any>(null);  // For reactions

    freedomScore = 0;
    truthScore = 0;
    politicalLeaningScore = 0;
    objectivityScore = 0;

    freedomScoreDetails = [{ link: '', linkType: '', reason: '' }];
    truthScoreDetails = [{ link: '', linkType: '', reason: '' }];
    politicalLeaningScoreDetails = [{ link: '', linkType: '', reason: '' }];
    objectivityScoreDetails = [{ link: '', linkType: '', reason: '' }];

  constructor(
    private http: HttpClient) {

  }

    getCategories(): Observable<any[]> {
        return this.http.get<any[]>(ScoresUrls.Categories);
    }

    getScoreDescriptions(): Observable<any> {
        return this.http.get<any>(ScoresUrls.ScoreDescriptions);
    }

    getScoreDescription(type: string, value: number): Observable<string> {
        return this.http.get<string>(`${ScoresUrls.ScoreDescriptions}/${type}/${value}`);
    }

    searchEntities(query: string): Observable<any[]> {
        return this.http.get<any[]>(`${ScoresUrls.EntitiesSearch}/${query}`);
    }

    saveScores(scoreData: any): Observable<void> {
        return this.http.post<void>(ScoresUrls.Scores, scoreData);
    }
}
