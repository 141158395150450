// score-modal.component.ts
import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeDetectorRef } from '@angular/core';
import { Renderer2, ElementRef } from '@angular/core';

import { BehaviorSubject, Subscription, Observable, of  } from 'rxjs';

import { ToastrService } from 'ngx-toastr';

import { ScoreService } from '../../services/scores.service';
import { SessionService } from '../../services/session.service';
import { ContentAnalysisService } from '../../services/content-analysis.service';
import { switchMap, catchError } from 'rxjs/operators';

//import { Observable } from 'rxjs';


@Component({
    selector: 'app-score-modal',
    templateUrl: './score-modal.component.html',
    styleUrls: ['./score-modal.component.css']
})
export class ScoreModalComponent implements OnInit, AfterViewInit {
    @Input() postData: any; // Receive the post data from the parent

    scoreForm: FormGroup;
    categories: any[] = [];
    filteredEntities: any[] = [];
    scoreDescriptions: any = {};
    selectedTab = 0;
    tabs = ['Freedom Score', 'Truth Score', 'Political Leaning', 'Objectivity', 'Entity Categories', 'Alternate Entities'];
    selectedLinkType: any[] = [];

    freedomScore: number = null;
    freedomScoreDescription: string = '';
    truthScore: number = null;
    truthScoreDescription: string = '';
    politicalScore: number = null;
    politicalScoreDescription: string = '';
    objectivityScore: number = null;
    objectivityScoreDescription: string = '';

    maxGroups = 3;

    //freedomScoreDetails = [{ link: '', linkType: '', reason: '' }];
    //truthScoreDetails = [{ link: '', linkType: '', reason: '' }];
    //politicalScoreDetails = [{ link: '', linkType: '', reason: '' }];
    //objectivityScoreDetails = [{ link: '', linkType: '', reason: '' }];

    activeTab = 'freedom';
    previousTab: string = 'freedom'; // Track the previous active tab

    UserID: number;

    url: string;
    analysisResult: any;
    metaTags: any;
    linkType: string;

    isLoading = false;
    // Change isLoading to a BehaviorSubject
    private isLoadingSubject = new BehaviorSubject<boolean>(false);
    isLoading$ = this.isLoadingSubject.asObservable();

    private tabSubscriptions: { [key: string]: Subscription[] } = {
        freedom: [],
        truth: [],
        political: [],
        objectivity: []
    };

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private toastr: ToastrService,

        private cdr: ChangeDetectorRef,
        private renderer: Renderer2,
        private elementRef: ElementRef,

        private contentAnalysisService: ContentAnalysisService,
        private sessionService: SessionService,
        private scoreService: ScoreService
    ) { }

    ngOnInit(): void {
        this.scoreForm = this.fb.group({
            freedomScore: [null],
            truthScore: [null],
            politicalScore: [null],
            objectivityScore: [null],
            freedomScoreDetails: this.fb.array([this.createScoreDetail()]),
            truthScoreDetails: this.fb.array([this.createScoreDetail()]),
            politicalScoreDetails: this.fb.array([this.createScoreDetail()]),
            objectivityScoreDetails: this.fb.array([this.createScoreDetail()])
        });

        this.sessionService.getUserID().subscribe(userID => {
            this.UserID = Number(userID);
        });

        this.loadCategories();
        this.loadScoreDescriptions();
        this.updateScoreDescription('freedom', this.scoreForm.get('freedomScore').value);

        //this.subscribeToValueChanges();
    }

    ngAfterViewInit(): void {
        //this.updateSliderThumbValue();
        //this.updateSliderThumb(this.freedomScore);
        //this.subscribeToValueChanges(this.activeTab);

    }

    analyzeContent() {
        this.contentAnalysisService.analyzeUrl(this.url).subscribe(
            result => this.analysisResult = result,
            error => console.error('Error analyzing content', error)
        )
    }

    fetchMetaTags() {
        this.contentAnalysisService.fetchMetaTags(this.url).subscribe(
            result => {
                this.metaTags = result.metaTags;
                this.linkType = result.linkType;
            },
            error => console.error('Error fetching meta tags', error)
        );
    }

    get freedomScoreDetails(): FormArray {
        return this.scoreForm.get('freedomScoreDetails') as FormArray;
    }

    get truthScoreDetails(): FormArray {
        return this.scoreForm.get('truthScoreDetails') as FormArray;
    }

    get politicalScoreDetails(): FormArray {
        return this.scoreForm.get('politicalScoreDetails') as FormArray;
    }

    get objectivityScoreDetails(): FormArray {
        return this.scoreForm.get('objectivityScoreDetails') as FormArray;
    }

    createScoreDetail(): FormGroup {
        return this.fb.group({
            reasonLink: new FormControl('', [], [this.urlValidator.bind(this)]),
            reasonDetail: ['', [Validators.minLength(10), this.minWordsValidator(3)]]
        }, { validators: this.linkOrReasonValidator });
    }

    validateUrl(control: AbstractControl, tabName: string, index: number): void {
        const detail = control as FormGroup;
        const url = detail.get('reasonLink').value;
        const inputElement = this.elementRef.nativeElement.querySelector(`#${tabName}SupportingLink${index}`);

        if (!inputElement) {
            // Re-select the input element if it's not found
            this.cdr.detectChanges(); // Force Angular to detect changes
            setTimeout(() => this.validateUrl(control, tabName, index), 0); // Retry after changes are detected
            return;
        }
            if (url) {
            //this.cdr.detectChanges();
            //detail.get('reasonLink').setErrors(null);
            //const inputElement = this.elementRef.nativeElement.querySelector(`#${tabName}SupportingLink${index}`);
             this.showSpinner(inputElement);
            //this.isLoading = true;
            //this.isLoadingSubject.next(true); // Set isLoading to true
            //return;
            this.cdr.detectChanges(); // Manually trigger change detection

            this.contentAnalysisService.fetchMetaTags(url).subscribe(
                () => {
                    //this.isLoading = false;
                    //this.isLoadingSubject.next(false); // Set isLoading to false
                    this.hideSpinner(inputElement);
                    detail.get('reasonLink').setErrors(null);
                    detail.get('reasonLink').markAsTouched();
                    this.cdr.detectChanges(); 
                },
                () => {
                    //this.isLoading = false;
                    //this.isLoadingSubject.next(false); // Set isLoading to false
                    this.hideSpinner(inputElement);
                    detail.get('reasonLink').markAsTouched();
                    detail.get('reasonLink').setErrors({ url: true });
                    this.cdr.detectChanges(); 
                }
            );
        }
    }

    showSpinner(inputElement: HTMLElement) {
        const spinnerId = `${inputElement.id}-spinner`;
        const existingSpinner = document.getElementById(spinnerId);
        if (existingSpinner) {
            this.renderer.removeChild(inputElement.parentElement, existingSpinner);
        }

        const spinner = this.renderer.createElement('img');
        this.renderer.setAttribute(spinner, 'src', 'img/loading.gif');
        this.renderer.setAttribute(spinner, 'alt', 'Loading...');
        this.renderer.setAttribute(spinner, 'id', spinnerId);
        this.renderer.addClass(spinner, 'loading-spinner');
        this.renderer.setStyle(spinner, 'position', 'absolute');
        this.renderer.setStyle(spinner, 'width', '24px'); // Adjust size as needed
        this.renderer.setStyle(spinner, 'height', '25px'); // Adjust size as needed
        this.renderer.setStyle(spinner, 'margin-left', '35%');
        this.renderer.setStyle(spinner, 'top', '43.5%');
        //this.renderer.setStyle(spinner, 'transform', 'translateY(-50%)'); // Center vertically
        this.renderer.appendChild(inputElement.parentElement, spinner);
    }

    hideSpinner(inputElement: HTMLElement) {
        const spinnerId = `${inputElement.id}-spinner`;
        const spinner = document.getElementById(spinnerId);
        if (spinner) {
            this.renderer.removeChild(inputElement.parentElement, spinner);
        }
    }

    validateReasonDetail(control: AbstractControl): void {
        control.get('reasonDetail').markAsTouched();
        control.get('reasonDetail').updateValueAndValidity();
        this.cdr.detectChanges();  // Trigger change detection
    }

    minWordsValidator(minWords: number) {
        return (control: FormControl) => {
            if (!control.touched || !control.value) return null;
            const words = control.value.split(/\s+/);
            return words.length >= minWords ? null : { minWords: true };
        };
    }

    linkOrReasonValidator(group: FormGroup) {
        const reasonLink = group.get('reasonLink').value;
        const reasonDetail = group.get('reasonDetail').value;
        const reasonLinkTouched = group.get('reasonLink').touched;
        const reasonDetailTouched = group.get('reasonDetail').touched;

        if (!reasonLinkTouched && !reasonDetailTouched) {
            return null;
        }

        return reasonLink || reasonDetail ? null : { linkOrReason: true };
    }

    //urlValidator(control: FormControl) {
    //    const urlPattern = /^(http|https):\/\/[^ "]+$/;
    //    if (!control.touched || !control.value) return null;
    //    return urlPattern.test(control.value) ? null : { invalidUrl: true };
    //}

    urlValidator(control: FormControl): Observable<any> {
        const urlPattern = /^(http|https):\/\/[^ "]+$/;
        if (!control.touched || !control.value) {
            return of(null);
        }

        if (!urlPattern.test(control.value)) {
            return of({ invalidUrl: true });
        }

        return this.contentAnalysisService.fetchMetaTags(control.value).pipe(
            switchMap(() => of(null)),
            catchError(() => of({ invalidUrl: true }))
        );
    }

    addFreedomGroup(): void {
        if (this.freedomScoreDetails.length < 3) {
            this.freedomScoreDetails.push(this.createScoreDetail());
        }
    }

    removeFreedomGroup(index: number): void {
        if (this.freedomScoreDetails.length > 1) {
            this.freedomScoreDetails.removeAt(index);
        }
    }

    addTruthGroup(): void {
        if (this.truthScoreDetails.length < 3) {
            this.truthScoreDetails.push(this.createScoreDetail());
        }
    }

    removeTruthGroup(index: number): void {
        if (this.truthScoreDetails.length > 1) {
            this.truthScoreDetails.removeAt(index);
        }
    }

    addPoliticalGroup(): void {
        if (this.politicalScoreDetails.length < 3) {
            this.politicalScoreDetails.push(this.createScoreDetail());
        }
    }

    removePoliticalGroup(index: number): void {
        if (this.politicalScoreDetails.length > 1) {
            this.politicalScoreDetails.removeAt(index);
        }
    }

    addObjectivityGroup(): void {
        if (this.objectivityScoreDetails.length < 3) {
            this.objectivityScoreDetails.push(this.createScoreDetail());
        }
    }

    removeObjectivityGroup(index: number): void {
        if (this.objectivityScoreDetails.length > 1) {
            this.objectivityScoreDetails.removeAt(index);
        }
    }

    openTab(tabName: string): void {
        if (this.activeTab) {
            //this.unsubscribeFromValueChanges(this.activeTab);
            this.markFieldsTouched(this.activeTab); // Mark fields of the current active tab as touched before switching tabs
        }
        this.activeTab = tabName;
        //this.subscribeToValueChanges(tabName);
        this.revalidateTabFields(tabName); // Revalidate fields in the new tab if necessary
        this.cdr.detectChanges();
    }

    markFieldsTouched(tabName: string): void {
        const markArrayTouched = (details: FormArray) => {
            details.controls.forEach(control => {
                if (control.dirty || control.touched) {
                    control.markAsTouched();
                    control.updateValueAndValidity();
                }
            });
        };

        if (tabName === 'freedom') {
            markArrayTouched(this.freedomScoreDetails);
        } else if (tabName === 'truth') {
            markArrayTouched(this.truthScoreDetails);
        } else if (tabName === 'political') {
            markArrayTouched(this.politicalScoreDetails);
        } else if (tabName === 'objectivity') {
            markArrayTouched(this.objectivityScoreDetails);
        }
    }

    subscribeToValueChanges(tabName: string): void {
        let controls: FormArray;
        switch (tabName) {
            case 'freedom':
                controls = this.freedomScoreDetails;
                break;
            case 'truth':
                controls = this.truthScoreDetails;
                break;
            case 'political':
                controls = this.politicalScoreDetails;
                break;
            case 'objectivity':
                controls = this.objectivityScoreDetails;
                break;
        }
        controls.controls.forEach((control, index) => {
            const subscription = control.get('reasonLink').valueChanges.subscribe(() => {
                if (!control.get('reasonLink').touched) {
                    this.validateUrl(control, tabName, index);
                }
            });
            this.tabSubscriptions[tabName].push(subscription);
        });

        //controls.controls.forEach((control, index) => {
        //    this.validateUrl(control, tabName, index);
        //});


    }

    unsubscribeFromValueChanges(tabName: string): void {
        this.tabSubscriptions[tabName].forEach(subscription => subscription.unsubscribe());
        this.tabSubscriptions[tabName] = [];
    }

    revalidateTabFields(tabName: string): void {
        const controls = this.getTabFormArray(tabName);
        controls.controls.forEach((control, index) => {
            if (control.get('reasonLink').touched) {
                this.validateUrl(control, tabName, index);
            }
        });
    }

    getTabFormArray(tabName: string): FormArray {
        switch (tabName) {
            case 'freedom':
                return this.freedomScoreDetails;
            case 'truth':
                return this.truthScoreDetails;
            case 'political':
                return this.politicalScoreDetails;
            case 'objectivity':
                return this.objectivityScoreDetails;
            default:
                return this.freedomScoreDetails;
        }
    }


    get scoreDetails(): FormArray {
        return this.scoreForm.get('scoreDetails') as FormArray;
    }

    onFreedomScoreChange(newScore: number | null): void {
        this.freedomScore = newScore;
        if (newScore !== null) {
            this.updateScoreDescription('freedom', newScore);
        } else {
            this.freedomScoreDescription = 'No Score';
        }
    }

    onTruthScoreChange(newScore: number): void {
        this.truthScore = newScore;
        this.updateScoreDescription('truth', newScore);
    }

    onPoliticalScoreChange(newScore: number): void {
        this.politicalScore = newScore;
        this.updateScoreDescription('political', newScore);
    }

    onObjectivityScoreChange(newScore: number): void {
        this.objectivityScore = newScore;
        this.updateScoreDescription('objectivity', newScore);
    }

    //addScoreDetail(): void {
    //    this.scoreDetails.push(this.fb.group({
    //        supportingLink: [''],
    //        linkType: [''],
    //        //entityScore: [0],
    //        scoreReason: ['']
    //    }));
    //}

    loadCategories(): void {
        this.scoreService.getCategories().subscribe(categories => {
            this.categories = categories;
        });
    }

    loadScoreDescriptions(): void {
        this.scoreService.getScoreDescriptions().subscribe(descriptions => {
            this.scoreDescriptions = descriptions;
        });
    }

    updateDescription(field: string): void {
        const value = this.scoreForm.get(field).value;
        this.scoreService.getScoreDescription(field, value).subscribe(description => {
            this.scoreDescriptions[field] = description;
        });
    }

    updateScoreDescription(type: string, value: number): void {
        this.scoreService.getScoreDescription(type, value).subscribe(description => {
            if (type === 'freedom') {
                this.freedomScoreDescription = description;
            } else if (type === 'truth') {
                this.truthScoreDescription = description;
            } else if (type === 'political') {
                this.politicalScoreDescription = description;
            } else if (type === 'objectivity') {
                this.objectivityScoreDescription = description;
            }
        });
        //this.updateSliderThumbValue();
    }

    //updateSliderThumbValue(): void {
    //    const slider = document.getElementById('freedomScoreSlider') as HTMLInputElement;
    //    if (slider) {
    //        //slider.setAttribute('value', this.freedomScore.toString());
    //        //slider.style.setProperty('--slider-value', this.freedomScore.toString());
    //        slider.setAttribute('data-value', this.freedomScore.toString());
    //    }
    //}

    //updateSliderThumb(value: number): void {
    //    const slider: any = document.getElementById('freedomScoreSlider');
    //    const thumb: HTMLElement = slider?.querySelector('#thumb');

    //    if (thumb) {
    //        thumb.textContent = value.toString();
    //        thumb.style.left = `calc(${((value - slider.min) / (slider.max - slider.min)) * 100}% - 12px)`;
    //    }

    //    slider.addEventListener('input', () => {
    //        thumb.textContent = slider.value;
    //    });
    //}

    onAlternateEntityInput(): void {
        const value = this.scoreForm.get('alternateEntity').value;
        if (value.length > 1) {
            this.scoreService.searchEntities(value).subscribe(entities => {
                this.filteredEntities = entities;
            });
        } else {
            this.filteredEntities = [];
        }
    }

    selectAlternateEntity(entity: any): void {
        this.scoreForm.get('alternateEntity').setValue(entity.name);
        this.filteredEntities = [];
    }

    onSave(): void {
        if (this.scoreForm.invalid) {
            this.showValidationErrors();
            this.showValidationNotifications();
            return;
        }

        const scoresData = {
            entityID: this.postData.UserID,
            //entityID: this.scoreService.entityID,
            userID: this.UserID,
            freedomScore: this.freedomScore >= 0 ? this.freedomScore : null,
            truthScore: this.truthScore >= 0 ? this.truthScore : null,
            politicalScore: this.politicalScore >= 0 ? this.politicalScore : null,
            objectivityScore: this.objectivityScore >= 0 ? this.objectivityScore : null,
            freedomScoreDetails: this.scoreForm.get('freedomScoreDetails').value,
            truthScoreDetails: this.scoreForm.get('truthScoreDetails').value,
            politicalScoreDetails: this.scoreForm.get('politicalScoreDetails').value,
            objectivityScoreDetails: this.scoreForm.get('objectivityScoreDetails').value
        };

        //if (this.scoreForm.valid) {
        //    this.scoreService.saveScore(this.scoreForm.value).subscribe(() => {
        //        this.activeModal.close();
        //    });
        //}

        this.scoreService.saveScores(scoresData).subscribe(
            response => {
                this.toastr.success('Scores saved successfully');
                //console.log('Scores saved successfully');
                this.activeModal.close();
            },
            error => {
                this.toastr.error('Error saving scores');
                //console.error('Error saving scores', error);
            }
        );

    }

    showValidationErrors(): void {
        Object.keys(this.scoreForm.controls).forEach(key => {
            const control = this.scoreForm.get(key);
            if (control instanceof FormGroup || control instanceof FormArray) {
                Object.keys(control.controls).forEach(subKey => {
                    const subControl = control.get(subKey);
                    subControl.markAsTouched();
                    subControl.updateValueAndValidity();
                });
            } else {
                control.markAsTouched();
                control.updateValueAndValidity();
            }
        });
    }

    showValidationNotifications(): void {
        const invalidTabs = [];
        const checkTab = (details: FormArray, tabName: string) => {
            const hasErrors = details.controls.some(control => {
                const reasonLink = control.get('reasonLink').value;
                const reasonDetail = control.get('reasonDetail').value;
                return control.invalid && control.touched && (reasonLink || reasonDetail);
            });
            if (hasErrors) {
                invalidTabs.push(tabName);
            }
        };

        checkTab(this.freedomScoreDetails, 'Freedom Score');
        checkTab(this.truthScoreDetails, 'Truth Score');
        checkTab(this.politicalScoreDetails, 'Political Leaning');
        checkTab(this.objectivityScoreDetails, 'Objectivity');

        if (invalidTabs.length) {
            this.toastr.warning(`Please correct errors in the following tabs: ${invalidTabs.join(', ')}`, 'Validation Error', {
                closeButton: true,
                progressBar: true,
                timeOut: 5000
                //positionClass: 'toast-top-center'
            });
        }
    }

    testToastr(): void {
        this.toastr.success('Toastr is working!');
    }

    onCancel(): void {
        this.activeModal.dismiss();
    }

    selectTab(index: number): void {
        this.selectedTab = index;
    }

    selectLinkType(type: string, index: number): void {
        this.selectedLinkType[index] = type;
    }

}
