  <h4>Truth Score</h4>
  <app-custom-slider [value]="truthScore" [min]="0" [max]="20" (valueChange)="onTruthScoreChange($event)"></app-custom-slider>
  <div class="slider-label">{{ truthScoreDescription }}</div>

  <!-- Score Details -->
  <div *ngFor="let detail of truthScoreDetails; let i = index">
    <div class="form-group">
      <label for="supportingLink{{i}}">Supporting Link</label>
      <input type="url" class="form-control" id="supportingLink{{i}}" [(ngModel)]="detail.link">
    </div>
    <div class="form-group">
      <label for="linkType{{i}}">Link Type</label>
      <select class="form-control" id="linkType{{i}}" [(ngModel)]="detail.linkType">
        <option>Facebook</option>
        <option>Twitter</option>
        <option>Website</option>
      </select>
    </div>
    <div class="form-group">
      <label for="scoreReason{{i}}">Score Reason</label>
      <textarea class="form-control" id="scoreReason{{i}}" [(ngModel)]="detail.reason"></textarea>
    </div>
    <button type="button" class="btn btn-danger" (click)="removeGroup(i)" *ngIf="truthScoreDetails.length > 1">-</button>
  </div>

  <button type="button" class="btn btn-primary" (click)="addGroup()" *ngIf="truthScoreDetails.length < maxGroups" title="Add additional supporting information">+</button>
