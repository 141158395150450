import { Component, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserService } from '../../services/user.service';
import { RegistrationDataService } from '../../services/registration.data.service';

import { RegistrationValidationComponent } from '../../modals/sign_up_validation.modal/sign_up_validation.modal.component';

import { passwordStrengthValidator } from '../../validators/password.validator';
import { matchPasswordValidator } from '../../validators/match-password.validator';
import { nameValidator } from '../../validators/name.validator';
import { emailValidator } from '../../validators/email.validator';
import { phoneValidator } from '../../validators/phone.validator';


@Component({
    selector: 'app-signup',
    templateUrl: './sign_up.modal.component.html',
    styleUrls: ['./sign_up.modal.component.css']
})
export class RegistrationComponent implements OnInit {
    formRegistration: FormGroup;
    showInfoText = false;
    showInfoPhoneText = false;
    Name: string = ''; 

    months = [
        { name: 'January', value: 1 },
        { name: 'February', value: 2 },
        { name: 'March', value: 3 },
        { name: 'April', value: 4 },
        { name: 'May', value: 5 },
        { name: 'June', value: 6 },
        { name: 'July', value: 7 },
        { name: 'August', value: 8 },
        { name: 'September', value: 9 },
        { name: 'October', value: 10 },
        { name: 'November', value: 11 },
        { name: 'December', value: 12 }
    ];
    days: number[] = [];
    years: number[] = [];
    characterCount: number = 0;
    usePhone: boolean = true;
    formData: any = {};

    SelectedMonth: number;
    SelectedDay: number;
    SelectedYear: number;

    constructor(
        private activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private registrationDataService: RegistrationDataService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.setValidation();

        this.SelectedMonth = 1; // Default to January
        this.SelectedYear = new Date().getFullYear(); // Current year

        // Initialize days and years
        this.updateDays();
        this.initializeYears();
    }

    updateCharacterCount(text: string): void {
        this.characterCount = text ? text.length : 0;
    }

    togglePhoneEmail(): void {
        this.usePhone = !this.usePhone;
    }

    updateDays() {
        // Determine max number of days based on selected month
        const daysInMonth = new Date(this.SelectedYear, this.SelectedMonth, 0).getDate();
        this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    }

    initializeYears() {
        // Initialize years from this year to 120 years ago
        const currentYear = new Date().getFullYear();
        const startYear = currentYear - 120;
        for (let Year = currentYear; Year >= startYear; Year--) {
            this.years.push(Year);
        }
    }

    setValidation() {
        this.formRegistration = this.formBuilder.group({
            Name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50), nameValidator()]],
            Email: ['', [Validators.required, emailValidator()]],
            Phone: ['', [phoneValidator()]],
            Birthday: this.formBuilder.group({
                SelectedMonth: ['', Validators.required],
                SelectedDay: ['', Validators.required],
                SelectedYear: ['', Validators.required]
            }),
            Password: ['', [
                Validators.required,
                Validators.minLength(10),
                passwordStrengthValidator()
            ]],
            ConfirmPassword: ['', Validators.required],                
        //termsOfService: ['', Validators.requiredTrue]  // Ensures the checkbox is checked
        }, { validators: matchPasswordValidator() });

        // Load any existing data into the form
        const existingData = this.registrationDataService.getRegistrationData();

        // Adjust the birthday field to match the form's nested structure
        if (existingData.Birthday) {
            const birthdayDate = new Date(existingData.Birthday);
            existingData.Birthday = {
                SelectedYear: birthdayDate.getFullYear(),
                SelectedMonth: birthdayDate.getMonth() + 1, // JavaScript months are 0-based
                SelectedDay: birthdayDate.getDate()
            };
        }

        this.formRegistration.patchValue(existingData);
    }

    openValidationModal(): void {
        if (this.formRegistration.valid) {

            const formData = this.formRegistration.value;

            // Combine the birthday parts into a Date object
            const combinedBirthday = this.combineBirthday(formData.Birthday);

            // Include the combined birthday in the registration data
            const registrationData = {
                ...formData,
                Birthday: combinedBirthday  // Replace the birthday group with the combined date
            };

            // Now we can remove the nested birthday structure
            delete registrationData.Birthday.SelectedMonth;
            delete registrationData.Birthday.SelectedDay;
            delete registrationData.Birthday.SelectedYear;


            // Save data to the shared service
            this.registrationDataService.setRegistrationData(this.formRegistration.value);
            this.modalService.dismissAll();

            // Open the Validation Modal and pass this component's reference

            const modalRef = this.modalService.open(RegistrationValidationComponent, {
                keyboard: false,
                backdrop: 'static',
                size: 'lg'
            });
            modalRef.componentInstance.registrationModalRef = this;
        }
    }


    submitRegistration(): void {
        if (this.formRegistration.valid) {
            console.log('Form Submitted', this.formRegistration.value);
            // Call the registration service to submit the form data
            this.userService.registerUser(this.formData).subscribe(
                (response) => {
                    // Handle success
                    console.log('Registration successful:', response);
                },
                (error) => {
                    // Handle error
                    console.error('Error during registration:', error);
                }
            );
        }
    }

    combineBirthday(dateParts: any): Date {
        const { year, month, day } = dateParts;
        return new Date(year, month - 1, day);  // JavaScript months are 0-based
    }

    showInfo() {
        this.showInfoText = !this.showInfoText; // Toggle the visibility
    }

    showInfoPhone() {
        this.showInfoPhoneText = !this.showInfoPhoneText; // Toggle the visibility
    }

    closeModal(event: Event) {
        if (typeof event !== "undefined" && event !== null) {
            event.preventDefault();
        }
        this.activeModal.close("Modal Closed");
    }

}
