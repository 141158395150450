import { Component, Input, OnInit, OnDestroy, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


import { UserService } from '../../../services/user.service';

interface MenuItem {
    icon: string;
    label: string;
    link: string;
}

@Component({
    selector: 'app-profile-menu',
    templateUrl: './profile_menu.component.html',
    styleUrls: ['./profile_menu.component.css']
})
export class ProfileMenuComponent implements OnInit, OnDestroy, AfterViewInit  {
    @Input() ProfileName: string;  // Input property to receive username    menuItems: MenuItem[];  // Array to hold menu items
    @Input() Username: string;  // Input property to receive username    menuItems: MenuItem[];  // Array to hold menu items
    @Input() PublishDate: Date;  // Input property to receive username    menuItems: MenuItem[];  // Array to hold menu items
    @Input() ImageDirectory: string;
    @ViewChild('profileLink', { static: false }) profileLinkElement: ElementRef;


    menuItems: MenuItem[];  // Array to hold menu items

    userProfileStates = new Map<number, any>();

    showProfileBox: boolean = false;
    showTooltip: boolean = false;

    userProfileVisible = false;
    hoverBoxStyle = {};
    userProfile: any;

    private unsubscribeHover$ = new Subject<void>();

    private observer: IntersectionObserver;
    hoverTimer: any; // To hold the timer reference
    private timeoutId: any; // Holds the reference to the timeout



    constructor(
        private userService: UserService,
    ) {
        // Define menu items - this could instead be loaded from a service
        this.menuItems = [
            { icon: 'fa-house', label: 'Home', link: '/' },
            { icon: 'fa-compass', label: 'Explore', link: '/explore' },
            { icon: 'fa-bell', label: 'Notifications', link: '/notifications' },
            // Add more items as needed
        ];
    }

    ngOnInit(): void {
        // Additional initialization can be done here
    }

    ngAfterViewInit(): void {
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.loadUserProfileData(false);
                }
            });
        });

        if (this.profileLinkElement) {
            this.observer.observe(this.profileLinkElement.nativeElement);
        }
    }

    ngOnDestroy(): void {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId); // Clear timeout on component destruction
        }
        if (this.hoverTimer) {
            clearTimeout(this.hoverTimer); // Clear the timer if the mouse leaves the link
        }
        this.showProfileBox = false;
        this.showTooltip = false;
        // Clear the timeoutId after it executes
        this.timeoutId = null;
        this.hoverTimer = null;
        if (this.observer && this.profileLinkElement) {
            this.observer.unobserve(this.profileLinkElement.nativeElement);
        }
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(): void {
        this.showProfileBox = false;
        this.showTooltip = false;
        // Clear the timeoutId after it executes
        this.timeoutId = null;
        this.hoverTimer = null;
    }

    showTimeDetailHover(event: MouseEvent) {
        //console.log(`Mouse over loadUserProfile`);

        event.stopPropagation(); // Add this to prevent event bubbling

        // Check the class of the event target to determine which functionality to trigger
        const target = event.target as HTMLElement;
        if (target.classList.contains('publish-date')) {
            // Logic for showing the date tooltip
            this.hoverTimer = setTimeout(() => {
                this.showTooltip = true;

                if (this.hoverTimer) clearTimeout(this.hoverTimer);

                this.showProfileBox = false;
            }, 1000);  // Delay in milliseconds
        } 
    }

    loadUserProfile(event: MouseEvent) {
        //console.log(`Mouse over loadUserProfile`);

        event.stopPropagation(); // Add this to prevent event bubbling

        // Check the class of the event target to determine which functionality to trigger
        const target = event.target as HTMLElement;
        if (target.classList.contains('publish-date')) {
            // Logic for showing the date tooltip
            this.hoverTimer = setTimeout(() => {
                this.showTooltip = true;

                if (this.hoverTimer) clearTimeout(this.hoverTimer);

                this.showProfileBox = false;
            }, 2000);  // Delay in milliseconds
        } else {
            this.hoverTimer = setTimeout(() => {
                //if (!this.userProfileStates.has(index)) {
                //    this.userProfileStates.set(index, { visible: false });
                //}

                //this.userService.getUserProfile(this.Username).subscribe(data => {

                //    this.userProfile = data;
                //    this.adjustHoverBoxPosition();
                //    this.showProfileBox = true;
                //    //console.log("this.showProfileBox : " + this.showProfileBox );
                //});

                this.loadUserProfileData(true);

            }, 2000); // Delay of 1000 milliseconds (1 second)
        }
    }

    loadUserProfileData(showBox: boolean = false) {
        this.userService.getUserProfile(this.Username).subscribe(data => {
            this.userProfile = data;
            if (showBox) {
                this.adjustHoverBoxPosition();
                this.showProfileBox = true;
            }
        });
    }

    keepHoverBoxVisible(): void {
        //this.unsubscribeHover$.next();
        if (this.timeoutId) {
            clearTimeout(this.timeoutId); // Cancel the pending timeout
            this.timeoutId = null;
        }
        this.showProfileBox = true;
    }

    hideUserProfile() {
        //this.showProfileBox = false;

        //this.unsubscribeHover$.next();
        //timer(500).pipe(takeUntil(this.unsubscribeHover$)).subscribe(() => {
        //    this.showProfileBox = false; // Delay hiding the box to allow for transition
        //    this.showTooltip = false;
        //});

        //if (this.userProfileStates.has(index)) {
        //    this.userProfileStates.get(index).visible = false;
        //}

        //this.hoverTimer

        if (this.timeoutId) clearTimeout(this.timeoutId);
        if (this.hoverTimer) clearTimeout(this.hoverTimer);

        // Set a new timeout
        this.timeoutId = setTimeout(() => {
            //clearTimeout(this.hoverTimer); // Clear the timer if the mouse leaves the link
            this.showProfileBox = false;
            this.showTooltip = false;
            // Clear the timeoutId after it executes
            this.timeoutId = null;
        }, 2000); // Delay in milliseconds
    }

    adjustHoverBoxPosition(): void {
        if (this.profileLinkElement && this.profileLinkElement.nativeElement) {
            const rect = this.profileLinkElement.nativeElement.getBoundingClientRect();
            const spaceBelow = window.innerHeight - rect.bottom;
            const hoverBoxHeight = 200;  // Fixed height or dynamically determined

            if (spaceBelow < hoverBoxHeight) {
                this.hoverBoxStyle = { bottom: '30px' };
            } else {
                this.hoverBoxStyle = { top: '30px' };
            }
        }
    }

    followUser() {
        this.userService.followUser(this.Username).subscribe({
            next: (result) => {
                console.log('User followed successfully');
            },
            error: (error) => {
                console.error('Error following the user', error);
            }
        });
    }

}
