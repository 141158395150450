import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'unique'
})
export class UniquePipe implements PipeTransform {
    transform(value: any[], key: string): any[] {
        if (!value || !key) {
            return value;
        }
        return Array.from(new Set(value.map(item => item[key])))
            .map(uniqueKey => value.find(item => item[key] === uniqueKey));
    }
}
