import { Injectable } from '@angular/core';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, ReplaySubject, forkJoin, throwError } from 'rxjs';

import { SocialMedia } from '../global.urls';
import * as Classes from '../classes/models.classes';
import { ShortsPostSave } from '../interfaces/models.interfaces';
import { tap } from 'rxjs/operators';

//import { Http } from '@angular/http';

@Injectable({
    providedIn: 'any'
})
export class SocialMediaService {
    //private apiUrl = '/api/twitter';

    constructor(private http: HttpClient) { }

    fetchAndSavePopularTweets(): Observable<void> {
        return this.http.post<void>(SocialMedia.FetchAndSaveTweets, {});
    }
}