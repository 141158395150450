import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';

//import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

//import { FormBuilder, FormGroup, Validators } from '@angular/forms';

//import { UserService } from '../../services/user.service';
//import { RegistrationDataService } from '../../services/registration.data.service';

//import { RegistrationValidationComponent } from '../../modals/sign_up_validation.modal/sign_up_validation.modal.component';

import { UserPostsDataService } from '../../services/posts.service';
import { SessionService } from '../../services/session.service';

import *  as Classes from '../../classes/models.classes'

@Component({
    selector: 'app-quote-modal',
    templateUrl: './quote-modal.component.html',
    styleUrls: ['./quote-modal.component.css']
})
export class QuoteModalComponent {
    @Input() postData: any; // Receive the post data from the parent
    @Output() postQuote = new EventEmitter<string>(); // Emit the comment

    comment: string = '';

    UserPostsDataContent: Classes.ShortsPostSave;

    UserID: number;

    FileName: string = 'logo.png'; //
    VideoMimeType: string = 'image/jpeg';
    selectedFile: File | null = null;

    constructor(
        private sessionService: SessionService,
        private userPostsDataService: UserPostsDataService,
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {
        this.sessionService.getUserID().subscribe(userID => {
            this.UserID = Number(userID);
        });

        this.UserPostsDataContent = new Classes.ShortsPostSave();
    }

    triggerQuoteFileUpload(): void {
        document.getElementById('quoteMediaFileUpload')!.click();
    }

    checkQuoteMedia(event: any): void {
        this.selectedFile = event.target.files[0];

        if (!this.selectedFile) {
            return;
        }

        const fileType = this.selectedFile.type;

        //if (this.selectedFile) {
        // Handle videos
        if (this.selectedFile.type.startsWith('video/')) {
            // Check the file type
            if (this.selectedFile.type !== 'video/mp4' && this.selectedFile.type !== 'video/quicktime') {
                alert('Unsupported video format. Please upload a MP4 or MOV file.');
                return;
            }

            // Load the file into a video element to check duration
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                const duration = video.duration;

                if (duration > 120) { // Check if video is longer than 2 minutes
                    alert('Video is too long. Maximum allowed duration is 2 minutes.');
                } else {
                    alert('Video is valid. Duration: ' + duration + ' seconds.');
                    // Proceed with further processing like uploading
                }
            };

            video.onerror = () => {
                alert('Unable to load the video for validation.');
            };

            video.src = URL.createObjectURL(this.selectedFile);

            return; // Exit function after handling video
        }

        // Handle images
        if (this.selectedFile.type.startsWith('image/')) {
            // Image-specific processing logic here

            if (this.selectedFile.type === 'image/gif') {
                alert('GIF file selected.');
                // Additional GIF-specific checks can go here
            } else {
                alert('Image file selected.');
            }

            // Proceed with further processing like uploading
        }
    }

    submitPost() {

        // Call checkVideo with the mock event

        this.UserPostsDataContent.UserID = this.UserID;
        this.UserPostsDataContent.OriginalPostID = this.postData.TSID;
        this.UserPostsDataContent.PostType = 5;
        this.UserPostsDataContent.PublishDate = new Date() // Date is set at the time of submission
        if (this.selectedFile.name.length > 0) {
            this.UserPostsDataContent.FileName = this.selectedFile.name;
            this.UserPostsDataContent.VideoMimeType = this.selectedFile.type;
        }

        const formData = new FormData();

        formData.append('postData', JSON.stringify(this.UserPostsDataContent)); // Serialize your object into a JSON string
        if (this.selectedFile) {
            formData.append('file', this.selectedFile, this.selectedFile.name);
        }

        this.userPostsDataService.saveQuotePost(formData).subscribe(data => {
            if (data.type === HttpEventType.DownloadProgress) {
                let percentDone: number = Math.round(100 + data.loaded / data.total);
                if (percentDone > 100) {
                    percentDone = 100;
                }
                else if (percentDone < 0) {
                    percentDone = 0;
                }
            } else if (data instanceof HttpResponse) {
                let loadedData: number = data.body;
            }
        })
    };


    onPost(): void {
        this.postQuote.emit(this.comment); // Emit the comment to the parent component
        this.activeModal.close(); // Close the modal
    }

    onCancel(): void {
        this.activeModal.dismiss(); // Dismiss the modal without doing anything
    }
}

