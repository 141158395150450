<div class="modal-dialog custom-modal-width"> <!-- Custom class for wider modal -->
  <div class="modal-content modal-box">
    <div class="modal-header d-flex justify-content-between align-items-start">
      <h5 class="m-0">Compose</h5>
      <button type="button" class="close" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="container-fluid p-0">
        <div class="row align-items-center mb-3">
          <div class="col">
            <img class="responsive-logo-image-sm" [src]="'api/content/logo?userID=' + UserID" >
          </div>
          <div class="col">
            <div class="dropdown mb-3">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Privacy Settings
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#">Public</a>
                <a class="dropdown-item" href="#">Accounts You Follow</a>
                <a class="dropdown-item" href="#">Verified Accounts</a>
              </div>
            </div>
        </div>
        <div class="row align-items-center quote-post-container">
            <textarea placeholder="Add your comment" class="form-control-lg border-0 bg-light margin-left-3per" [(ngModel)]="UserPostsDataContent.PostContent" rows="3"></textarea>
            <!-- Commenting Section -->
            <!-- Original Post Display -->
            <div class="original-post rounded border">
              <div class="post-details">
<!--                <p><strong>{{ postData.ProfileName }}</strong> <br>
                  @{{ postData.UserName }} &#x2022; {{ postData.PublishDate | relativeTime }}</p>-->
                <div class="user-avatar-container">
                    <img class="circular-image" [src]="'./img_profile/' + postData.UserID + '/' + postData.UserImg" alt="" height="50px">
                </div>
                <app-profile-menu
                [ProfileName]="postData.ProfileName"
                [Username]="postData.UserName"
                [PublishDate]="postData.PublishDate"
                [ImageDirectory]="postData.ImageDirectory">
                </app-profile-menu>                                                    


                <a href="#" class="post-title">{{ postData.PostTitle }}</a>
                <p [innerHTML]="postData.PostContent | parseHashtags"></p>
                <!-- Conditional rendering for media types -->
                <ng-container *ngIf="postData.VideoMimeType">
                  <div *ngIf="postData.VideoMimeType.startsWith('video/')">
                    <video controls class="responsive-video rounded" style="max-width: 100%;">
                      <source [src]="postData.VideoURL" [type]="postData.VideoMimeType">
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div *ngIf="postData.VideoMimeType.startsWith('image/')">
                    <img [src]="postData.VideoURL" alt="Post image" class="rounded" style="max-width: 100%;">
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer no-border-top">
        <div class="col-7 text-info">
            <!-- Hidden file input for uploading videos -->
            <input type="file" id="quoteMediaFileUpload" (change)="checkQuoteMedia($event)" accept="video/*,image/jpeg,image/png,image/gif" style="display: none;">
            <span  (click)="triggerQuoteFileUpload()">
                <span style="display: inline-block;">
                <svg (click)="triggerQuoteFileUpload(); $event.stopPropagation();" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283 283" width="18" height="18">
                    <path style="fill: rgb(135, 135, 135);" d="m149.823,258.142c-31.398,30.698 -81.882,30.576 -113.105,-0.429c-31.214,-30.987 -31.337,-81.129 -0.42,-112.308l-0.026,-0.018l113.569,-112.772l14.203,-14.098c23.522,-23.356 61.65,-23.356 85.172,0s23.522,61.221 0,84.586l-125.19,123.02l-0.044,-0.035c-15.428,14.771 -40.018,14.666 -55.262,-0.394c-15.244,-15.069 -15.34,-39.361 -0.394,-54.588l-0.044,-0.053l13.94,-13.756l69.701,-68.843l13.931,13.774l-83.632,82.599c-7.701,7.596 -7.701,19.926 0,27.53s20.188,7.604 27.88,0l124.918,-123.37l-0.035,-0.026l0.473,-0.403c15.682,-15.568 15.682,-40.823 0,-56.39s-41.094,-15.568 -56.776,0l-0.42,0.473l-0.026,-0.018l-14.194,14.089l-113.576,112.773c-23.522,23.356 -23.522,61.221 0,84.577s61.659,23.356 85.163,0l99.375,-98.675l14.194,-14.089l14.194,14.089l-14.194,14.098l-99.357,98.675c0,-0.001 -0.018,-0.018 -0.018,-0.018z"/>
                </svg>
                </span>
            </span>
        </div>
        <div class="col-4 ">
              <button type="button" class="btn btn-primary" (click)="submitPost()">Post</button>
              <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
    </div>
  </div>
</div>
