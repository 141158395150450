import { Injectable } from '@angular/core';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, ReplaySubject, forkJoin, throwError } from 'rxjs';

import { VoterFraudDataUrls } from '../global.urls';
import * as Classes from '../classes/models.classes';

//import { Http } from '@angular/http';

@Injectable({
    providedIn: 'any'
})
export class FraudIncidentDataService {
  lcl_params: Classes.Params;

  constructor(
    private http: HttpClient) {

  }

  loadFraudIncidentData(StateID: string, CategoryID: string) {
    let requestHeaders: HttpHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.append('Content-Type', 'application/json');

    let params = new HttpParams();

    params.append("StateID", StateID.toString());
    params.append("CategoryID", CategoryID.toString());

    this.lcl_params = new Classes.Params();

    this.lcl_params.stateID = StateID;
    this.lcl_params.categoryID = CategoryID;

    let getFraudIncidentDataRequest: HttpRequest<Classes.Params> = new HttpRequest<Classes.Params>('POST', VoterFraudDataUrls.Get_Incidents, this.lcl_params, {
    //let getFraudIncidentDataRequest: HttpRequest<Classes.VoterFraudIncident> = new HttpRequest<Classes.VoterFraudIncident>('POST', VoterFraudDataUrls.Get_Incidents, VoterFraudIncidentData, {
    //let getFraudIncidentDataRequest = new HttpRequest('GET', VoterFraudDataUrls.Get_Incidents, '', {
      reportProgress: true,
      headers: requestHeaders
      //params: params
    });

    return this.http.request<Array<Classes.VoterFraudIncident>>(getFraudIncidentDataRequest);

  }


}
