
    <div class="container-fluid container-md">
                   <!-- xs NAV BAR -->
                <nav class=" bg-light xs-nav fixed-bottom    d-sm-none">
                    <div class="container-fluid">
                        <div class="row h-50">
                            <div class="col-3">
                                <span><i class="fa fa-house fs-3 py-3"></i></span>
                            </div>
                            <div class="col-3">
                            <span><i class="fa fa-magnifying-glass fs-2 py-3 "></i></span>
                            </div>
                            <div class="col-3">
                            <span><i class="fa fa-bell fs-2 py-3"></i></span>
                            </div>
                            <div class="col-3 ">
                                <span><i class="fa fa-envelope fs-2 py-3"></i></span>
                            </div>
                        </div>
                    </div>
                </nav>
        <div class="row ">
            <!-- LEFT SIDE BAR -->
            <div
                class="d-none col-sm-2  d-sm-block  col-md-3 col-lg-3 d-flex flex-column text-sm-end text-md-start  align-items-lg-start left_sidebar border-end ">
                <div>
                    <span><i class="fa-brands fa-twitter display-5 text-info py-3  "></i></span>
                    
                </div>
                <div class="d-flex align-items-center justify-content-sm-end justify-content-md-start">
                    <span><i class="fa fa-house fs-3 py-3"></i></span>
                    <p class="d-none d-md-block fs-4 pt-3 ps-2 fw-bold">Home</p>
                </div>
                <div class="d-flex align-items-center d-block d-md-none  justify-content-sm-end justify-content-md-start justify-content-sm-end justify-content-md-start">
                    <span><i class="fa fa-magnifying-glass fs-2 py-3 "></i></span>
                    <!-- <p class="d-none d-lg-block fs-3 pt-3 ps-1">Home</p> -->
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span><i class="fa fa-hashtag fs-2 py-3 d-none d-md-block"></i></span>
                    <p class="d-none d-md-block fs-4 pt-3 ps-2">Expolore</p>
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span><i class="fa fa-bell fs-2 py-3"></i></span>
                    <p class="d-none d-md-block fs-4 pt-3 ps-2">Notifications</p>
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span><i class="fa fa-envelope fs-2 py-3"></i></span>
                    <p class="d-none d-md-block fs-4 pt-3 ps-2">Messages</p>
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span><i class="fa fa-bookmark fs-2 py-3"></i></span>
                    <p class="d-none d-md-block fs-4 pt-3 ps-2">Bookmark</p>
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span><i class="fa fa-file-lines fs-2 py-3"></i></span>
                    <p class="d-none d-md-block fs-4 pt-3 ps-2">List</p>
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span><i class="fa fa-user fs-2 py-3"></i></span>
                    <p class="d-none d-md-block fs-4 pt-3 ps-2"><a routerLink="/dashboard_login">Profile</a></p>
                </div>
                <div class="d-flex align-items-center  justify-content-sm-end justify-content-md-start">
                    <span><i class="fa fa-ellipsis fs-2 py-3"></i></span>
                    <p class="d-none d-md-block fs-4 pt-3 ps-2">More</p>
                </div>
            </div>

            <!-- MAIN SECTION -->
            <div class="col-12 col-sm-10 col-md-9 col-lg-6  main_bar ">
                <div class="row d-flex justify-content-between align-items-center ps-1">
                    <div class="col-1 pt-2">
                        <div><span class="fs-4 fw-bold">Home</span></div>
                    </div>
                    <div class="col-1 me-3">
                        <div><span class="fs-4"><i class="fa fa-star"></i> </span></div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col pt-2 ">
                        <div class="row">
                            <div class="col-2">
                                <img class="rounded-circle" src="./asserts/images/profile.jfif" alt="" height="50px">
                            </div>
                            <div class="col-9 col-sm-10 ">
                                <input class="border-0 bg  form-control-lg" type="text" placeholder="What's happening?">
                                <div class="ps-3 d-flex text-info">
                                    <p><span><i class="fa fa-earth-americas "></i></span></p>
                                    <p class="fw-bold ps-1 ">Everyone can reply</p>
                                    <hr>
                                </div>
                                <div class="row pb-2 justify-content-center">
                                    <div class="col-9 text-info">
                                        <span class="ps-3"><i class="fa fa-image"></i></span>
                                        <span class="ps-3"><i class="fa fa-gift"></i></span>
                                        <span class="ps-3"><i class="fa fa-face-grin"></i></span>
                                        <span class="ps-3"><i class="fa fa-location-pin"></i></span>
                                    </div>
                                    <div class="col-3 col-sm-2  ">
                                        <a class="text-decoration-none fs-6  fw-bold text-white bg-info py-1 px-3  rounded-pill"
                                            href="#">Post</a>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="row  d-flex  justify-content-between align-items-center border">
                            <div class="col-11 col-sm-10 ps-5  pt-1 d-flex pt-2">
                                <span><i class="fa fa-comment"></i></span>
                                <p class="ps-1">Bitcoin cryptocurrency. <span class="text-info fw-bold ">see
                                        more</span></p>
                            </div>
                            <div class="col-1 col-sm-2 text-end fs-5 pt-2 ps-2">
                                <span><i class="fa fa-xmark"></i></span>
                            </div>
                        </div>

                        <ng-container *ngFor="let postData of UserPostsData; let i = index">

                            <ng-container *ngIf="postData.PostType === 1"> <!-- share post without image -->

                                <!-- TWEET 1 -->
                                <div class="row mt-1 border d-flex  ">
                                    <div class="col-1 mt-2">

                                        <img class="rounded-circle" [src]="'./img_profile/' + postData.TSID + '/' + postData.UserImg"  alt="" height="50px">
                                    </div>
                                    <div class="col-9 ">
                                        <div class="row d-flex justify-content-between align-items-center ps-1">
                                            <div class="col-10 pt-2 ">
                                                <div>
                                                    <p><span class="fw-bold">{{postData.ProfileName}} </span>@rovercrc . {{postData.PublishDate}}</p>
                                                </div>
                                            </div>
                                            <div class="col-1 me-2 ps-0">
                                                <div><span><i class="fa fa-ellipsis fs-5 py-3"></i></span></div>
                                            </div>
                                        </div>
                                        <div class="col">
												        <a href="post-detail.html" class="post-title">{{postData.PostTitle}}</a>
												        <p>
													        {{postData.PostContent}}
												        </p>
                                            <br>
                                            <div class="row pt-4">
                                                <div class="col"><span><i class="fa fa-comment"></i></span></div>
                                                <div class="col"><span><i class="fa fa-share-from-square"></i></span>
                                                </div>
                                                <div class="col"><i class="fa fa-heart"></i></div>
                                                <div class="col"><span><i class="fa fa-share-nodes"></i></span></div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <!-- TWEET 5 -->
                                <div class="row mt-1 border d-flex  ">
                                    <div class="col-3 mt-2">
                        
                                        <img class="rounded-circle" src="./asserts/images/profile.jfif" alt="" height="50px">
                                    </div>
                                    <div class="col-9 ">
                                        <div class="row d-flex justify-content-between align-items-center ps-1">
                                            <div class="col-10 pt-2 ">
                                                <div>
                                                    <p><span class="fw-bold">Crypto Rover </span>@rovercrc . 23h</p>
                                                </div>
                                            </div>
                                            <div class="col-1 me-2 ps-0">
                                                <div><span><i class="fa-solid fa-ellipsis fs-5 py-3"></i></span></div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            DO you think <span class="text-info">#Bitcoin</span> will hit $100,000 before 2025?
                                            <br>
                                            <div class="row pt-4">
                                                <div class="col"><span><i class="fa-solid fa-comment"></i></span></div>
                                                <div class="col"><span><i class="fa-solid fa-share-from-square"></i></span>
                                                </div>
                                                <div class="col"><i class="fa-solid fa-heart"></i></div>
                                                <div class="col"><span><i class="fa-solid fa-share-nodes"></i></span></div>
                                            </div>
                        
                                        </div>
                        
                                    </div>
                                </div>

                            </ng-container>
                            <ng-container *ngIf="postData.PostType === 2"><!-- share audio post -->
                            </ng-container>
                            <ng-container *ngIf="postData.PostType === 3"><!-- share video post -->
                            </ng-container>
                            <ng-container *ngIf="postData.PostType === 4"><!-- share image with post -->
                            </ng-container>
                            <ng-container *ngIf="postData.PostType === 5"><!-- share image album -->
                            </ng-container>
                            <ng-container *ngIf="postData.PostType === 6"><!-- gif image post -->
                            </ng-container>
                            <ng-container *ngIf="postData.PostType === 7"><!-- share link -->
                            </ng-container>
                            <ng-container *ngIf="postData.PostType === 8"><!-- share video -->
                            </ng-container>
                            <ng-container *ngIf="postData.PostType === 9"><!-- video playlist carousel -->
                            </ng-container>
                            <ng-container *ngIf="postData.PostType === 10"><!-- post sell book -->
                            </ng-container>
                            <ng-container *ngIf="postData.PostType === 11"><!-- advertisment carousel -->
                            </ng-container>

								
                        </ng-container>

                    </div>
                </div>
            </div>
            <!-- RIGHT SIDE BAR -->
            <div class="col-lg-3 d-none d-lg-block  w-25 h-25 right_side_bar">
                <div class="">
                    <div class="bg-opacity-10 bg-black rounded-pill">
                        <div class="row d-flex align-items-center ">
                            <div class="col-2 text-end fs-4">
                                <span><i class="fa fa-magnifying-glass ps-4"></i></span>
                            </div>
                            <div class="col-10"><input type="text"
                                    class="form-control-lg border-0 me-1 w-75 bg-transparent"
                                    placeholder="Search Twitter"></div>
                        </div>
                    </div>
                    <div class="row bg-opacity-10 bg-black mx-1 my-2 pb-3 rounded-3">
                        <div class="col">
                            <div class="trending">
                                <p class="fs-6 fw-bold py-2">Trends for you</p>
                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in Pakistan</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">Nawaz Sharif</p>
                                        <p class="">12.7k Tweets</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Technology . Trending</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">#TikTok</p>
                                        <p class="">101k Tweets</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in Pakistan</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">Audio</p>
                                        <p class="">132k Tweets</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in Pakistan</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">#Bajwa</p>
                                        <p class="">8,104 Tweets</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in Pakistan</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">Shame</p>
                                        <p class="">141k Tweets</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <p class="m-0">Trending in Pakistan</p>
                                    </div>
                                    <div class="col-3 text-end">
                                        <span><i class="fa fa-ellipsis pe-2"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p class="fs-6 fw-bold mb-0">#dollarvsrupee</p>
                                        <p class="">2,449 Tweets</p>
                                    </div>
                                </div>

                                <span class="text-info">Show more</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
 